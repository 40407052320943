import * as Congs from './Configurations'
export const MENTORSHIP_COURSES = [
  {
    "id": 100,
    "title": "communication skills and Personality  development  Bootcamp 1",
    "code": "STS200106",
    "subjectName": "communication skills and Personality  development",
    "subjectCode": "STS2001",
    "level": 6,
    "category": "",
    "tags": [],
    "imageUrl": "/images/temp/mentor1.jpeg",
    "description": "This course is designed to have rapid preparation for interviews. Here the mentor will be preparing you for interviews and will groom you for that. Will cover all necessary topics and problems which are asked in the interview. He will make sure that students should be well prepared to crack interviews.",
    "mentor": "Bytecoder Professor",
    "learnings": [
      "Languages specific preparation (java",
      "python and JS) and interview questions",
      "100+ DSA practises problems for interview",
      "10+ System designs problems",
      "Mock interviews",
      "Assignments",
      "Practices problems and interview questions",
      "Mentoring and guidance",
      "Soft skills and communication grooming sessions"
    ],
    "duration": "6 weeks",
    "whoShouldLearn": "Candidates who have a good amount of technical skills and DSA knowledge. But nit able to crack the interview due to lack of interview experience and high level technical understanding.",
    "fee": 5000,
    "rating": "4.5",
    "skills": [
      "DSA",
      "system design",
      "Mock interviews",
      "assignments",
      "real life projects."
    ],
    "outcomes": "After completing this course, you will be able to crack any interview at this level. This will boost your knowledge and confidence in the interview.",
    "prerequisites": [],
    "reviews": [],
    "totalRegistrations": 324
  },
  {
    "id": 100,
    "title": "communication skills and Personality  development  Bootcamp 2",
    "code": "STS200106",
    "subjectName": "communication skills and Personality  development",
    "subjectCode": "STS2001",
    "level": 6,
    "category": "",
    "tags": [],
    "imageUrl": "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/DSA.svg",
    "description": "This course is designed to have rapid preparation for interviews. Here the mentor will be preparing you for interviews and will groom you for that. Will cover all necessary topics and problems which are asked in the interview. He will make sure that students should be well prepared to crack interviews.",
    "mentor": "Bytecoder Professor",
    "learnings": [
      "Languages specific preparation (java",
      "python and JS) and interview questions",
      "100+ DSA practises problems for interview",
      "10+ System designs problems",
      "Mock interviews",
      "Assignments",
      "Practices problems and interview questions",
      "Mentoring and guidance",
      "Soft skills and communication grooming sessions"
    ],
    "duration": "6 weeks",
    "whoShouldLearn": "Candidates who have a good amount of technical skills and DSA knowledge. But nit able to crack the interview due to lack of interview experience and high level technical understanding.",
    "fee": 5000,
    "rating": "4.5",
    "skills": [
      "DSA",
      "system design",
      "Mock interviews",
      "assignments",
      "real life projects."
    ],
    "outcomes": "After completing this course, you will be able to crack any interview at this level. This will boost your knowledge and confidence in the interview.",
    "prerequisites": [],
    "reviews": [],
    "totalRegistrations": 324
  },
  {
    "id": 100,
    "title": "communication skills and Personality  development  Bootcamp 3",
    "code": "STS200106",
    "subjectName": "communication skills and Personality  development",
    "subjectCode": "STS2001",
    "level": 6,
    "category": "",
    "tags": [],
    "imageUrl": "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/DSA.svg",
    "description": "This course is designed to have rapid preparation for interviews. Here the mentor will be preparing you for interviews and will groom you for that. Will cover all necessary topics and problems which are asked in the interview. He will make sure that students should be well prepared to crack interviews.",
    "mentor": "Bytecoder Professor",
    "learnings": [
      "Languages specific preparation (java",
      "python and JS) and interview questions",
      "100+ DSA practises problems for interview",
      "10+ System designs problems",
      "Mock interviews",
      "Assignments",
      "Practices problems and interview questions",
      "Mentoring and guidance",
      "Soft skills and communication grooming sessions"
    ],
    "duration": "6 weeks",
    "whoShouldLearn": "Candidates who have a good amount of technical skills and DSA knowledge. But nit able to crack the interview due to lack of interview experience and high level technical understanding.",
    "fee": 5000,
    "rating": "4.5",
    "skills": [
      "DSA",
      "system design",
      "Mock interviews",
      "assignments",
      "real life projects."
    ],
    "outcomes": "After completing this course, you will be able to crack any interview at this level. This will boost your knowledge and confidence in the interview.",
    "prerequisites": [],
    "reviews": [],
    "totalRegistrations": 324
  },
  {
    "id": 100,
    "title": "communication skills and Personality  development  Bootcamp 4",
    "code": "STS200106",
    "subjectName": "communication skills and Personality  development",
    "subjectCode": "STS2001",
    "level": 6,
    "category": "",
    "tags": [],
    "imageUrl": "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/DSA.svg",
    "description": "This course is designed to have rapid preparation for interviews. Here the mentor will be preparing you for interviews and will groom you for that. Will cover all necessary topics and problems which are asked in the interview. He will make sure that students should be well prepared to crack interviews.",
    "mentor": "Bytecoder Professor",
    "learnings": [
      "Languages specific preparation (java",
      "python and JS) and interview questions",
      "100+ DSA practises problems for interview",
      "10+ System designs problems",
      "Mock interviews",
      "Assignments",
      "Practices problems and interview questions",
      "Mentoring and guidance",
      "Soft skills and communication grooming sessions"
    ],
    "duration": "6 weeks",
    "whoShouldLearn": "Candidates who have a good amount of technical skills and DSA knowledge. But nit able to crack the interview due to lack of interview experience and high level technical understanding.",
    "fee": 5000,
    "rating": "4.5",
    "skills": [
      "DSA",
      "system design",
      "Mock interviews",
      "assignments",
      "real life projects."
    ],
    "outcomes": "After completing this course, you will be able to crack any interview at this level. This will boost your knowledge and confidence in the interview.",
    "prerequisites": [],
    "reviews": [],
    "totalRegistrations": 324
  }
]



export function getAllCourses(search, interest)  {
  console.log("fetching courses")
  return  fetch( Congs.GET_ALL_COURSES+ "?search="+search+"&interest="+interest)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};

export function getPopulerShortcuts()  {
  console.log("fetching shortcuts")
  return  fetch( Congs.GET_ALL_POPULER_SHORTCUTS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


export function getCourseById(code)  {
  console.log("fetching course by id  " + code)
  return  fetch(Congs.GET_ALL_COURSE_BY_CODE )
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};



export function getAllEnrolledCourses(search, interest, count)  {
  console.log("fetching enrolled courses")
  return  fetch( Congs.GET_ALL_ENROLLED_COURSES+ "?search="+search+"&interest="+interest + "&count="+ count,{
    headers: Congs.getHeaders(),
  })
    .then((response) => response.json())
    .then((json) => json.slice(0, 4))
    .catch((e) => console.log("Error " + e));
};


export function getRecommendedCourses () {
  console.log("fetching courses")
  return  fetch( Congs.GET_RECOMMENDED_COURSES,{
    headers: Congs.getHeaders(),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};

export function getAllMentorshipCourses(search, interest)  {
  console.log("fetching courses")
  return  fetch(Congs.GET_ALL_MENTORSHIP_COURSES+ "?search="+search+"&interest="+interest)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


// ============================================================================

let questionObject = {
  id: "#12",
  inputType: "Text",
  input: { content: "Summmary" },
  outputType: "Text/Video/Example/Question",

  output: {
    content: "this is summary for the card",
    url: "https://google.com",
    question: {
      type: "multi",
      question: "Which programming languages are statically typed?",
      choices: ["JavaScript", "Python", "Java", "Ruby"],
    },
    reponse: {
      userAnswer: "",
      explanation: "",
      answer: "",
      wasAnswerCorrect: true,
    },
  },
  isBookMarked: false,
};



export const getSubjectQuickNotes = (code, topic) => {
  console.log(topic);
  let topicDetails = [
    {
      topic: "Array",
      quickSummary:
        "Arrays In data structure and algorithms (DSA), arrays are fundamental data structures that store a collection of elements of the same data type. Arrays provide a way to organize and access data efficiently, making them essential for various computational tasks. Here's a summary of arrays in DSA: Definition: An array is a contiguous block of memory that holds elements of the same data type. Elements are accessed using an index, which usually starts from 0 and increments sequentially.",
      bookmarked: [
        {
          id: "#1",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
      ],
      messages: [
        {
          id: 0,
          type: "Chat",
          input: "Summmaaary",
          response: "Arrays In data structure and algorithms",
          isBookMarked: false,
        },
        {
          id: 10,
          type: "Chat",
          input: "Chat Summmary",
          response: "Arrays In data structure and algorithms",
          isBookMarked: false,
        },
        {
          id: 1,
          type: "Chat",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
        {
          id: 1,
          type: "question",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
      ],
    },
    {
      topic: "LinkedList",

      messages: [
        {
          id: 0,
          type: "Chat",
          input: "Summmary",
          response: "LL In data structure and algorithms",
          isBookMarked: false,
        },
        {
          id: 1,
          type: "Chat",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
        {
          id: 1,
          type: "Chat",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
      ],
    },
    {
      topic: "Stack",

      messages: [
        {
          id: 0,
          type: "Chat",
          input: "Summmary",
          response: "Stack In data structure and algorithms",
          isBookMarked: false,
        },
        {
          id: 1,
          type: "Chat",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
        {
          id: 1,
          type: "question",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
      ],
    },
    {
      topic: "Queue",

      messages: [
        {
          id: 0,
          type: "Chat",
          input: "Summmary",
          response: "Queue In data structure and algorithms",
          isBookMarked: false,
        },
        {
          id: 1,
          type: "Chat",
          responseType: "Text",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
        {
          id: 1,
          type: "question",
          input: "here is your own notes",
          response: "nice work",
          isBookMarked: true,
        },
      ],
    },
  ];
  return topicDetails.filter((topicDetail) => topicDetail.topic === code)[0];
};

export const getTopics = (code) => {
  let topics = ["Array", "Stack", "Queue", "Tree"];
  const temp = fetch(Congs.BASE_URL)
    .then((response) => response.json())
    .then((json) => json.topicDetails)
    .catch(() => console.log("not found"));
  console.log(temp);
  // temp.map(t=> topics.push(t))
  for (let t in temp) {
    topics.push(t);
  }
  console.log(topics);

  return topics;
};

export function getPracticeChallenges(subject, selectedTopic) {
  return [
    {
      name: selectedTopic,
      difficulty: "Easy",
      link: "link",
    },
  ];
}

export function getSuggestedVideos(subject, topic) {
  return [
    {
      id: 1,
      type: "question",
      input: "here is your own notes",
      response: "/videos/ai.mp4",
      isBookMarked: true,
    },
    {
      id: 1,
      type: "question",
      input: "here is your own notes",
      response: "/videos/ai.mp4",
      isBookMarked: true,
    },
  ];
}

export function getSuggestedExamples(subject, topic) {
  return [
    {
      id: 1,
      type: "Chat",
      input: "here is your own notes",
      response: "nice work",
      isBookMarked: true,
    },
    {
      id: 19,
      type: "Chat",
      input: "here is your own notes",
      response: "nice work",
      isBookMarked: true,
    },
  ];
}

export function getAssignment(subject, topic = "*") {
  return [
    {
      id: 1,
      type: "multi",
      question: "Which programming languages are statically typed?",
      choices: ["JavaScript", "Python", "Java", "Ruby"],
      reponse: {
        userAnswer: "",
        explanation: "",
        answer: "",
      },
    },
    {
      id: 2,
      type: "text",
      question: "Explain the concept of virtual DOM.",
      reponse: {
        userAnswer: "",
        explanation: "",
        answer: "",
      },
    },
    {
      id: 3,
      type: "upload",
      question: "Upload an image or PDF showing your code implementation.",
      reponse: {
        userAnswer: "",
        explanation: "",
        answer: "",
      },
    },
    {
      id: 4,
      type: "single",
      question: "Which of the following is a CSS preprocessor?",
      choices: ["Sass", "jQuery", "React", "Vue"],
      reponse: {
        userAnswer: "",
        explanation: "",
        answer: "",
      },
    },
  ];
}
