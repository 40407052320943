import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Register.scss";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Layouts from "../MaintainancePages/Layout/Layouts";
import { useEffect } from "react";
import { registerNewUser } from '../../../APIservice/LoginRegistrationApi'

const Register = () => {


  const [isMobile, setMobile] = useState(false); // here we are checking that is mobile vide is active or not

  const checkIsMobile = () => {
    setMobile(window.innerWidth <= 850);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    profile_image_url: null,
    gender: '',
    exam_preference: ''
  });



  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }

    if (!formData.name) {
      newErrors.name = 'Full name is required';
    } else if (formData.name.length < 3) {
      newErrors.name = 'Full name must be at least 3 characters long';
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }


    if (!formData.gender) {
      newErrors.gender = 'Gender is required';
    }

    if (!formData.exam_preference) {
      newErrors.exam_preference = 'Exam preference is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const navigate = useNavigate();



  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {

      registerNewUser(formData).then(async response => {
        const result = await response.json();
        if (!response.ok) {
          throw new Error(`${result.detail}`);
        }
        toast.success("you are successfully register, please login.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }).catch((err) => {
        toast.error(`${err}`);
      })
    }
  };

  return (
    <div className="register-container">
      <Layouts />


      <div className="circle-one"></div>
      <div className="circle-two"></div>

      <div className="register-right-container">
        {/* <div className="circle-two"></div> */}
        <div className="register-form-heading">
          <div>
            <h3>Sign Up</h3>
            <p className="login-custom-underline">
              Join Us and start your unstoppable journey!
            </p>
          </div>
          {!isMobile &&

            <div className="mobile-svg-image">
              <img src="/images/login-page-image.svg" alt="" />
            </div>
          }
        </div>

        <div className="register-types">
          <form className="register-form">
            <div className="user-email">
              <label>Full Name <span className="asterisk">*</span></label>
              <input
                value={formData.name}
                type="text"
                minLength={3}
                placeholder=""
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              {errors.name && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.name}</p>}

            </div>
            <div className="user-email">
              <label>Email <span className="asterisk">*</span></label>
              <input
                value={formData.email}
                type="email"
                placeholder=""
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              {errors.email && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.email}</p>}

            </div>
            <div className="user-email">
              <label>Password <span className="asterisk">*</span></label>
              <input
                value={formData.password}
                type="password"
                placeholder=""
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              />
              {errors.password && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.password}</p>}

            </div>
            <div className="user-gender">
              <label>
                Gender <span className="asterisk">*</span>
              </label>
              <select
                className="user-preference-dropdowns"
                value={formData.gender}
                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              {errors.gender && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.gender}</p>}

            </div>

            <div className="user-preference">
              <label>
                Exam Preference <span className="asterisk">*</span>
              </label>
              <select
                className="user-preference-dropdowns"
                value={formData.exam_preference}
                onChange={(e) => setFormData({ ...formData, exam_preference: e.target.value })}
              >
                <option value="">Select Exam</option>
                <option value="NEET">NEET</option>
                <option value="IIT">IIT</option>
              </select>
              {errors.exam_preference && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.exam_preference}</p>}

            </div>



            <div className="user-phone">
              <label>Mobile Number <span className="asterisk">*</span></label>
              <input
                value={formData.phone}
                type="number"
                minLength={10}
                placeholder=""
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
              {errors.phone && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.phone}</p>}

            </div>


            <div className="term-and-condiiton">


              <input type="button" value="Register" onClick={handleSubmit} />
              <ToastContainer />
            </div>

          </form>

          <div className="vertical-line"></div>

          <div className="or-and-lines">
            <div className="line1"></div>
            <div>
              <p>or</p>
            </div>
            <div className="line2"></div>
          </div>

          <div className="other-login-auth" >
            <p>Sign In With Google</p>

            <Link style={{ margin: '1rem' }}>
              <div className="google-auth">
                <img src="/images/google.svg" alt="" />
                <span style={{ fontSize: '1rem', }}>
                  Sign In with Google
                </span>
              </div>
            </Link>
          </div>
        </div>

   

        <p style={{marginTop:'1rem'}}>
          Already have an Account? <Link to={"/login"} style={{ color: '#02720d' }} >Login Here</Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default Register;
