import React, { useEffect, useState } from "react";
import "./AllCareerTrackCradsPage.css";
import { useNavigate } from "react-router-dom";
import * as CoursesApi from "../../../../../APIservice/CoursesApi";

const AllCareerTrackCradsPage = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [recommendedcourses, setRecommendedCourses] = useState([]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) => setCourses(cs));
    CoursesApi.getRecommendedCourses().then((cs) => setRecommendedCourses(cs));
  }, []);
  return (
    <div className="all-career-track-card-page-container">
      <h1>Career Tracks</h1>
      <div className="dash-subject-progress-container-career">
        {courses.map((course, idx) => (
          <div key={idx} className="subject-item">
            <div className="course-name">
              {course.progress !== 100 && (
                <div className="progress-track-box">In Progress</div>
              )}
              <h2>{course.title}</h2>
              <p>{course.currentChapterTitle}</p>
              <button
                className="subjects-resume-track-buttton"
                onClick={() => {
                  navigate("/career-tracks/" + course.code + "");
                }}
              >
                Resume Track
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCareerTrackCradsPage;
