import React, { useState, useEffect } from "react";
import "./ExpertsPage.css";
import SearchBar from "../SearchBar/SearchBar";
import ExpertCard from "../ExpertCard/ExpertCard";
import { expertInfo } from "../../../APIservice/ExpertCardInfo"; // Import the expertInfo array
import Filter from "../../../GenericComponents/Filter/Filter";
import { Filter1Outlined, Filter2Outlined, Filter3Sharp, Filter5, FilterAlt, FilterAltOffOutlined, FilterAltSharp } from "@mui/icons-material";


const ExpertsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState(""); // State to store the selected role


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".expert-page-filter")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  
  const limitedExperts = () => {
    if (!role) {
      return expertInfo.filter((expert) => expert.id >= 1 && expert.id <= 10);
    } else {
      return expertInfo.filter((expert) => expert.studentRole === role);
    }
  };

  return (
    <div className="experts-page">
      <div className="experts-page-byteconnect-forge-path-container">
        <div className="experts-page-byteconnect-forge-path-text-search-container">
          {/* <div className="experts-page-forge-text-container">
            <h1 className="experts-page-byteconnect-forge-path-text-heading">
              Forge your path with expert guidance!
            </h1>
            <p className="experts-page-byteconnect-forge-path-text-paragraph">
              Unlock a world of possibilities through one-on-one sessions with
              industry professionals, alumni, and faculty. Connect, Learn, and
              Thrive with EduConnect - your gateway to personalized mentorship
              and career exploration.
            </p>
          </div> */}
          <div className="experts-page-byteconnect-forge-path-search-bar">
            <SearchBar />
          </div>
          <div className="expert-page-filter">
            <img src={'/images/filter-lines.png'} />
            <div onClick={() => setIsOpen(!isOpen)}>Filter</div>
            {isOpen && <Filter />}
          </div>
        </div>
      </div>
      <div className="experts-page-buttons-container">
        <button
           className={`experts-page-buttons ${role === "Chemistry Expert" && "active"}`}
          onClick={() => setRole("Chemistry Expert")}
        >
          Chemistry Expert
        </button>
        <button
         className={`experts-page-buttons ${role === "Physics Expert" && "active"}`}
          onClick={() => setRole("Physics Expert")}
        >
          Physics Expert
        </button>

        <button
          className={`experts-page-buttons ${role === "Mathematics Expert" && "active"}` }
          onClick={() => setRole("Mathematics Expert")}
        >
          Mathematics Expert
        </button>
        <button
          className={`experts-page-buttons ${role === "Exam Helpers" && "active"}`}
          onClick={() => setRole("Exam Helpers")}
        >
          Exam Helpers
        </button>
        <button
          className={`experts-page-buttons ${role === "Strategy Planners" && "active"}`}
          onClick={() => setRole("Strategy Planners")}
        >
          Strategy Planners
        </button>
        <button
          className={`experts-page-buttons ${role === "Career Advicers" && "active"}`}
          onClick={() => setRole("Career Advicers")}
        >
          Career Advicers
        </button>
      </div>
      <div className="experts-container">
        <ExpertCard className="experts-container" experts={limitedExperts()} />
      </div>
    </div>
  );
};

export default ExpertsPage;
