import React from "react";
import "./Cart.css";
import { useSelector,useDispatch } from "react-redux";
import { removeFromCart,updateQuantity } from "../../../Redux/slices/cartSlice";
import { useNavigate } from "react-router-dom";

function Cart() {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch=useDispatch();
  const navigate=useNavigate();

   const handleRemoveFromCart = (item) => {
     dispatch(removeFromCart(item));
   };

   const decreaseQuantity = (item) => {
     if (item.quantity > 1) {
       dispatch(updateQuantity({ id: item.id, quantity: item.quantity - 1 }));
     }
   };

   const increaseQuantity = (item) => {
     dispatch(updateQuantity({ id: item.id, quantity: item.quantity + 1 }));
   };

   const handleProceed=()=>{
    navigate("/addDetails")
   }
   const currentDate=new Date();
   const deliveryDate=new Date(currentDate);
   deliveryDate.setDate(currentDate.getDate() + 3);

   const totalPrice=cartItems.reduce((total,item)=>total+ (item.price-item.discount)*item.quantity,0);
   const totalQuantity=cartItems.reduce((total,item)=>total+item.quantity,0);
  
   return (
     <div>
       {cartItems.length === 0 ? (
         <div
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             height: "80vh",
           }}
         >
           No items in the cart!
         </div>
       ) : (
         <div className="cart-container">
           <div className="cart-detail">
             <ul>
               {cartItems.map((item) => (
                 <li key={item.id}>
                   <div className="image-details">
                     <img src={item.image} alt={item.name} />
                     <div>
                       <p>{item.name}</p>
                       <p>Quantity: {item.quantity}</p>
                       <button onClick={() => handleRemoveFromCart(item)}>
                         Remove
                       </button>
                     </div>
                     <div className="quantity">
                       <span
                         className="button"
                         onClick={() => decreaseQuantity(item)}
                       >
                         -
                       </span>
                       <span>{item.quantity}</span>
                       <span
                         className="button"
                         onClick={() => increaseQuantity(item)}
                       >
                         +
                       </span>
                     </div>
                   </div>

                   <div
                     style={{
                       width: "30%",
                       fontSize: "0.8rem",
                       fontWeight: "200",
                     }}
                   >
                     <span>
                       Delivered by{" "}
                       {new Date(
                         new Date().getTime() + 3 * 24 * 60 * 60 * 1000
                       ).toDateString()}
                     </span>
                   </div>
                 </li>
               ))}
             </ul>
           </div>

           <div className="pricing">
             <div>
               <h3>Price Details</h3>
             </div>
             <div className="price flex">
               <span>Price ({totalQuantity} items)</span>
               <span>₹ {totalPrice}</span>
             </div>
             <div className="discount flex">
               <span>Discount</span>
               <span>
                 ₹{" "}
                 {cartItems.reduce(
                   (total, item) => total + item.discount * item.quantity,
                   0
                 )}
               </span>
             </div>
             <hr />
             <div className="total flex">
               <span>Total</span>
               <span>₹ {totalPrice}</span>
             </div>
             <div onClick={handleProceed} className="checkout-button">Proceed to Checkout</div>
           </div>
         </div>
       )}
     </div>
   );
    
  
}

export default Cart;
