import React, { useContext, useState, useEffect, useRef } from "react";
import GlobalContext from "../../../context/GlobalContext";
import "./Labels.css";
import { calendarEventsInfo } from "../../../APIservice/CalendarEvents";
import CalendarEventDetails from "../CalenderEventDetails/CalenderEventDetails";

export default function Labels() {
  const { labels, updateLabel } = useContext(GlobalContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const eventDetailsRef = useRef(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseDetails = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventDetailsRef.current && !eventDetailsRef.current.contains(event.target)) {
        setSelectedEvent(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <p className="label-header">Upcoming Events</p>

      {calendarEventsInfo.map((event, idx) => (
        <div
          key={idx}
          className="upcoming-events-container"
          style={{
            backgroundColor: `${event.urgencyColor}`,
            cursor: "pointer",
          }}
          onClick={() => handleEventClick(event)}
        >
          <h2>{event.date}</h2>
          <div className="upcoming-events-details-container">
            <h3>{event.calendarEvent}</h3>
            <p>{event.calendarEventDetails}</p>
          </div>
        </div>
      ))}

      {/* Render the selected event details component */}
      {selectedEvent && (
        <div ref={eventDetailsRef}>
          <CalendarEventDetails event={selectedEvent} />
        </div>
      )}
    </React.Fragment>
  );
}
