import React, { useState } from "react";
import "./Books.css";
import { PlayArrowOutlined } from "@mui/icons-material";
import { bookLibrary } from "../../../APIservice/LibraryBooks";
import { Link, useNavigate } from "react-router-dom";
import BooksHistory from "../BooksHistory/BooksHistory";

function Books() {
  const categories = [
    "Recommended",
    "All",
    "React",
    "Kafka",
    "Advance Java",
    "Python",
    "AI/ML",
    "System Design",
  ];

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const readBookClick = (bookId) => {
    navigate(`/library/books/${bookId}`);
  };

  const getFilteredBooks = () => {
    if (selectedCategory === "All") {
      return bookLibrary;
    } else {
      return bookLibrary.filter(
        (booksCategory) =>
          booksCategory.category === selectedCategory ||
          (selectedCategory === "Recommended" &&
            booksCategory.category === "Recommended")
      );
    }
  };
  const historyBooks = bookLibrary
    .find((booksCategory) => booksCategory.category === selectedCategory)
    .books.slice(0, 3);
  return (
    <div className="library-books-container">
      <div className="filter-books-tags">
        <ul className="chips-container">
          {categories &&
            categories.map((category) => (
              <li
                key={category}
                onClick={() => handleCategoryClick(category)}
                className={selectedCategory === category ? "active" : ""}
              >
                {category}
              </li>
            ))}
        </ul>
      </div>
      <h1>Continue Reading</h1>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="library-book-card-container">
          {historyBooks.map((librarybook) => (
            <div key={librarybook.id} className="book-card-history-recent">
              <div className="book-bg">
                <img src="/images/bytegpt-bg.png" alt="" />
              </div>
              <div className="book-card-image">
                <img src={librarybook.book_image} alt="" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="book-card-info">
                  <p className="book-title">{librarybook.book_title}</p>
                  <p className="description">{librarybook.book_description}</p>
                </div>
                <div className="read-download-book">
                  <span
                    className="read-book"
                    onClick={() => readBookClick(librarybook.id)}
                  >
                    Continue Reading
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/library/bookshistory/all">
          <div className="books-view-all-button">
            <img src={'/images/arrow.png'} alt="arrow" />

            <p>View All</p>
          </div>
        </Link>
      </div>
      <div className="allBooks-container">
        {getFilteredBooks().map((booksCategory) => (
          <div key={booksCategory.id} className="library-book-topic-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>{booksCategory.category}</h2>
              <Link to="/library/allrecommendedbooks">
                <h2>View All</h2>
              </Link>
            </div>
            <div className="library-book-card-container">
              {booksCategory.books.map((librarybook) => (
                <div key={librarybook.id} className="book-card">
                  <div className="book-bg">
                    <img src="/images/bytegpt-bg.png" alt="" />
                  </div>
                  <div className="book-card-image">
                    <img src={librarybook.book_image} alt="" />
                  </div>
                  <div className="book-card-info">
                    <p className="book-title">{librarybook.book_title}</p>
                    <p className="description">
                      {librarybook.book_description}
                    </p>
                  </div>
                  <div className="read-download-book">
                    <span
                      className="read-book"
                      onClick={() => readBookClick(librarybook.id)}
                    >
                      Read
                    </span>
                    <span className="download-book">Download</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Books;
