import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import * as CoursesApi from "../../APIservice/CoursesApi";
import "./CareerPath.css";
import EnrolledCareerTracks from "./EnrolledCareerTracks/EnrolledCareerTracks";
import UnrolledCareerTracks from "./UnRolledCareerTracks/UnrolledCareerTracks";

function CareerPath() {
  const [courses, setCourses] = useState([]);
  const userInfo = useContext({});
  const userName = userInfo ? userInfo.name : "Mayank";



  useEffect(() => {
    CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) => setCourses(cs));
  }, []);


  return (
    <div className="programs-container">
      {
        courses.length !== 0 ? <EnrolledCareerTracks /> : <UnrolledCareerTracks />
      }

    </div>
  );

}

export default CareerPath;
