import React, { useEffect, useState } from "react";
import { DASH_PROGRESS_CARD } from "../../../APIservice/DashboardsProgressCard";
import "./SubscriptionsComponent.css";
import { useNavigate } from "react-router-dom";
import * as courseApi from "../../../APIservice/CoursesApi";
import { getALLSubscriptionsHistory } from "../../../APIservice/QP/QuestionPaper";

const SubscriptionsComponent = () => {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    let user_id = localStorage.getItem("user_id");
    getALLSubscriptionsHistory(user_id).then((result) => {
      console.log(result);
      return setSubscriptions(result);
    });
  }, []);

  return (
    <div className="profile-courses-container">
      <div className="profile-courses-details">
        <div className="user-ongoing-courses">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.1rem",
              marginBottom: "1rem",
            }}
          >
            Ongoing
          </p>

          <div className="ongoing-courses-container">
            {subscriptions.filter(subscription => subscription.is_active).map((course) => {
              return (
                <div className="ongoing-courses-card">
                  <div className="courses-certi-and-name">
                    <div className="certi-img">
                      {/* <img src={course.exam.image_url} alt="" /> */}
                      <img src="https://img.freepik.com/free-vector/gradient-english-school-illustration_23-2149477711.jpg?size=338&ext=jpg&ga=GA1.1.2116175301.1718582400&semt=ais_user" alt="" />

                    </div>

                    <div className="courses-name-and-progress">
                      <p style={{ fontSize: '1.2rem' }}>{course.exam.title}</p>
                      <p style={{ color: "#02720d" }}>
                        {course.plan.plan_name}
                      </p>

                    </div>
                  </div>
                  <div>
                    <p style={{fontSize:'1rem'}}>
                      End date {course.end_date}

                    </p>
                  </div>
                  <div className="go-and-download-courses">
                    <div
                      className="go-to"
                      onClick={() => navigate(`/questionpapers/${course.exam.id}`)}
                    >
                      Continue
                    </div>

                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="completed-courses">
          <div className="completed-and-end-course">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              Completed
            </p>

            {/* <div className="end-courses">
              <p>End the Course</p>
            </div> */}
          </div>

          <div className="ongoing-courses-container">
            {subscriptions.filter(subscription => !subscription.is_active).map((course) => {
              return (
                <div className="ongoing-courses-card">
                  <div className="courses-certi-and-name">
                    <div className="certi-img">
                      {/* <img src={course.exam.image_url} alt="" /> */}
                      <img src="https://img.freepik.com/free-vector/gradient-english-school-illustration_23-2149477711.jpg?size=338&ext=jpg&ga=GA1.1.2116175301.1718582400&semt=ais_user" alt="" />
                    </div>

                    <div className="courses-name-and-progress">
                      <p style={{ fontSize: '1.2rem' }}>{course.exam.title}</p>
                      <p style={{ color: "#02720d" }}>
                        {course.plan.plan_name}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p style={{color:'red', fontSize:'1rem'}}>
                      End date {course.end_date}

                    </p>
                  </div>

                  <div className="go-and-download-courses">
                    <div
                      className="go-to"
                      onClick={() => navigate(`/questionpapers/${course.exam.id}/subscriptions`)}
                    >
                      Renew Now
                    </div>

                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsComponent;
