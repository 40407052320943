import React from "react";
import "./CalenderEventDetails.css";

export default function CalendarEventDetails({ event }) {
  return (
    <div className="calendar-event-details">
      <h2 style={{ color: `${event.calendarEventColor}` }}>
        {event.calendarEvent} Details
      </h2>

      {/* Payment Details */}
      {event.calendarEvent === "Payment" && (
        <div className="calendar-event-details-body">
          <div
            style={{
              backgroundColor: `${event.urgencyColor}`,
              borderRadius: "10px",
            }}
            className="calendar-event-details-first-container"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <img src={event.calenderEventImage} alt={event.calendarEvent} />
                <h1>{event.calendarEventDetails}</h1>
              </div>

              <div className="calendar-event-copy-button">Copy</div>
            </div>
            <div className="calendar-event-transcation-details">
              <p>
                Transaction ID-{" "}
                <span>{event.paymentDetails.transactionID}</span>
              </p>
              <p>
                Transaction Time-{" "}
                <span>{event.paymentDetails.transactionTime}</span>
              </p>
              <p>
                Payment Status-{" "}
                <span
                  style={{
                    color:
                      event.paymentDetails.paymentStatus === "Success"
                        ? "green"
                        : "red",
                  }}
                >
                  {event.paymentDetails.paymentStatus}
                </span>
              </p>
            </div>
          </div>

          <div className="calendar-event-second-container">
            <h3 style={{ color: `${event.calendarEventColor}` }}>
              {event.calendarEvent} Overview
            </h3>
            <div className="calendar-event-second-container-location-details">
              <h1>Billing Address</h1>
              <div>
                {" "}
                <p>House: {event.paymentDetails.billingAddress.house}</p>
                <p>Street: {event.paymentDetails.billingAddress.street}</p>
                <p>City: {event.paymentDetails.billingAddress.city}</p>
              </div>
            </div>
            <div className="calendar-event-second-container-billing-details">
              <p>
                Mode of Payment
                <span> {event.paymentDetails.modeOfPayment}</span>{" "}
              </p>
              <p>
                Vendor Name <span> {event.paymentDetails.vendorName}</span>
              </p>
              <p>
                Course Fee <span>{event.paymentDetails.courseFee}</span>
              </p>
              <p>
                Status
                <span
                  style={{
                    color:
                      event.paymentDetails.status === "Success"
                        ? "green"
                        : "red",
                    fontWeight: "700",
                  }}
                >
                  {event.paymentDetails.status}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Interview Call Details */}
      {event.calendarEvent === "Interview Call" && (
        <div className="calendar-event-details-body">
          <div
            style={{
              backgroundColor: `${event.urgencyColor}`,
              borderRadius: "10px",
            }}
            className="calendar-event-details-first-container"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <img src={event.calenderEventImage} alt={event.calendarEvent} />
                <h1>{event.calendarEventDetails}</h1>
              </div>

              <div className="calendar-event-copy-button">Copy</div>
            </div>
            <div className="calendar-event-transcation-details">
              <p>Session ID: {event.sessionDetails.sessionID}</p>
              <p>Session Time: {event.sessionDetails.sessionTime}</p>
              <p>
                Session Status-{" "}
                <span
                  style={{
                    color:
                      event.sessionDetails.sessionStatus === "Ongoing"
                        ? "green"
                        : "red",
                  }}
                >
                  {event.sessionDetails.sessionStatus}
                </span>
              </p>
            </div>
          </div>
          <div className="calendar-event-second-container">
            <h3 style={{ color: `${event.calendarEventColor}` }}>
              {event.calendarEvent} Overview
            </h3>
            <div className="calendar-event-second-container-location-details">
              <h1>Interview Details</h1>
              <div>
                <p>
                  Meeting Link:{" "}
                  {event.sessionDetails.sessionOverview.meetingLink}
                </p>
                <p>
                  Meeting Duration:{" "}
                  {event.sessionDetails.sessionOverview.meetingDuration}
                </p>
                <p>
                  Invitees:{" "}
                  {event.sessionDetails.sessionOverview.invitees.join(", ")}
                </p>
              </div>
            </div>
            <div className="calendar-event-second-container-location-details">
              <h1>Description</h1>
              <div>
                {Array.isArray(
                  event.sessionDetails.sessionOverview.description.agenda
                ) ? (
                  <ul>
                    {event.sessionDetails.sessionOverview.description.agenda.map(
                      (item, index) => (
                        <li key={index}>-{item}</li>
                      )
                    )}
                  </ul>
                ) : (
                  <p>
                    {event.sessionDetails.sessionOverview.description.agenda}
                  </p>
                )}
              </div>
            </div>

            <button>Join Now</button>
          </div>
        </div>
      )}

      {/* Classes Details */}
      {event.calendarEvent === "Classes" && (
        <div className="calendar-venet-details-body">
          <div
            style={{
              backgroundColor: `${event.urgencyColor}`,
              borderRadius: "10px",
              width:"30rem"
            }}
            className="calendar-event-details-first-container"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <img src={event.calenderEventImage} alt={event.calendarEvent} />
                <h1>{event.calendarEventDetails}</h1>
              </div>

              <div className="calendar-event-copy-button">Copy</div>
            </div>
            <div className="calendar-event-transcation-details">
              <p>Class Name- {event.classDetails.className}</p>
              <p>Instructor- {event.classDetails.instructor}</p>
              <p>Class Time- {event.classDetails.classTime}</p>
              <p>Class Location- {event.classDetails.classLocation}</p>
              <p>
                Class Status-{" "}
                <span
                  style={{
                    color:
                      event.classDetails.classStatus === "Ongoing"
                        ? "green"
                        : "red",
                  }}
                >
                  {event.classDetails.classStatus}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
