import React, { useContext, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import "./EventModal.css"; // Import CSS file for EventModal styles

const labelsClasses = ["indigo", "gray", "green", "blue", "red", "purple"];

export default function EventModal() {
  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
  } = useContext(GlobalContext);

  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );
  const [activeButton, setActiveButton] = useState("Event");

  function handleSubmit(e) {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: daySelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowEventModal(false);
  }

  return (
    <div className="modal-container">
      <form className="modal-content">
        <header className="modal-header">
          <h2>{selectedEvent ? "Edit Event" : "New Event"}</h2>
          <button onClick={() => setShowEventModal(false)}>
            <span className="material-icons-outlined close-icon">close</span>
          </button>
        </header>
        <div className="modal-body">
          <div className="form-grid">
            <input
              type="text"
              name="title"
              placeholder="Add title"
              value={title}
              required
              className="input-field"
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="task-type">
              {["Event", "Out of office", "Task", "Appointment Schedule"].map(
                (btnLabel) => (
                  <button
                    key={btnLabel}
                    type="button"
                    className={`button ${
                      activeButton === btnLabel ? "active" : ""
                    }`}
                    onClick={() => setActiveButton(btnLabel)}
                  >
                    {btnLabel}
                  </button>
                )
              )}
            </div>
            <div className="calendar-event-time">
              <img src="/images/clock.svg" alt="clock" />
              <p>{daySelected.format("dddd, MMMM DD")}</p>
            </div>
            <div>Find a Time</div>
            <div className="calendar-event-modal-sub-containers">
              <img />
              <p className="calendar-event-sub-headings">Add Guests</p>
            </div> 
            <div className="calendar-event-modal-sub-containers">
              <img />
              <p className="calendar-event-sub-headings">Add Google Meet Video Conferencing</p>
            </div>
            <div className="calendar-event-modal-sub-containers">
              <img />
              <p className="calendar-event-sub-headings">Add Location</p>
            </div>
            <div className="calendar-event-modal-sub-containers">
              <img />
              <input
                type="text"
                name="description"
                placeholder="Add a description"
                value={description}
                required
                className="input-field"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="calendar-event-modal-sub-containers">
              <img />
              <div>
                <img />
                <p className="calendar-event-sub-headings">Create Meeting Notes</p>
              </div>
            </div>
            <div>Add Attachment</div>
            <div className="calendar-event-modal-sub-containers">
              <img />
              <div style={{display:"flex", flexDirection:"column"}}>
                <p className="calendar-event-sub-headings">Ganesh Chaudhary</p>
                <div style={{display:"flex", gap:"1rem"}}>
                  <p className="calendar-event-sub-headings">Busy</p>
                  <p className="calendar-event-sub-headings">Default Visiblity</p>
                  <p className="calendar-event-sub-headings">Notify 10 minutes before</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="modal-footer">
          {selectedEvent && (
            <button
              type="button"
              onClick={() => {
                dispatchCalEvent({
                  type: "delete",
                  payload: selectedEvent,
                });
                setShowEventModal(false);
              }}
              className="delete-button"
            >
              Delete
            </button>
          )}
          <button type="submit" onClick={handleSubmit} className="save-button">
            Save
          </button>
        </footer>
      </form>
    </div>
  );
}
