export const careerMentor = [
    {
        id:1,
        mentorImage: "/images/Rectangle 40358.png",
        mentorName:"Johny Depp",
        mentorProfession: "Principal Data Engineer",
        mentorExperience: "10+ Years Experience",

    },
    {
        id:2,
        mentorImage: "/images/Rectangle 40358.png",
        mentorName:"Henry Cavil",
        mentorProfession: "Data Analayst",
        mentorExperience: "10+ Years Experience",
        
    },
    {
        id:3,
        mentorImage: "/images/Rectangle 40358.png",
        mentorName:"Jacob Elordi",
        mentorProfession: "Software Engineer",
        mentorExperience: "10+ Years Experience",
        
    },
    
]