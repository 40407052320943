import React from "react";
import "./StatusDetailCard.css";
import { Checkmark } from "react-checkmark";
import { salaryDetails } from "../../JobDetailsPage/JobDetailsPage";

const StatusDetailsModal = ({ company, role, status, closeModal }) => {
  const getStatusColor = () => {
    switch (status) {
      case "Shortlisted":
        return "rgba(255, 152, 0, 1)";
      case "Accepted":
        return "rgba(7, 184, 24, 1)";
      case "Rejected":
        return "rgba(242, 20, 6, 1)";
      default:
        return "black";
    }
  };

  const getCheckmarkColor = (index) => {
    if (status === "Shortlisted") {
      return index < 2 ? "#3461eb" : "transparent";
    } else {
      switch (status) {
        case "Accepted":
          return "green";
        case "Rejected":
          return index < 3 ? "#3461eb" : "red";
        default:
          return "black";
      }
    }
  };
  const getFooterText = (index) =>{
    switch(status) {
      case "Shortlisted":
        return "We’re reviewing your profile please stay with us for updates";
      case "Accepted":
        return "You’ve selected for this job";
      case "Rejected":
        return "Sorry! We are not moving forward with your application";
      default:
        return "";
    }
  }

  const renderCheckmarkContent = (index) => {
    if (status === "Shortlisted" && index >= 2) {
      return <div className="numbered-box">{index + 1}</div>;
    } else {
      return <Checkmark size="2.5rem" color={getCheckmarkColor(index)} />;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-company-role-details">
            <h2>{company}</h2>
            <p>{role}</p>
          </div>
          <div className="modal-close material-icons-outlined close-icon" onClick={closeModal}>
            close
          </div>
        </div>
        <div className="modal-body">
          <div className="status-modal-body-details-containers">
          <div className="status-details-card-right-container">
            <h2>Status</h2>
            <div className="status-progress">
              {[...Array(4)].map((_, index) => (
                <React.Fragment key={index}>
                  <div className="status-progress-item">
                    <div className="status-progress-item-content">
                      {renderCheckmarkContent(index)}
                      <div className="status-progress-item-title">
                        {index === 0
                          ? "Application Submitted"
                          : index === 1
                          ? "Application Reviewed"
                          : index === 2
                          ? "Application Shortlisted"
                          : status === "Rejected"
                          ? "Rejected"
                          : "Hired"}
                      </div>
                    </div>
                  </div>
                  {index < 3 && (
                    <div
                      className="status-line"
                      style={{ backgroundColor: "rgba(161, 174, 190, 1)" }}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="status-details-card-right-container">
            <div className="status-details-card-salary-amount-container">
              <div className="status-details-card-salary-image-amount-box">
                <img src="/images/mdi_currency-inr.svg" />
                <h1>4,50,000</h1>
              </div>
              <div className="status-details-card-average-salary-text">
                Avg Salary
              </div>
            </div>
            <div className="status-details-card-salary-industry-employment-contact-container">
              <div className="status-details-card-salary-industry-container status-details-card-salary-industry-employment-contact-boxes">
                <img src={salaryDetails[0].industryImage} />
                <div className="status-details-card--sub-container">
                  <h1>{salaryDetails[0].industryType}</h1>
                  <p>{salaryDetails[0].industry}</p>
                </div>
              </div>
              <div className="status-details-card-salary-employment-container status-details-card-salary-industry-employment-contact-boxes">
                <img style={{background:"rgba(242, 236, 230, 1)", padding:"1rem", borderRadius:"50%"}} src={salaryDetails[0].employImage} />
                <div className="status-details-card--sub-container">
                  <h1>{salaryDetails[0].employType}</h1>
                  <p>{salaryDetails[0].employment}</p>
                </div>
              </div>
              <div className="status-details-card--salary-contact-container status-details-card-salary-industry-employment-contact-boxes">
                <img style={{background:"rgba(242, 236, 230, 1)", padding:"1rem", borderRadius:"50%"}}  src={salaryDetails[0].contactImage} />
                <div className="status-details-card--sub-container">
                  <h1>{salaryDetails[0].contactMail}</h1>
                  <p>{salaryDetails[0].contact}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
         
        </div>
        <div
          className="status-box"
          style={{ backgroundColor: getStatusColor() }}
        >
          {getFooterText()}
        </div>
      </div>
    </div>
  );
};

export default StatusDetailsModal;
