export const calendarEventsInfo = [
  {
    id: 1,
    date: "07 May",
    calendarEvent: "Payment",
    calenderEventImage: "/images/calpayment.svg",
    calendarEventDetails: "Purchased Python Course",
    urgencyColor: "rgba(255, 242, 230, 1)",
    calendarEventColor: "rgba(255, 149, 51, 1)",
    paymentDetails: {
      transactionID: "INBHNKKJ8582658585",
      transactionTime: "20:00 PM",
      paymentStatus: "Success",
      billingAddress: {
        house: "House 10",
        street: "BTM Layout, Stage - 1",
        city: "Bangalore",
      },
      modeOfPayment: "Credit Card",
      vendorName: "Razorpay",
      courseFee: "5000/- (Five thousand rupees only)",
      status: "Success",
    },
  },
  {
    id: 2,
    date: "07 May",
    calendarEvent: "Interview Call",
    calendarEventColor: "rgba(0, 169, 145, 1)",
    calenderEventImage: "/images/calvideo.svg",
    calendarEventDetails: "with Amazon INC",
    urgencyColor: "rgba(217, 242, 239, 1)",
    sessionDetails: {
     
      sessionID: "cgh-ghj-kjl",
      sessionTime: "20:00 PM",
      sessionStatus: "Ongoing",
      sessionOverview: {
        meetingLink: "meet.google.com/cgh-ghj-kjl",
        meetingDuration: "25 Mins",
        invitees: ["afzalshaik7878@gmail.com", "ganesh@bytecoder.in"],

        description: {
          agenda: [
            "Overview of your current academic progress.",
            "Discussion of upcoming coursework and deadlines.",
            "Q&A session for any academic or administrative queries.",
            "Goal-setting for your educational and personal development.",
          ],
        },
      },
    },
  },
  {
    id: 3,
    date: "07 May",
    calendarEvent: "Classes",
    calendarEventColor: "rgba(68, 9, 120, 1)",
    calenderEventImage: "/images/calvideo.svg",
    calendarEventDetails: "OOP - Java Class Ongoing",
    urgencyColor: "rgba(236, 230, 242, 1)",
    classDetails: {
      classStatus: "Ongoing",
      className: "OOP - Java",
      instructor: "John Doe",
      classTime: "10:00 AM - 12:00 PM",
      classLocation: "Virtual Classroom",
    },
  },
  {
    id: 4,
    date: "07 May",
    calendarEvent: "Payment",
    calendarEventColor: "rgba(255, 149, 51, 1)",
    calenderEventImage: "/images/calpayment.svg",
    calendarEventDetails: "Purchased Python Course",
    urgencyColor: "rgba(255, 242, 230, 1)",
    paymentDetails: {
      transactionID: "INBHNKKJ8582658585",
      transactionTime: "20:00 PM",
      paymentStatus: "Failed",
      billingAddress: {
        house: "House 10",
        street: "BTM Layout, Stage - 1",
        city: "Bangalore",
      },
      modeOfPayment: "Credit Card",
      vendorName: "Razorpay",
      courseFee: "5000/- (Five thousand rupees only)",
      status: "Falied",
    },
  },
];
