import React, { useEffect, useState, useRef } from "react";
import "./BlogsSearch.css";

const BlogsBlog = () => {
  const [blog, setBlog] = useState("");
  const [isBlogClicked, setIsBlogClicked] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState("");
  const blogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (blogRef.current && !blogRef.current.contains(event.target)) {
        setIsBlogClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectJob = (blog) => {
    setSelectedBlog(blog);
    setBlog(blog);
    setIsBlogClicked(false);
  };
  const handleBlogClick = () => {
    setIsBlogClicked(true);
  };

  const setBlogHandler = (e) => {
    setBlog(e.target.value);
    console.log(blog);
  };

  return (
    <div>
      {" "}
      <div className="blog-container-search-bar" ref={blogRef}>
        <div className="blog-search" onClick={handleBlogClick}>
          <input
            type="text"
            value={blog}
            onChange={setBlogHandler}
            placeholder="Search for Blogs"
          />
        </div>
      </div>
      {/* {isBlogClicked && (
        <div className="blog-page-search-results">
          <div>
            <p className="blog-page-search-results-heading">Recently Viewed</p>
            <ul className="blog-search-result-content">
              <li onClick={() => handleSelectJob("UI/UX Designer")}>
              Basics of Programming and Mentoring
              </li>
            </ul>
          </div>
          <p className="blog-page-search-results-heading">For you!</p>
          <ul className="blog-search-result-content">
            <li onClick={() => handleSelectJob("UI/UX Designer")}>
              <i className="fa-solid fa-magnifying-glass"></i> How to crack an interview
            </li>
            <li onClick={() => handleSelectJob("SQL Developer")}>
              <i className="fa-solid fa-magnifying-glass"></i> Generative AI blogs
            </li>
            <li
              onClick={() =>
                handleSelectJob("Partner Reinsurance Europe SE, Dublin")
              }
            >
              <i className="fa-solid fa-magnifying-glass"></i> How UI Design is important in products
            </li>
          </ul>
        </div>
      )} */}
    </div>
  );
};

export default BlogsBlog;
