import React, { useEffect, useState, useRef } from "react";
import "../JobsPage/JobsPage.css";
import "./AllJobsPage.css";
import JobCard from "../JobCard/JobCard";
import * as jobsApiService from "../../../APIservice/JobsApiService";
import Filter from "../../../GenericComponents/Filter/Filter";
import SearchJob from "../SearchJob/SearchJob";

const AllJobsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobFilters, setJobFilters] = useState([{ values: [] }]);
  const [status, setStatus] = useState("");


  useEffect(() => {
    jobsApiService.getAllJobOpennings(status).then((jobss) => {
      setJobs(jobss);
      console.log(jobss);
      console.log(jobs);
    });
    jobsApiService.getAllFilters().then((filters) => setJobFilters(filters));
  }, [status]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".job-page-filter")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="all-jobs">
      <div className="all-jobs-details-container">
        <div className="all-jobs-text-container">
         
        </div>
        <div className="all-jobs-search-bar-filter-container">
          <SearchJob />
          <div className=" job-page-filter">
            <img src={'/images/filter-lines.png'} alt="filter" />
            <div onClick={() => setIsOpen(!isOpen)}>Filter</div>
            {isOpen && <Filter />}
          </div>
        </div>
      </div>

      <div className="all-jobs-container-page">
        {jobs.map((job) => (
          <JobCard job={job} />
        ))}
      </div>
    </div>
  );
};

export default AllJobsPage;
