// Day.js

import React, { useContext, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs"; // Import dayjs library
import GlobalContext from "../../../context/GlobalContext";
import "./Day.css"; 

export default function Day({ day, rowIdx }) {
  const dayjsDate = useMemo(() => dayjs(day), [day]);
  const { setDaySelected, setShowEventModal, filteredEvents, setSelectedEvent } = useContext(GlobalContext);
  const [dayEvents, setDayEvents] = useState([]);

  useEffect(() => {
    // Filter events based on the day part of the date
    const events = filteredEvents.filter((evt) => dayjs(evt.day).isSame(dayjsDate, 'day'));
    setDayEvents(events);
  }, [filteredEvents, dayjsDate]);

  function getCurrentDayClass() {
    return dayjsDate.isSame(dayjs(), 'day') ? "current-day" : "";
  }

  return (
    <div className="day-container" onClick={() => setShowEventModal(true)}>
      <header className="day-header">
        {rowIdx === 0 && <p className="day-label">{dayjsDate.format("ddd").toUpperCase()}</p>}
        <p className={`day-number ${getCurrentDayClass()}`}>{dayjsDate.format("DD")}</p>
      </header>
      <div className="events-container">
        {dayEvents.map((evt, idx) => (
          <div key={idx} onClick={() => setSelectedEvent(evt)} className={`event ${evt.label}`}>
            {evt.title}
          </div>
        ))}
      </div>
    </div>
  );
}
