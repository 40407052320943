import React from "react";
import { RecordingApiData } from "../../APIservice/RecordingApi";
import { useParams, useNavigate } from "react-router-dom";

const RecordingCard = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log(RecordingApiData);

  const goToRecording = () => {
    navigate("/programs/" + params.courseCode + "/learn/sessions/" + "sessionsId"
    );
  };

  return (
    <div className="recordings-card-container">
      {RecordingApiData.map((record) => {
        return (
          <div className="recording-card" onClick={goToRecording}>
            <div className="recording-thumbnail">
              <img src={record.recordingImage} alt="" />
            </div>
            <p className="attended">{record.status}</p>
            <div className="recording-name-and-duration">
              <h2 className="recording-name">
                {record.recordingName}{" "}
                <span className="verify-icon">
                  <img src="/images/verified.svg" alt="" />
                </span>
              </h2>
              <div className="recording-duration">
                <div className="recording-date">
                  <i class="fa-regular fa-calendar"></i>
                  <p>{record.recordingDate}</p>
                </div>
                <div className="recording-time">
                  <i class="fa-regular fa-clock"></i>
                  <p>{record.recordingTime}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecordingCard;
