import React, { useState } from "react";
import "./Chapters.css";
import { careerTrackCourse } from "../../../../APIservice/CareerTrackCourses";
import ChapterCard from "./ChapterContinueCard/ChapterContinueCard";
import UserRating from "../../UserRating/UserRating";
import CommentSection from "../../../../GenericComponents/CommentSection/CommentSection";
import CourseTopicDetails from "../../CourseTopicDetails/CourseTopicDetails";

const Chapters = ({ courseCode }) => {

  const [currentPointIndex, setCurrentPointIndex] = useState(0);

  return (
    <div className="chapters-container">
      <div className="chapters-name">
        <h1>Course Track</h1>
        <h2>{careerTrackCourse.careerTrack}</h2>
        <p>{careerTrackCourse.description}</p>
      </div>
      <div className="chapter-body">
        <div
          className="chapter-body-chapter-card"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ChapterCard courseCode={courseCode} />
        </div>

        <CourseTopicDetails setCurrentPointIndex={0} />


        <div className="chapter-page-user-rating-section">
          <h1 className="chapter-page-user-rating-section-heading">
            Echoes of Experience
          </h1>
          <UserRating />
        </div>
        <div className="chapter-page-comment-section">
          <CommentSection />
        </div>
      </div>
    </div>
  );
};

export default Chapters;
