import * as Congs from './Configurations'


import axios from "axios";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    token: "c1",
  },
});

export const EnrolledCourses = async (payload) => {
  console.log("here is code post --> ", payload);

  try {
    const response = await axiosInstance.post(
      Congs.ENROLL_COURSE_API,
      JSON.stringify(payload)
    );

    console.log("here is code post --> ", payload);

    return response;
  } catch (error) {
    return error;
  }
};



export function getCourseChapters(course_code){
  console.log("fetching course content")
  return  fetch( Congs.GET_ENROLLED_COURSE_CHAPTERS_API + course_code + "/chapters",{
    headers: Congs.getHeaders()
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}

export function getCourseSessions(course_code){
  console.log("fetching course content")
  return  fetch( Congs.GET_ENROLLED_COURSE_SESSIONS_API + course_code + "/sessions",{
    headers: Congs.getHeaders()
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}


export function getCourseSessionById(course_code, id){
  console.log("fetching session by id " + id)
  return  fetch( Congs.GET_ENROLLED_COURSE_SESSIONS_API + course_code + "/sessions/" + id,{
    headers: Congs.getHeaders()
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}

export function getCoursePractices(course_code){
  console.log("fetching course practices")
  return  fetch( Congs.GET_ENROLLED_COURSE_PRACTICES_API + course_code + "/practices",{
    headers: Congs.getHeaders()
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}

export function getCourseNotes(course_code){
  console.log("fetching course notes")
  return  fetch( Congs.GET_ENROLLED_COURSE_NOTES_API + course_code + "/notes",{
    headers: Congs.getHeaders()
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}



