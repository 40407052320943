import React, { useState } from "react";
import "./SessionHistory.scss";
import { sessionHistory } from "../../../APIservice/SessionHistory";

const SessionHistory = () => {
  const [selectedSession, setSelectedSession] = useState(null);

  const handleViewDetails = (sessionId) => {
    setSelectedSession(sessionId === selectedSession ? null : sessionId);
  };

  return (
    <div className="session-history-page">
      <h1>Booked Session</h1>
      <div className="session-history-page-body">
        <div className="session-history-cards-container">
          {sessionHistory.map((session) => (
            <div key={session.id} className="session-card">
              <div className="session-details">
                <div className="session-details-header">
                  <div className="session-details-header-time">
                    <img src={session.clockImage} alt="clock" />
                    <p> {session.sessionDuration}</p>
                    <p>{session.meetingType}</p>
                  </div>
                  <p className="session-details-date"> {session.sessionDate}</p>
                </div>

                <div className="session-mentor-details">
                  <img
                    src={session.sessionSummary[0].sessionMentorProfile}
                    alt="mentor"
                  />
                  <div>
                    <h3>{session.sessionMentorshipType}</h3>
                    <p> {session.sessionMentorshipMentor}</p>
                  </div>
                </div>
              </div>
              <button
                className="view-details-btn"
                onClick={() => handleViewDetails(session.id)}
              >
                View Details
              </button>
            </div>
          ))}
        </div>
        <div
          className={`session-history-details-container ${
            selectedSession ? "visible" : ""
          }`}
        >
          {selectedSession &&
            sessionHistory.map(
              (session) =>
                session.id === selectedSession && (
                  <div key={session.id} className="session-details-panel">
                    <div className="session-details-panel-header">
                      <h3>Session Summary</h3>
                      <button>Book Again</button>
                    </div>
                    <div className="session-details-panel-body">
                      <div className="session-details-panel-second-container">
                        <div className="session-details-panel-second-container-time-details">
                          <div className="session-details-panel-second-container-time-details-dates">
                            <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              BOOKED ON
                              <div className="bold-session">
                                {session.sessionSummary[0].sessionBookedOn}
                              </div>
                            </p>
                            <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              SESSION DATE
                              <div className="bold-session">
                                {session.sessionDate}
                              </div>
                            </p>
                          </div>
                          <div className="session-details-panel-second-container-time-details-time">
                            <div className="session-details-panel-second-container-time-details-time">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>Time</p>
                                <div>
                                  <div
                                    style={{ display: "flex", gap: "0.5rem" }}
                                  >
                                    <div className="bold-session-time">
                                      {" "}
                                      {session.sessionStartTime}
                                    </div>
                                    <div className="bold-session-time">to</div>
                                    <div className="bold-session-time">
                                      {" "}
                                      {session.sessionEndTime}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="session-details-panel-second-container-time-details-duration">
                              {session.sessionDuration}
                            </p>
                          </div>

                          <p className="session-details-panel-second-container-time-details-attendees">
                            <span className="attendees-heading"> Attendees </span>
                            {session.sessionSummary[0].sessionAttendees.map(
                              (att, index) => (
                                <li key={index}>{att}</li>
                              )
                            )}
                          </p>
                        </div>
                        <div className="session-details-panel-second-container-counselling-details">
                          <div className="session-details-panel-second-container-counselling-details-mentor">
                            <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              Mentor Name<br></br>{" "}
                              <div className="bold-session">
                                {session.sessionMentorshipMentor}
                              </div>
                            </p>
                            <img
                              style={{ width: "3rem", height: "3rem" }}
                              src={
                                session.sessionSummary[0].sessionMentorProfile
                              }
                              alt="mentor"
                            />
                          </div>
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            Session Name<br></br>{" "}
                            <div className="bold-session">
                              {session.sessionSummary[0].SessionName}
                            </div>
                          </p>
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            Session Call Details <br></br>
                            <div className="bold-session">
                              {session.sessionSummary[0].sessionCallDetails}
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="session-history-description-container">
                        <p>Description:</p>
                        <ul>
                          {session.sessionSummary[0].sessionDescription.map(
                            (desc, index) => (
                              <li key={index}>{desc}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default SessionHistory;
