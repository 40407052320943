import React, { useEffect, useState } from "react";
import "./Login.scss"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layouts from "../MaintainancePages/Layout/Layouts";
import { loginUser } from '../../../APIservice/LoginRegistrationApi'
import { green } from "@mui/material/colors";

export default function Login(props) {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isMobile, setMobile] = useState(false); // here we are checking that is mobile vide is active or not
  const navigate = useNavigate();

  const checkIsMobile = () => {
    // Determine whether it's mobile or desktop based on screen size
    setMobile(window.innerWidth <= 850);
  };

  useEffect(() => {
    // Check initially when the component mounts
    checkIsMobile();

    // Add event listener to listen for window resize events
    window.addEventListener("resize", checkIsMobile);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);



  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!userEmail) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(userEmail)) {
      newErrors.email = 'Email is invalid';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("clicked!!!!!");


    if (validate()) {

      const updatedData = {
        email: userEmail,
        password: password,
      };

      loginUser(updatedData).then(async (response) => {
        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.detail);
        }

        console.log(result)
        setTimeout(() => {
          if (isMobile) {
            navigate("/mobile-maintainance");
          } else {
            toast.success("you are successfully logged in.");
            localStorage.setItem("loggedin", "true");
            localStorage.setItem("token", result.token);
            localStorage.setItem("user_id", result.user_info.id);
            navigate("/dashboard");
          }
        }, 1000);


      }).catch((error) => {
        toast.error(`${error}`);
      })

    }

  };

  return (
    <div className="login-container">
      <Layouts />

      <div className="circle-one"></div>
      <div className="circle-two"></div>
      <div className="login-right-container">
        <div className="login-form-heading">
          <div>
            <h3>Log In</h3>
            <p className="login-custom-underline">
              Securely access your Bytecoder account.
            </p>
          </div>
          {!isMobile &&
            <div className="mobile-svg-image">
              <img src="/images/login-page-image.svg" alt="" />
            </div>
          }
        </div>

        <div className="login-types">
          <form className="login-form">
            <div className="user-email">
              <label>
                Email <span className="asterisk">*</span>
              </label>
              <input
                value={userEmail}
                type="email"
                placeholder=""
                onChange={(e) => setUserEmail(e.target.value)}
              />
              {errors.email && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.email}</p>}

            </div>
            <div className="user-password">
              <label>
                Password <span className="asterisk">*</span>
              </label>
              <input
                value={password}
                type="password"
                placeholder=""
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="forget-and-login">
              <Link> Forget Password?</Link>

              <input
                type="button"
                placeholder="Login"
                value="Login"
                onClick={handleSubmit}
              />
              <ToastContainer />
            </div>
          </form>

          <div className="vertical-line"></div>

          <div className="or-and-lines">
            <div className="line1"></div>
            <div>
              <p>or</p>
            </div>
            <div className="line2"></div>
          </div>

          <div className="other-login-auth">
            <p className="sign-in-google">Sign In With Google</p>

            <Link>
              <div className="google-auth">
                <img src="/images/google.svg" alt="" />
                <span>
                  <p>Sign In with Google</p>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <p>
          New To Bytecoder <Link to={"/register"} style={{ color: '#02720d' }}>Sign Up Here</Link>{" "}
        </p>
      </div>
    </div>
  );
}

