import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as CoursesApi from "../../../../APIservice/CoursesApi";
import "./SubjectCourseCard.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SubjectCourseCard(props) {

  const navigate = useNavigate();
  const [mediaType, setMediaType] = useState({ imageUrl: "" });
  const params = useParams();
  const [courseCard, setCourseCard] = useState({ skills: [], learnings: [] });

  useEffect(() => {
    CoursesApi.getCourseById(params.courseCode).then((cs) => {
      setCourseCard(cs[0]);
      console.log(cs[0]);
    });
  }, []);

  const course = props.course;

  return (
    <div className="subject-course-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "1rem",
        }}
        key={course.id}
        // onClick={() => props.openCourse(course)}
        onClick={() => {
          navigate(
            "/programs/" + course.code 
          );
        }}
      >
        <img
          src={course.imageUrl}
          style={{ width: "99%", height: "50%", background: "black" }}
          alt="Course Image"
        />
        <div className="course-name-details">
          <img src="/images/Group 45576.svg" alt="lang" />
          <h3
            style={{
              width: "100%",

              fontWeight: "bold",
              fontSize: "16px",
              margin: 0,
            }}
          >
            {course.title} 
          </h3>
          <p
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "16px",
              margin: 0,
              color: "rgba(0, 0, 0, 0.5)",
            }}
          >
            10+ Hours
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubjectCourseCard;
