import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as CoursesApi from "../../../../APIservice/CoursesApi";
import SearchAndPopularTags from "../../../../GenericComponents/SearchAndPopularTags/SerachAndPopularTags";
import './AllCareerTrackRecommended.css'
import CareerCard from '../../../Subjects/Programs/CourseCard/CareerTrackCard';

const AllCareerTrackRecommended = () => {
    const [searchText, setSearchText] = useState("");
  const [courses, setCourses] = useState([]);
  const [populerShortcuts, setpopulerShortcuts] = useState([]);
  const [interest, setInterest] = useState("");

  useEffect(() => {
    CoursesApi.getAllCourses().then((cs) => setCourses(cs));
    CoursesApi.getPopulerShortcuts().then((shortcuts) =>
      setpopulerShortcuts(shortcuts)
    );
  }, []);

  useEffect(() => {
    CoursesApi.getAllCourses("", interest).then((cs) => setCourses(cs));
  }, [interest]);

  const searchCourse = (e) => {
    e.preventDefault();
    console.log("search " + searchText + " interest " + interest);
    CoursesApi.getAllCourses(searchText, interest).then((cs) => setCourses(cs));
  };
  const navigation = useNavigate();
  const openCourse = (code) => {
    navigation("/career-tracks/" + code);
  };

  return (
    <div className="all-subject-recommended-page">
    {/* Pass necessary props to SearchAndPopularTags */}
    <div  className="all-subject-recommended-page-search-container">
      {" "}
      <SearchAndPopularTags
        props={{
          interest,
          populerShortcuts,
          setInterest,
          searchText,
          setSearchText,
          searchEvent: searchCourse,
        }}
      />
    </div>

    <div className="subject-recommended-container">
      {courses.map((course) => (
        <CareerCard
          key={course.id}
          course={course}
          openCourse={() => openCourse(course.code)}
        />
      ))}
    </div>
  </div>
  )
}

export default AllCareerTrackRecommended