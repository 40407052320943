import React from "react";
import { useState } from "react";
import "./ProgressBar.css";
import { useEffect } from "react";

const ProgressBar = (props) => {
  const { filled } = props;
  const [progfilled, setProgFilled] = useState(0);

  useEffect(() => {
    if (progfilled < 100) {
      setProgFilled(progfilled);
    }
  }, [filled]);

  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{
          height: "100%",
          width: `${filled}%`,
          backgroundColor: "#102C42",
          transition: "width 0.5s",
        }}
      ></div>
      {/* <span className="progress-percent">{filled}</span> */}
    </div>
  );
};

export default ProgressBar;
