import React from "react";
import "./MyDashboard.css";
import "../Subjects/ProgramLearning/QuickNotes/QuickNotes.css";
import { useState } from "react";
import { useEffect } from "react";
import * as courseApi from "../../APIservice/CoursesApi";
import * as JobsApi from "../../APIservice/JobsApiService";
import NewEnrollDashboard from "./NewEnrollDashboard";
import { useNavigate } from "react-router";

import { Bar, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import QuestionPaperDashboard from "./QPComponent/QuestionPaperDashboard";
import BookedSession from "../ByteConnect/BookedSession/BookedSession";
import { mentorshipData } from "../../APIservice/MentorshipCard";
import * as loginApi from "../../APIservice/LoginRegistrationApi";

const MyDashboard = () => {
  const [courses, setCourses] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [userInfo, setUserInfo] = useState({})

  const limitedBooking = mentorshipData.filter(
    (mentor) => mentor.id >= 1 && mentor.id <= 2
  );
  useEffect(() => {
    courseApi.getAllEnrolledCourses("", "", 4).then((result) => {
      console.log(result);
      return setCourses(result);
    });

    JobsApi.getAllJobOpennings("Saved").then((jobs) => {
      console.log(jobs);
      setAppliedJobs(jobs);
    });

    let user_id = localStorage.getItem("user_id");
    if (user_id == null) {
      // navigate("/login")
      console.log("useruser")
      console.log(user_id)
    } else {
      loginApi.getUserInfo(user_id).then((response) => {
        console.log(response)
        if (response.status === 200) {
          return response.json().then(data => setUserInfo(data))
        } else {
          navigate("/login");
          return response.json()
        }
      })
        .catch((e) => {
          console.log("Error " + e)
          navigate("/login");

        });
    }

  }, []);

  const navigate = useNavigate();

  const handleProgressCard = (code) => {
    navigate("/programs/" + code + "/learn/chapters");
  };

  const handleViewAllJobs = () => {
    navigate("/jobs");
  };
  const data = {
    datasets: [
      {
        label: "Weekly Sales",
        data: [18, 12, 6],
        backgroundColor: [
          "rgba(7, 184, 24, 1)",
          "rgba(255, 142, 10, 1)",
          "rgba(242, 20, 6, 1)",
        ],
        borderColor: [
          "rgba(7, 184, 24, 1)",
          "rgba(255, 142, 10, 1)",
          "rgba(242, 20, 6, 1)",
        ],
        needleValue: 30,
        borderWidth: 1,
        cutout: "95%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };

  const gaugeNeedlePlugin = {
    id: "gaugeNeedle",
    afterDraw(chart, args, options) {
      const { ctx, chartArea } = chart;
      const dataset = chart.data.datasets[0];
      console.log("Dataset:", dataset);
      const needleValue = dataset.needleValue || 0; // Ensure needleValue is defined
      console.log("Needle Value:", needleValue);
      const dataTotal = dataset.data.reduce((a, b) => a + b, 0);
      const angle = (needleValue / dataTotal) * Math.PI * 2 - Math.PI / 2;
      const radius = chartArea.outerRadius * 0.6;

      // Draw the needle
      ctx.save();
      ctx.translate(chartArea.centerX, chartArea.centerY);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -2);
      ctx.lineTo(radius, 0);
      ctx.lineTo(0, 2);
      ctx.fillStyle = "#000"; // Set color to black
      ctx.fill();
      ctx.restore();
    },
  };

  const options = {
    plugins: {
      datalabels: {
        color: "",
      },
      legend: {
        position: "bottom",
        labels: {
          boxHeight: 10,
          boxWidth: 5,
        },
      },
    },
  };
  if (courses.length == 0) {
    return <NewEnrollDashboard />;
  } else {
    return (
      <div className="my-dashboard-body">
        <div className="my-dashboard-body-left-part">
          <div className="my-dashboard-header">
            <div className="my-dashboard-header-text-container">
              <h1>Hello {userInfo.name}</h1>
              <p>We missed you</p>
              <p>Learning is never done without error!</p>
            </div>
            <div className="my-dashboard-header-image-container">
              <img
                className="my-dashboard-header-image-container-image2"
                src={userInfo.gender == 'Female' ? "/images/female-student.webp" : "/images/image 2.svg"}
              />
            </div>
          </div>

          {/* enable disable enrolled courses  */}
          {
            false &&
            <div className="enrolled-progress">
              <h1>Enrolled Progress</h1>
              <div className="progress-card-container">
                {courses.map((course, idx) => {
                  return (
                    <div
                      key={course.id}
                      className="progress-card"
                      onClick={() => handleProgressCard(course.code)}
                    // style={{ backgroundColor: colors[idx] }}
                    >
                      <div className="progress-course-img">
                        <img src={course.imageUrl} alt="" />
                      </div>

                      <div className="progress-course-details">
                        <h4>{course.title}</h4>
                      </div>
                      <div className="prog-course-name-percent">
                        <img src="/images/playbutton.svg" />
                        <p>{course.currentChapterTitle}</p>
                      </div>
                      {/* <div
                    style={{
                      marginTop: "1rem",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                  >
                    <ProgressBar filled={course.progress} />
                  </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {/* enable disable booked sessions  */}
          {
            false &&
            <div className="dashboard-booked-session-container">
              <h1 className="dashboard-booked-session-container-heading">
                Booked Session
              </h1>
              <div className="dashboard-booked-session-container-mentorship-cards">
                <BookedSession mentors={limitedBooking} />
              </div>
            </div>
          }

          {/* enable disable Question papers  */}
          {
            true &&
            <div className="dashboard-qna-container">

              <div className="dashboard-qna-cards">
                <QuestionPaperDashboard />
              </div>
            </div>
          }
        </div>

        {
          false &&
          <div className="my-dashobard-body-byte-score">
            <div className="my-dashboard-byte-score-heading">
              <img src="/images/bytescore.svg" />
              <h1>Byte Score</h1>
            </div>
            <div
              style={{
                height: "19%",
                width: "90%",
                background: "rgba(249, 249, 249, 1)",
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Doughnut
                height={"100%"}
                data={data}
                plugins={[ChartDataLabels, gaugeNeedlePlugin]}
                options={options}
              />
            </div>
            <div className="my-dashboard-byte-score-results">
              <h1>You’re doing great!</h1>
              <p>But, we have few suggestions to improve your score. </p>
            </div>
            <div className="my-dashboard-byte-score-results-tips">
              <h1>TIPS</h1>
              <p>1. Complete the assignment on time</p>
              <p>2. Complete the tasks within the time</p>
              <p>3. Attempt all daily practice</p>
              <p>4. Focus on consistency</p>
            </div>
            <div className="my-dashboard-about-byte-score">
              <h1>What is Bytescore?</h1>
              <p>
                BYTESCORE, an AI model, evaluates student performance through
                assignments, course progress, and practice, providing personalized
                scores to support individual learning and guide educators in
                providing tailored support.
              </p>
            </div>
          </div>
        }
      </div>
    );
  }
};

export default MyDashboard;
