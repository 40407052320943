// CandidateForm.js

import React, { useState } from "react";
import "./CandidateProfilePage.css";
import UserProfile from "../UserProfile/UserProfile";
import { useNavigate } from "react-router-dom";
import UserProfileNav from "../UserProfileNav/UserProfileNav";
import SubscriptionsComponent from "../ProfileCourses/SubscriptionsComponent";

function CandidateForm() {
  const [nav, setNav] = useState("profile");

  return (
    <div className="candidate-form-container">
      <UserProfileNav nav={nav} setNav={setNav} />

      {nav === "profile" ? <UserProfile /> : <SubscriptionsComponent />}
    </div>
  );
}

export default CandidateForm;
