
import React, { useEffect, useState } from "react";
import { getAllActiveExamSubscriptions } from "../../../APIservice/QP/QuestionPaper";
import { getAllExams } from "../../../APIservice/QP/Exams";

import { Link, useNavigate } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import './QuestionPaperDashboard.scss'
import { ExamCard } from "../../QuestionPaper/ExamCard/ExamCard";

export default function QuestionPaperDashboard() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [recommendedSubscriptions, setRecommendedSubscriptions] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);

  let options = {
    page: page,
    limit: limit,
  }


  useEffect(() => {
    let user_id = localStorage.getItem("user_id");
    options = {
      ...options,
      page: page,
      limit: limit,
      query: query
    }
    getAllActiveExamSubscriptions(user_id, options).then(papers => setSubscriptions(papers['results']))
    getAllExams(options).then(papers => setRecommendedSubscriptions(papers['result']["results"]))


  }, [])
  return (
    <div className="questionPaper-home">
      {subscriptions.length != 0 ?
        <section className="exams-subscribed-container">
          <h3 className="dashboard-qna-container-heading">
            My Subscriptions
          </h3>
          <div className="subscribed-card-container">
          {subscriptions?.map((exam, index) => (
                     <ExamCard exam={exam.exam_details} />
                    ))}
          </div>
        </section>
        :
        < section className="explore-section">
          {/* --------- Explore Section ----------*/}
          <div className="title-viewAll">
            <h2>What do you like to explore </h2>
            {/* <Link to="/allpapers">
              <div>View All</div>
            </Link> */}
          </div>

          <div className="subscribed-card-container">
            {recommendedSubscriptions.map((exam) => (
                     <ExamCard exam={exam} />

            ))}
          </div>
        </section>
      }
    </div >
  )
} 