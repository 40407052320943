export const libraryVideos = [
         {
           id: 1,
           category: "History",
           videos: [
             {
               id: 1,
               title: "Introduction to Arrays",
               video_link:
                 "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
               thumbnail_link:
                 "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
               likes: 12,
               comment: 80,
               mostWatched: true,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 2,
               title: "Pointers",
               video_link:
                 "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
               thumbnail_link:
                 "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 3,
               title: "Linked List",
               video_link:
                 "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
               thumbnail_link:
                 "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 4,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 5,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 6,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 7,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
           ],
         },
         {
           id: 2,
           category: "DSA",
           videos: [
             {
               id: 1,
               title: "Introduction to Arrays",
               video_link:
                 "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
               thumbnail_link:
                 "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
               likes: 12,
               comment: 80,
               mostWatched: true,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 2,
               title: "Pointers",
               video_link:
                 "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
               thumbnail_link:
                 "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 3,
               title: "Linked List",
               video_link:
                 "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
               thumbnail_link:
                 "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 4,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
              id: 5,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 6,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
           ],
         },
         {
           id: 3,
           category: "Data Science",
           videos: [
             {
               id: 1,
               title: "Introduction to Arrays",
               video_link:
                 "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
               thumbnail_link:
                 "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
               likes: 12,
               comment: 80,
               mostWatched: true,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 2,
               title: "Pointers",
               video_link:
                 "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
               thumbnail_link:
                 "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 3,
               title: "Linked List",
               video_link:
                 "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
               thumbnail_link:
                 "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 4,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
           ],
         },
         {
           id: 4,
           category: "System Design",
           videos: [
             {
               id: 1,
               title: "Introduction to Arrays",
               video_link:
                 "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
               thumbnail_link:
                 "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
               likes: 12,
               comment: 80,
               mostWatched: true,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 2,
               title: "Pointers",
               video_link:
                 "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
               thumbnail_link:
                 "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 3,
               title: "Linked List",
               video_link:
                 "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
               thumbnail_link:
                 "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
               id: 4,
               title: "Queue",
               video_link:
                 "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
               thumbnail_link:
                 "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
               likes: 10,
               comment: 180,
               mostWatched: false,
               description:
                 'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
             },
             {
              id: 5,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 6,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
           ],
         },
         {
          id: 5,
          category: "Mock Interviews",
          videos: [
            {
              id: 1,
              title: "Introduction to Arrays",
              video_link:
                "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
              thumbnail_link:
                "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
              likes: 12,
              comment: 80,
              mostWatched: true,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 2,
              title: "Pointers",
              video_link:
                "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
              thumbnail_link:
                "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 3,
              title: "Linked List",
              video_link:
                "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
              thumbnail_link:
                "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 4,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
             id: 5,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
           {
             id: 6,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
          ],
        },
        {
          id: 6,
          category: "Career Guidelines",
          videos: [
            {
              id: 1,
              title: "Introduction to Arrays",
              video_link:
                "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
              thumbnail_link:
                "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
              likes: 12,
              comment: 80,
              mostWatched: true,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 2,
              title: "Pointers",
              video_link:
                "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
              thumbnail_link:
                "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 3,
              title: "Linked List",
              video_link:
                "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
              thumbnail_link:
                "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 4,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
             id: 5,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
           {
             id: 6,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
          ],
        },
        {
          id: 7,
          category: "Machine Learning",
          videos: [
            {
              id: 1,
              title: "Introduction to Arrays",
              video_link:
                "https://www.youtube.com/embed/bnL5PtGbRRU?si=Hl6sSRwxWTv4bEWK",
              thumbnail_link:
                "https://i.ytimg.com/vi/NptnmWvkbTw/maxresdefault.jpg",
              likes: 12,
              comment: 80,
              mostWatched: true,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 2,
              title: "Pointers",
              video_link:
                "https://www.youtube.com/embed/cVLw5HeL3JM?si=MYE0cIggvI-hG44m",
              thumbnail_link:
                "https://i.ytimg.com/vi/cVLw5HeL3JM/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 3,
              title: "Linked List",
              video_link:
                "https://www.youtube.com/embed/R9PTBwOzceo?si=wlmqUTWU2M2n4kfQ",
              thumbnail_link:
                "https://i.ytimg.com/vi/R9PTBwOzceo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
              id: 4,
              title: "Queue",
              video_link:
                "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
              thumbnail_link:
                "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
              likes: 10,
              comment: 180,
              mostWatched: false,
              description:
                'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
            },
            {
             id: 5,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
           {
             id: 6,
             title: "Queue",
             video_link:
               "https://www.youtube.com/embed/yzj0Ch01Exo?si=UnOrgoLGmRwSB6c2",
             thumbnail_link:
               "https://i.ytimg.com/vi/yzj0Ch01Exo/maxresdefault.jpg",
             likes: 10,
             comment: 180,
             mostWatched: false,
             description:
               'Introduction to ARRAYS is a comprehensive video tutorial aimed at beginners to understand the fundamental concept of arrays in programming. In this engaging presentation, viewers will learn the definition of arrays and how they function as a structured way to store and organize data in computer memory. The video covers basic array syntax, initialization, accessing elements, and common operations performed on arrays. Through clear explanations and illustrative examples, viewers will gain a solid understanding of how arrays work and their importance in programming. Whether you\'re new to coding or seeking to strengthen your foundational knowledge, "Introduction to ARRAYS" is an essential resource to kickstart your journey into the world of programming.',
           },
          ],
        },
        
       ];

export const categories = [
  "History",
  "All",
  "Mock Interviews",
  "Career Guidelines",
  "Machine Learning",
  "DSA",
  "System Design",
  "Data Science",
 
];
