import React, {useState} from "react";
import CreateEventButton from "../CreateEventButton/CreateEventButton";
import SmallCalendar from "../SmallCalendar/SmallCalendar";
import Labels from "../Labels/Labels";
import "./Sidebar.css"; // Import CSS file for Sidebar component styles

export default function Sidebar() {

  return (
    <aside className="sidebar">
      
      <SmallCalendar />
      <Labels   />
    </aside>
  );
}
