import React from "react";
import "./ChapterContinueCard.css";
import { Link } from "react-router-dom";
import { careerTrackCourse } from "../../../../../APIservice/CareerTrackCourses";

const ChapterCard = ({ courseCode }) => {
  // Accessing the first topic from careerTrackCourse
  const firstTopic = careerTrackCourse.topics[0];

  return (
    <div className="chapter-card-container">
      <div className="chapter-card-text-container">
        <div className="progress-track-box">In Progress</div>
        <h2>{firstTopic.topicTitle}</h2>
      
        <h3>{firstTopic.modules[0].subtopicHeading}</h3>
      </div>
      <Link to={`/programs/${courseCode}/learn/chapters`}>
        <div className="chapter-card-resume-button">Resume Track</div>
      </Link>
    </div>
  );
};

export default ChapterCard;
