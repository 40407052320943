import React, { useState } from "react";
import "./CourseTopicDetails.css";
import { careerTrackCourse } from "../../../APIservice/CareerTrackCourses";

const CourseTopicDetails = ({ setCurrentPointIndex }) => {
  const { topics } = careerTrackCourse;
  const [detailsVisible, setDetailsVisible] = useState(
    Array(topics[0].modules.length).fill(false)
  );
  const [currentPointIndex, setCurrentIndex] = useState(0);
  const [completedPoints, setCompletedPoints] = useState(
    Array(topics[0].modules[0].pointsCovered.length).fill(false)
  );

  const toggleDetails = (index) => {
    setDetailsVisible((prevDetailsVisible) => {
      const updatedVisibility = [...prevDetailsVisible];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  const navigateToPoint = (pointIndex) => {
    setCurrentPointIndex(pointIndex);
  };

  return (
    <div className="course-topic-details-container">
      {" "}
      {topics[0].modules.map((module, index) => (
        <div key={index} className="career-track-courses-container-module-card">
          <div className="career-track-courses-container-module-card-header-description-container">
            <div className="career-track-courses-container-module-card-header">
              <div className="career-track-courses-container-module-card-header-text">
                <h2>{index + 1}</h2>
                <h1>{module.subtopicHeading}</h1>
              </div>
            </div>
            <p>{module.subtopicDescription}</p>
          </div>
          <div className="career-track-courses-container-module-toggle-details-continue-button">
            <button
              className={`career-track-courses-container-module-toggle-details-button ${
                detailsVisible[index] ? "" : "hidden-details"
              }`}
              onClick={() => toggleDetails(index)}
            >
              {detailsVisible[index]
                ? "Hide Chapter Details"
                : "View Chapter Details"}
            </button>

            <button
              className={`career-track-courses-container-module-toggle-continue-button ${
                detailsVisible[index] ? "" : "hidden-details"
              }`}
            >
              Continue
            </button>
          </div>

          {detailsVisible[index] && (
            <ul className="career-track-courses-point-container">
              {module.pointsCovered.map((point, pointIndex) => (
                <li key={pointIndex} className="point-item">
                  <div className="point-item-checkbox-text-container">
                    <div className="checkbox">
                      {completedPoints[pointIndex] ? (
                        <div className="completed"></div>
                      ) : pointIndex === currentPointIndex ? (
                        <div className="current"></div>
                      ) : (
                        <div className="incomplete"></div>
                      )}
                    </div>
                    <div className="point-text">{point}</div>
                  </div>

                  <div
                    className="arrow"
                    onClick={() => navigateToPoint(pointIndex)}
                  >
                    <img className="arrow" src="/images/arrow.png" alt="arrow" />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default CourseTopicDetails;
