import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getExamYears, getExamsById, getExamSubscriptionStatus } from "../../../../APIservice/QP/Exams";
import { getAllPapers } from "../../../../APIservice/QP/QuestionPaper";
import { SubjectPaperCard } from "./SubjectPaperCard";

export function SubjectwiseDetails() {
  const { examId } = useParams();

  const [subjectPapers, setSubjectPapers] = useState([]);
  const [years, setYears] = useState([]);

  const [exam, setExam] = useState({})

  const [subscriptionStatus, setSubscriptionStatus] = useState(true)

  const [paperYear, setPaperYear] = useState('');


  const handleYearChange = (year) => {
    setPaperYear(year);
  };


  const shapeColor = ["#B0E4DD", "#C5B3D5", "#FFC28A", "#DAF3FA"];


  useEffect(() => {
    getExamYears(parseInt(examId)).then((years) => setYears(years.years))
    getExamsById(examId).then((exam) => setExam(exam))
    getExamSubscriptionStatus(examId).then((exam) => setSubscriptionStatus(exam.subscription_status))

  }, [])

  useEffect(() => {
    getAllPapers(parseInt(examId), paperYear).then((subscription) => setSubjectPapers(subscription.results))
  }, [paperYear])

  useEffect(() => {
    if (years?.length > 0) {
      setPaperYear(years[0]);

    }

  }, [years])





  return (
    <div>
      <div className="year">
        <p style={{ fontSize: '1rem' }}>Choose Year:</p>
        {years?.map((year) => (
          <button
            className={paperYear === year ? "active" : ""}
            onClick={() => handleYearChange(year)}
          >
            {year}
          </button>
        ))}
      </div>

      <div className="subjectContainer">
        {subjectPapers?.map((subjectPaper, index) => (
          <SubjectPaperCard subjectPaper={subjectPaper} subscriptionStatus={subscriptionStatus} />
        ))}
      </div>
    </div>
  );
}