import { React, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appliedJobs } from "../../../../APIservice/AppliedJobs";
import "./AppliedJobsCard.css";
import StatusDetailsCard from "../StatusDetailCard/StatusDetailCard";

const AppliedJobsCard = ({ company, role, status, image }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getStatusColor = () => {
    switch (status) {
      case "Shortlisted":
        return "rgba(255, 152, 0, 1)";
      case "Accepted":
        return "rgba(7, 184, 24, 1)";
      case "Rejected":
        return "rgba(242, 20, 6, 1)";
      default:
        return "black";
    }
  };

  const statusClassName = status === "Shortlisted" ? "shortlisted" : "other";

  return (
    <div className="applied-job-card">
      <div className="applied-jobs-card-company-details">
        <img src={image} alt="company" />
        <h3 className="applied-company-name">{company}</h3>
        <p className="applied-company-role">{role}</p>
      </div>
      <div className="applied-jobs-status-details-box">
        <div
          className={`applied-jobs-status ${statusClassName}`}
          style={{ backgroundColor: getStatusColor() }}
         
        >
          {status}
        </div>
        <div className="applied-job-details-btn"  onClick={openModal}>Details</div>
        {isModalOpen && (
          <StatusDetailsCard
            company={company}
            role={role}
            status={status}
            closeModal={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export default AppliedJobsCard;
