import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MeetingDetailsLeft from "./MeetingDetailsLeft/MeetingDetailsLeft";
import MeetingDetailsRight from "./MeetingDetailsRight/MeetingDetailsRight";
import { expertInfo } from "../../../APIservice/ExpertCardInfo";
import './ExpertMeetingDetails.css'

const ExpertMeetingDetails = () => {
  const { id, meetingIndex } = useParams(); // Extract the 'id' and 'meetingIndex' parameters from the URL
  const expert = expertInfo.find((expert) => expert.id === parseInt(id));
  const navigate = useNavigate(); // Hook to navigate to different routes

  if (!expert) {
    console.log("Expert not found with ID:", id);
    return null; // Render nothing if expert is not found
  }

  // Parse the meeting index as an integer
  const index = parseInt(meetingIndex);

  // Check if the index is a valid number and within the range of meetings
  if (isNaN(index) || index < 0 || index >= expert.expertMeeting.length) {
    console.log("Invalid meeting index:", meetingIndex);
    return null; // Render nothing if the index is invalid
  }

  const selectedMeeting = expert.expertMeeting[index];

  const handleConfirmDetails = (bookingDetails) => {
    // Navigate to the KnowYou page and pass the booking details as state
    navigate(`/byteconnect/expertsPage/expert-meeting-details/${id}/${meetingIndex}/knowyou`, {
      state: { bookingDetails },
    });
  };

  return (
    <div className="expert-meeting-details-page">
      <div className="expert-meeting-details-left-container">
        {/* Pass the selected meeting details and the function to handle confirming details */}
        <MeetingDetailsLeft expert={expert} selectedMeeting={selectedMeeting} />
      </div>
      <div className="expert-meeting-details-right-container">
        <MeetingDetailsRight />
      </div>
    </div>
  );
};

export default ExpertMeetingDetails;
