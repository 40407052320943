import { React, useState, useEffect } from "react";
import JobCard from "../../Pages/Jobs/JobCard/JobCard";
import * as jobsApiService from "../../APIservice/JobsApiService";
import "./Filter.css";

const Filter = () => {
  const [jobs, setJobs] = useState([]);
  const [jobFilters, setJobFilters] = useState([{ values: [] }]);
  const [jobeType, setJobeType] = useState([]);
  const [experienceLevel, setExperienceLevel] = useState([]);
  const [status, setStatus] = useState("");

  const applyFilter = (event) => {
    console.log(event.target.value);
  };

  useEffect(() => {
    jobsApiService.getAllJobOpennings(status).then((jobss) => {
      setJobs(jobss);
      console.log(jobss);
      console.log(jobs);
    });
    jobsApiService.getAllFilters().then((filters) => setJobFilters(filters));
  }, [status]);
  return (
    <div className="jobs-filters">
      <div className=" jobs-filter-options jobs-filter-options-expert-page ">
        <h4>Filters</h4>
        {jobFilters.map((filter) => (
          <div className="filter">
            <p>{filter.filterType}</p>
            {filter.values.map((value) => (
              <div className="checkboxes">
                <input
                  type="checkbox"
                  name={filter.filterType}
                  value={value}
                  id=""
                  onChange={(event) => applyFilter(event)}
                />
                <label htmlFor="">{value}</label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filter;
