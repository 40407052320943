import React from 'react';
import AppliedJobsCard from './AppliedJobsCard/AppliedJobsCard';
import { appliedJobs } from "../../../APIservice/AppliedJobs";
import './AppliedJobs.css';

const AppliedJobs = () => {
  // Slice the appliedJobs array to get only the first three elements
  const appliedJobsToShow = appliedJobs.slice(0, 5);

  return (
    <div className='applied-jobs-container-card-slice'>
      {appliedJobsToShow.map((job) => (
        <AppliedJobsCard
          key={job.id}
          image={job.image}
          company={job.companyName}
          role={job.role}
          status={job.status}
          job={job}
        />
      ))}
    </div>
  );
};

export default AppliedJobs;
