import React from "react";
import { useState } from "react";
import { questionQuiz } from "../../APIservice/QuestionerApi";
import "./QuestionerRightLayer.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ConfirmPopup from "../../GenericComponents/ConfirmPopUp/ConfirmPopup";

const QuestionerRightLayer = () => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { questions } = questionQuiz;

  const { question, choices, correctAnswer, type } = questions[activeQuestion];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleConfirmFinish = () => {
    // Perform any additional logic for finishing the quiz
    setIsModalOpen(false);

    console.log("Selected Options:", selectedAnswer);
    navigate("/thankyou");
  };

  const handleCancelFinish = () => {
    // User canceled finishing the quiz
    setIsModalOpen(false);
  };

  const onClickAnswer = (index) => {
    setSelectedAnswerIndex(index);

    // Store the selected option
    const selectedAnswer = {
      questionNumber: activeQuestion + 1,
      selectedAnswer: questions[activeQuestion].choices[index],
    };

    // Update or add the selected option to the state
    setSelectedAnswer((prev) => {
      const updatedOptions = [...prev];
      const existingOptionIndex = updatedOptions.findIndex(
        (option) => option.questionNumber === selectedAnswer.questionNumber
      );

      if (existingOptionIndex !== -1) {
        // Update the existing option
        updatedOptions[existingOptionIndex] = selectedAnswer;
      } else {
        // Add the new option
        updatedOptions.push(selectedAnswer);
      }

      return updatedOptions;
    });
  };

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    // setActiveQuestion((prev) => prev + 1);

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      openModal();
    }
  };

  const onClickBack = () => {
    if (activeQuestion > 0) {
      setActiveQuestion((prev) => prev - 1);
    }
  };
  return (
    <div
      style={{
        width: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div className="exit-btn" onClick={() => navigate("/profile")}>
        <i class="fa-solid fa-right-from-bracket"></i>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <h2>{question}</h2>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            // width: "70%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {choices.map((choice, index) => {
            const choiceClassName = `choice-${activeQuestion}-${index}`;
            console.log(choiceClassName);
            return (
              <div
                key={index}
                className={choiceClassName}
                style={{
                  borderRadius: "10px",
                  border: "1px solid lightgrey",
                  backgroundColor:
                    selectedAnswerIndex === index ? "#349367" : "white",
                  cursor: "pointer",
                }}
                onClick={() => onClickAnswer(index)}
              >
                <img
                  src={choice.imageUrl}
                  width={50}
                  height={50}
                  style={{ borderRadius: "10px" }}
                  alt=""
                />
                <p
                  style={{
                    width: "80%",
                    textAlign: "center",
                    fontSize: ".6rem",
                  }}
                >
                  {choice.questionName}
                </p>
                {type == "MCQs" ? (
                  <p>{choice.questionDesc}</p>
                ) : (
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                )}
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {activeQuestion > 0 && (
              <div className="back-btn" onClick={onClickBack}>
                <p>Back</p>
              </div>
            )}
          </div>
          <button
            className="questioner-btn"
            onClick={onClickNext}
            disabled={selectedAnswerIndex === null}
          >
            {activeQuestion === questions.length - 1 ? "Finish" : "Next"}
          </button>
        </div>

        {isModalOpen && (
          <ConfirmPopup
            isOpen={isModalOpen}
            message="Are you sure you want to finish the quiz?"
            onConfirm={handleConfirmFinish}
            onCancel={handleCancelFinish}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionerRightLayer;