import React, {useEffect, useState} from "react";
import "./ClassPlayer.css";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import * as enrolledApi from "../../../../../../APIservice/EnrolledApi";

const ClassPlayer = (props) => {
    const params = useParams()
    const navigate = useNavigate();
    const [session, setSessions] = useState({ });
  
    useEffect(() => {
      enrolledApi.getCourseSessionById(params.courseCode, params.sessionId).then((content) => {
        console.log(content)
        setSessions(content)
      });
      
    }, []);

    const goBack = () => {
        navigate(-1)
    }
  console.log(params.videoUrl)
  return (
    <div className="class-player-container">
      <div className="go-back-and-title">
        <div className="go-back" onClick={goBack}>
          <p>&lt; Go Back</p>
        </div>

        <div className="recording-lecture-name">
          <p>{session.title}</p>
        </div>
      </div>

      <div className="recording-player react-player">
        <ReactPlayer
          url={session.videoUrl}
          controls={true}
          width="80vw"
          height="500px"
        />
      </div>

      <div className="recording-actions">
        <div className="read actn-btn" onClick={() => navigate('/blogs')} >Read</div>
        <div className="assign actn-btn" onClick={() => navigate('/programs/:courseCode/learn/assignments')}>Assignment</div>
        <div className="watch actn-btn" onClick={() => navigate(-1)}>Watch</div>
      </div>

      <div className="create-class-notes">
        <div className="notes-heading">
          <img src="/images/record-notes.svg" alt="" />
          <p>Notes</p>
        </div>

        <div className="notes-area">
            <textarea className="notes-textarea" rows="10" placeholder="make a quick notes"></textarea>
        </div>
      </div>
    </div>
  );
};

export default ClassPlayer;
