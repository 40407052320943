import React from "react";
import "./UserProfileNav.css";
import { useNavigate } from "react-router-dom";

const UserProfileNav = (props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="user-profile-nav">
      {/* <div className="back-to-dashboard" onClick={handleBack}>
        <i className="fa-solid fa-angle-left"></i>
        <p>Go back Dashboard</p>
      </div> */}

      <div className="user-profile-and-courses">
        <div
          className={`user-profile ${props.nav === "profile" ? "active" : ""}`}
          onClick={() => props.setNav("profile")}
        >
          <i className="fa-regular fa-user"></i>
          <p>my profile</p>
        </div>
        <div
          className={`user-courses ${props.nav === "courses" ? "active" : ""}`}
          onClick={() => props.setNav("courses")}
        >

          <i className="fa-regular fa-file"></i>
          <p>My Subscriptions</p>
        </div>
      </div>
    </div>
  );
};

export default UserProfileNav;
