import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import * as CoursesApi from "../../../APIservice/CoursesApi"
import CareerTrackCard from '../CareerTracks/CareerTrackCard/CareerTrackCard';
import CareerCard from '../../Subjects/Programs/CourseCard/CareerTrackCard'


export default function EnrolledCareerTracks() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [recommendedcourses, setRecommendedCourses] = useState([]);
  const [isCareerTrackActive, setIsCareerTrackActive] = useState(true); // State to track if Career Track is active

  const openCourse = (code) => {
    navigate("/career-tracks/" + code);
  };

  useEffect(() => {
    CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) => setCourses(cs));
    CoursesApi.getRecommendedCourses().then((cs) => setRecommendedCourses(cs));
  }, []);
  return (
    <div>
      <div className="subjects-and-add-courses">
        <div className="subjects-heading">
          <h2>Welcome to Career Tracks,</h2>
          <Link to="/career-tracks-history">
            <div className="subject-course-progress--view-all-button">
              View All
            </div>
          </Link>
        </div>
        <CareerTrackCard cardLimit={2} />
      </div>
      <div className="discover-courses">
        <div className="program-container-header">
          <h2>Recommended For You !!</h2>
          <h3 onClick={() => navigate("/new-career-tracks")}>View All</h3>
        </div>
        <div className="program-card-container">


          {recommendedcourses.slice(0, 3).map((course) =>
            <CareerCard
              key={course.id}
              course={course}
              openCourse={() => openCourse(course.code)}
              isCareerTrack={true}
            />
          )}
        </div>
      </div>

    </div>
  )
}