import * as Congs from "../Configurations"

const GET_ALL_EXAMS = Congs.BASE_URL + "/api/v2/exams?page=1&limit=2";


export function getAllExams(fetchOptions) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  const url =`${Congs.BASE_URL}/api/v2/exams?page=${fetchOptions.page}`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);


  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getRecommendedExams( page = 1, limit = 4) {

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/recommended-exams?page=${page}&limit=${limit}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getExamsById(examId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}


export function getExamSubscriptionStatus(examId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/subscription-status`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}



export function subscribeExamPlan(userId, payload) {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/users/${userId}/exam-subscriptions`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}



export function getAllPlansOfExamination(examId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/plans`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}


export async function getExams() {
  console.log("fetching all exams...");

  try {
    const response = await fetch(
      GET_ALL_EXAMS, {
      headers: Congs.getHeaders()
    }
    );
    const json = await response.json();
    return json;
  } catch (e) {
    return console.log("Error " + e);
  }
}



export function getExamYears(examId) {


  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/years`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}
