import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ByteConnect.css";
import SearchBar from "./SearchBar/SearchBar";
import ExpertCard from "./ExpertCard/ExpertCard";
import { expertInfo } from "../../APIservice/ExpertCardInfo";
import BookingDetails from "./MentorshipCard/BookingDetails/BookingDetails";
import BookedSession from "./BookedSession/BookedSession";
import { mentorshipData } from "../../APIservice/MentorshipCard";

const ByteConnect = () => {
  const limitedExperts = expertInfo.filter(
    (expert) => expert.id >= 1 && expert.id <= 2
  );
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const limitedBooking = mentorshipData.filter(
    (mentor) => mentor.id >= 1 && mentor.id <= 2
  );

  const handleBookingDetails = () => {
    setShowBookingDetails(true);
  };

  return (
    <div className="byteconnect-container">
      <div className="byteconnect-forge-path-container">
        <div className="byteconnect-forge-path-text-container">
          <h1 className="byteconnect-forge-path-text-heading">
            Forge your path with expert guidance!
          </h1>
          <p className="byteconnect-forge-path-text-paragraph">
            Unlock a world of possibilities through one-on-one sessions with
            industry professionals, alumni, and faculty. Connect, Learn, and
            Thrive with EduConnect - your gateway to personalized mentorship and
            career exploration.
          </p>
          <div className="byteconnect-forge-path-search-bar">
            <SearchBar />
          </div>
        </div>
        <div className="byteconnect-forge-path-card-container">
          <ExpertCard
            className="byteconnect-forge-path-card-container"
            experts={limitedExperts}
          />
        </div>
        <div className="byteconnect-forge-path-next-section-arrow">
          <Link to="/byteconnect/expertsPage">
            <img src={'/images/arrow.png'} alt="arrow" />
          </Link>
        </div>
      </div>
      <div className="byteconnect-booked-session-container">
        <h1 className="byteconnect-booked-session-heading">Booked Session</h1>
        <div className="byteconnect-booked-session-mentorship-cards">
          <div>
            {" "}
            <BookedSession mentors={limitedBooking} />
          </div>
          {showBookingDetails && (
            <BookingDetails onClose={() => setShowBookingDetails(false)} />
          )}
          <div className="byteconnect-forge-path-next-section-arrow-two">
            <Link to="/byteconnect/bookingDetailsPage">
              <img src={'/images/arrow.png'} alt="arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ByteConnect;
