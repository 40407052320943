import { React, useState } from "react";
import "./KnowYou.css";
import { EditAttributesOutlined, EditNoteRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";

const KnowYou = ({ expert, selectedMeeting }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [bookings, setBookings] = useState([]);

  const handleToggleDetails = () => {
    setShowDetails((prevState) => !prevState);
  };

  const handleAddBooking = () => {
    const newBooking = {
      id: bookings.length + 1, // You can use a more robust ID generation logic
      title: "1:1 Data Career Power Hour",
      type: "Video Call",
      duration: "15 mins",
      price: 125,
    };
    setBookings([...bookings, newBooking]);
  };

  const handleConfirmAndPay = () => {
    // Logic for confirming and paying
    console.log("Confirm and pay:", bookings);
    // After payment confirmation, you can clear the bookings array or navigate to a payment page
    setBookings([]);
  };

  const handleEdit = () => {
    window.history.back(); // Navigate back to the previous page
  };

  const queryParams = new URLSearchParams(window.location.search);
  const date = queryParams.get("date");
  const timeZone = queryParams.get("timeZone");
  const time = queryParams.get("time");
  const selectedDate = new Date(date);
  const options = { weekday: "long" };
  const day = selectedDate.toLocaleDateString("en-US", options);

  return (
    <div className="know-you-container">
      <div className="know-you-meeting-details-left">
        <div className="know-you-meeting-booking-details">
          {" "}
          <h2 className="know-you-meeting-booking-details-heading-text">
            Booking Details
          </h2>
          <div className="know-you-meeting-booking-details-container">
            {" "}
            {/* <p className="know-you-meeting-booking-details-sub-text">{day},</p> */}
            <p className="know-you-meeting-booking-details-sub-text">{date},</p>
            <p className="know-you-meeting-booking-details-sub-text">{time},</p>
            {/* <p className="know-you-meeting-booking-details-sub-text">
              {timeZone}
            </p> */}
          </div>
        </div>
        <div className="know-you-meeting-booking-details-edit-button">
          <EditNoteRounded />
          <div className="know-you-meeting-edit-details" onClick={handleEdit}>
            Edit
          </div>{" "}
          {/* Edit button */}
        </div>
      </div>

      <div className="know-you-meeting-details-right">
        <h2 className="let-us-know-you-heading">Let us know you!</h2>
        <div className="know-you-meeting-details-right-input-container">
          <div className="">
            <h3 className="let-us-know-you-inputs">Full Name</h3>
            <input type="text" placeholder="Full Name" />
          </div>
          <div>
            <h3 className="let-us-know-you-inputs">Email</h3>
            <input type="email" placeholder="Email" />
          </div>
          <div>
            <h3 className="let-us-know-you-inputs">Phone Number</h3>{" "}
            <input type="tel" placeholder="Phone Number" />
          </div>
          <div>
            <h3 className="let-us-know-you-inputs">What is the call about</h3>{" "}
            <input type="text" placeholder="What is the call about" />
          </div>
        </div>

        <div
          className={`like-section ${
            showDetails ? "like-section-expanded" : "like-section-collapsed"
          }`}
        >
          <div className="like-section-heading-container">
            <h2>You may also like</h2>
            <button className="hide-like-section" onClick={handleToggleDetails}>
              {showDetails ? "^ Hide" : "Show"}
            </button>
          </div>

          {showDetails && (
            <div className="details-container">
              <div className="details-sub-container">
                <p className="details-sub-text-container">
                  1:1 Data Career Power Hour
                  <span className="details-sub-text">
                    Video Call | 15mins | $125
                  </span>{" "}
                </p>
                <button className="add-button" onClick={handleAddBooking}>
                  + Add
                </button>
              </div>

              <div className="details-sub-container">
                <p className="details-sub-text-container">
                  1:1 Data Career Power Hour
                  <span className="details-sub-text">
                    Video Call | 15mins | $125
                  </span>
                </p>
                <button className="add-button" onClick={handleAddBooking}>
                  + Add
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="confirm-pay-section">
          <Link to="/byteconnect/expertsPage/expert-meeting-details/:id/:meetingIndex/bookingconfirmed">
            <button
              className="confirm-pay-section-button"
              onClick={handleConfirmAndPay}
            >
              Confirm and Pay
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default KnowYou;
