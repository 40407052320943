import React, { useState } from "react";
import "./MentorshipCard.css";
import { mentorshipData } from "../../../APIservice/MentorshipCard"; // Import mentorshipData array
import { ClickableButton } from "../../../GenericComponents/Buttons/Buttons";
import BookingDetails from "./BookingDetails/BookingDetails";
import MeetingDetailsRight from '../ExpertMeetingDetails/MeetingDetailsRight/MeetingDetailsRight'


const MentorshipCard = ({
  key,
  image,
  image2,
  mentorshipTime,
  mentorshipMode,
  mentorshipSession,
  mentorshipSessionPerson,
  mentorshipStartTime,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false); 
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openReschedule = () => {
    setIsRescheduleOpen(true);
  };

  const closeReschedule = () => {
    setIsRescheduleOpen(false);
  };
  return (
    <div className="mentorship-card-container">
      <div className="mentorship-card-time-container">
        <div className="mentorship-card-time-image-info">
          <div className="mentorship-card-clock-image">
            <img src={image} alt="clock" />
          </div>

          <h1 className="mentorship-card-time-details small-mentor-text">
            {mentorshipTime} |{" "}
          </h1>
          <h1 className="mentorship-card-mode small-mentor-text">
            {" "}
            {mentorshipMode}
          </h1>
        </div>
        <div className="mentorship-card-start-time">{mentorshipStartTime}</div>
      </div>
      <div className="mentorship-card-mentor-container">
        <img src={image2} alt="mentor" />
        <div className="mentorship-card-mentor-text-container">
          <h1 className="meeting-text">
            {mentorshipSession}
            <br />{" "}
            <span className="mentor-name">with {mentorshipSessionPerson}</span>
          </h1>
        </div>
      </div>
      <div className="mentorship-card-buttons">
        <div>
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              height: "45px",
              width: "200px",
              border: "1px solid rgba(0, 0, 0, 1)",
            }}
            className="mentorship-card-button"
            onClick={openModal}
          >
            View Details
          </div>
          {isModalOpen && <BookingDetails closeModal={closeModal} />}
          {isRescheduleOpen && <MeetingDetailsRight closeReschedule={closeReschedule} />}
        </div>
        <ClickableButton
          name="Join Now"
          backgroundColor="rgba(7, 184, 24, 1)"
          color="white"
          height="45px"
          width="200px"
        />
      </div>
    </div>
  );
};

export default MentorshipCard;
