import React, { useState } from "react";
import "./ItemDetail.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { items } from "../../../APIservice/ShopItem";
import { AddShoppingCart, Star } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../Redux/slices/cartSlice";
import CartPopup from "../CartPopup/CartPopup";
import LocationSelector from "../LocationSelector/LocationSelector";

function ItemDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const item = items.find((item) => item.id === parseInt(id));

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [locationShowPopup, setLocationShowPopup] = useState(false);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

  if (!item) {
    return <div>No item found!</div>;
  }

  function generateRating(rating) {
    let stars = [];
    for (let index = 0; index < 5; index++) {
      if (index < rating) {
        stars.push(<Star key={index} sx={{ color: "#F4B400" }} />);
      } else {
        stars.push(<Star key={index} sx={{ color: "#c1c1c1" }} />);
      }
    }
    return stars;
  }

  const handleAddToCart = () => {
    dispatch(addToCart(item));
    navigate("/cart");
  };

  const buyNow = () => {
    dispatch(addToCart(item));
    navigate("/cart");
  };

  const handleShowPopup = () => {
    setShowPopup(true);
   
  };

  const handleLocationPopUp = () =>{
    setLocationShowPopup(true)
  }
  const handleHidePopup = () => {
    setShowPopup(false);
    setLocationShowPopup(false)
  };
  return (
    <div className="item-detail-section">
      <div className="cart-div">
        <div className="cart" onClick={handleShowPopup}>
          <AddShoppingCart sx={{ color: "#FF9533" }} />
          Cart
        </div>
        {showPopup && <CartPopup handleHidePopup={handleHidePopup} />}
      </div>

      <div className="scroll">
        <div className="product-container">
          <div className="left">
            <div className="variant-image">
              {item.cover_images.map((coverImage, index) => (
                <div key={index} onClick={() => setSelectedVariantIndex(index)}>
                  <img src={coverImage} alt={index} />
                </div>
              ))}
            </div>
            <div className="hero-image">
              <img
                src={item.cover_images[selectedVariantIndex]}
                alt={item.name}
              />
            </div>
          </div>
          <div className="right">
            <div className="product-name">
              <h3>{item.name}</h3>
            </div>
            <div className="rating-review">
              <span>{generateRating(item.rating)}</span>
              <span>10 Reviews</span>
            </div>
            <div className="description">
              <p>{item.description}</p>
            </div>
            <div className="price-quantity">
              <div className="price">
                <span>₹ {item.discount}</span>
                <span>₹ {item.price}</span>
              </div>
            </div>
            <div className="item-detail-location">
              <img src="/images/carbon_location.svg" alt="loc" />
              <p>Delivering to Bengluru 50001</p>
              <div
                className="update-location"
                onClick={() => setLocationShowPopup(true)}
              >
                Update location
              </div>
              {locationShowPopup && <LocationSelector handleHidePopup={handleHidePopup}  />}
            </div>
            <div className="buttons">
              <div className="addtoCart" onClick={handleAddToCart}>
                <span>Add to Cart</span>
              </div>
              <div className="buynow" onClick={buyNow}>
                <span>Buy Now</span>
              </div>
            </div>
          </div>
        </div>
        <section
          className="banner"
          style={{ backgroundImage: `url(${item.banner_image})` }}
        ></section>
        <section className="about-author">
          <h3>About Book and Author</h3>
          <p>{item.about}</p>
        </section>
        <section className="product-detail">
          <h3>Product Details</h3>
          <div>
            <p>Title: {item.name}</p>
            <p>Author: {item.author}</p>
            <p>Publisher: {item.publisher}</p>
            <p>Publication Date: {item.publication_date}</p>
            <p>Language: {item.language}</p>
            <p>Format: {item.format}</p>
            <p>Pages: {item.pages}</p>
            <p>ISBN-10: {item.isbn_10}</p>
            <p>ISBN-13: {item.isbn_13}</p>
            <p>Item Weight: {item.item_weight}</p>
            <p>Dimensions: {item.dimensions}</p>
            <p>Best Sellers Rank</p>
            <ul>
              {item.best_sellers_rank.map((rank, index) => (
                <li key={index}>{rank}</li>
              ))}
            </ul>
          </div>
        </section>
        <section className="others">
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            You May Also Like
          </h2>
          <div className="item-container">
            {items
              .filter((item) => item.id !== parseInt(id))
              .map((item) => (
                <Link to={`/shop/${item.id}`} key={item.id}>
                  <div className="item-card" key={item.id}>
                    <div className="cover-image">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="name-price">
                      <p>₹ {item.discount}</p>
                      <p>{item.name}</p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default ItemDetail;
