import React, { useState } from "react";
import Modal from "react-modal";
import "./ConfirmPopup.css";

const ConfirmPopup = ({ onConfirm,  isOpen, onCancel, title, description, duration }) => {
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    batch: "",
  });
  const getBatchColor = (batch) => {
    switch (batch) {
      case "Filling Fast":
        return "rgba(147, 16, 50, 1)";
      case "Almost Full":
        return "rgba(120, 68, 9, 1)";
      case "Available":
        return "rgba(255, 255, 255, 1)";
      default:
        return "inherit";
    }
  };
  const getBatchBackgroundColor = (batch) => {
    switch (batch) {
      case "Filling Fast":
        return "rgba(255, 195, 174, 1)";
      case "Almost Full":
        return "rgba(255, 149, 51, 1)";
      case "Available":
        return "rgba(30, 221, 133, 1)";
      default:
        return "inherit";
    }
  };
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()} ${currentDate.toLocaleString(
    "default",
    { month: "long" }
  )}, ${currentDate.getFullYear()}`;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectOption = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      batch: option,
    }));
    setIsDropdownOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to backend or display a success message
    console.log(formData);
  };

  return (
    <Modal
    className="confirm-popup-modal-container"
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirmation Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {

          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        },
      }}
    >
      <div className="confirm-popup-student-details">
        <h1>{title}</h1>
        <h2>{description}</h2>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <p>
            <img src="/images/noto_star.svg" alt="star" /> 4.5
          </p>
          <p> <img src="/images/ph_student-fill.svg" />4265 students</p>
          <p> <img src="/images/solar_calendar-outline.svg" /> {duration}</p>
        </div>
      </div>

      <div className="confirm-popup-contact-form">
        <form
          className="confirm-popup-contact-form-body"
          onSubmit={handleSubmit}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            <div>
              <h1>First Name</h1>
              <input
                className="confirm-popup-input-container"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                required
              />
            </div>
            <div>
              <h1>Last Name</h1>
              <input
                className="confirm-popup-input-container"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                required
              />
            </div>
          </div>

          <div className="">
            <h1>Mobile Number</h1>
            <div style={{ display: "flex", gap: "1rem" }}>
              <input
                className="confirm-popup-input-container"
                type="text"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                placeholder="+91"
                maxLength="4"
                style={{ width: "3rem" }}
                required
              />
              <input
                className="confirm-popup-input-container confirm-popup-input-container-mobile-number"
                type="tel"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                placeholder="Mobile Number"
                required
              />
            </div>
          </div>

          <div className="confirm-popup-batch-dropdown">
            <div
              className="confirm-popup-selected-option"
              onClick={handleDropdownToggle}
            >
              {formData.batch ? (
                <span className="formatted-date">{formattedDate}</span>
              ) : (
                formattedDate
              )}{" "}
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <span
                  style={{
                    color: getBatchColor(formData.batch),
                    backgroundColor: getBatchBackgroundColor(formData.batch),
                  }}
                >
                  {formData.batch}{" "}
                </span>
                {isDropdownOpen ? "▲" : "▼"}
              </div>
            </div>
            {isDropdownOpen && (
              <div className="confirm-popup-batch-options">
                <div
                  className="confirm-popup-batch-option"
                  onClick={() => handleSelectOption("Filling Fast")}
                >
                  {formattedDate} -{" "}
                  <span
                    style={{
                      color: getBatchColor(formData.batch),
                      backgroundColor: "rgba(255, 195, 174, 1)",
                    }}
                  >
                    Filling Fast
                  </span>
                </div>
                <div
                  className="confirm-popup-batch-option"
                  onClick={() => handleSelectOption("Almost Full")}
                >
                  {formattedDate} -{" "}
                  <span
                    style={{
                      color: getBatchColor(formData.batch),
                      backgroundColor: "rgba(255, 149, 51, 1)",
                    }}
                  >
                    Almost Full
                  </span>
                </div>
                <div
                  className="confirm-popup-batch-option"
                  onClick={() => handleSelectOption("Available")}
                >
                  {formattedDate} -{" "}
                  <span
                    style={{
                      color: getBatchColor(formData.batch),
                      backgroundColor: "rgba(30, 221, 133, 1)",
                    }}
                  >
                    Available
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="confirm-popup-fee-details" style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <h1>
              <img src="/images/rupee.svg" />
              Rs. 1347
            </h1>
            <h2 style={{ textDecoration: "line-through" }}>
              {" "}
              <img src="/images/Icon.svg" />
              1357
            </h2>
            <p>Valid Till {formattedDate + 1}</p>
          </div>
          <button
          className="confirm-poup-enroll-now-button"
            type="submit"
            style={{
              backgroundColor: "rgba(7, 184, 24, 1)",
              color: "white",
              outline: "none",
              border: "none",
              padding: ".5rem 1rem",
              borderRadius: "10px",
             
              cursor: "pointer",
            }}
            onClick={onConfirm}
          >
            Enroll Now
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;
