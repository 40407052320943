import * as Congs from "./Configurations";
import { ToastContainer, toast } from "react-toastify";

export function registerNewUser(userData) {
  console.log("fetching shortcuts");
  return Congs.FetchMaster(Congs.BASE_URL + "/api/v1/users/register", "POST", JSON.stringify(userData))

}


export function loginUser(userData) {
  console.log("logging user");

  return Congs.FetchMaster(Congs.BASE_URL + "/api/v1/users/auth/login", "POST", JSON.stringify(userData))

}

export function logoutUser() {
  console.log("logoutUser user");
  const userData = { uniqueUserId: Congs.uniqueUserId, token: Congs.getToken() }
  return fetch(Congs.LOGOUT_USER, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: Congs.getHeaders(),
  }).catch((e) => {
    // toast.error("Please enter right credentials.");
    console.log("error");
  });
}

export function getUserInfo(userId) {
  console.log("fetching userInfo");
  return Congs.FetchMaster(Congs.BASE_URL + `/api/v1/users/${userId}`, "GET")

}

export function updateUserInfo(userId, userInfo) {
  console.log("updating userInfo");

  return Congs.FetchMaster(Congs.BASE_URL + `/api/v1/users/${userId}`, "PUT", JSON.stringify(userInfo)).then((response) => response.json())

}

