
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getTopics } from "../../../../APIservice/QP/QuestionPaper";
import { getExamYears, getExamsById, getExamSubscriptionStatus } from "../../../../APIservice/QP/Exams";
import { getSubjects } from "../../../../APIservice/QP/QuestionPaper";


export function TopicwiseDetails() {

  const { examId } = useParams();

  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);

  const [exam, setExam] = useState({})

  const [subscriptionStatus, setSubscriptionStatus] = useState(true)

  const [paperYear, setPaperYear] = useState('');

  const [selectedSubject, setSelectedSubject] = useState({ id: 0 });

  const [topics, setTopics] = useState([]);

  const handleYearChange = (year) => {
    setPaperYear(year);

  };


  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  };

  useEffect(() => {
    getTopics(examId, selectedSubject?.id, paperYear).then((examtopics) => setTopics(examtopics.topics))

  }, [selectedSubject, paperYear])





  const shapeColor = ["#B0E4DD", "#C5B3D5", "#FFC28A", "#DAF3FA"];


  useEffect(() => {
    getExamYears(parseInt(examId)).then((years) => setYears(years.years))
    getExamsById(examId).then((exam) => setExam(exam))
    getExamSubscriptionStatus(examId).then((sub) => setSubscriptionStatus(sub.subscription_status))
    getTopics(selectedSubject.id).then((examtopics) => setTopics(examtopics.results))
    getSubjects(examId).then(resp => setSubjects(resp.results))

  }, [])




  useEffect(() => {
    getTopics(selectedSubject.id).then((examtopics) => setTopics(examtopics.results))

  }, [selectedSubject])


  useEffect(() => {
    if (subjects?.length > 0) {
      setSelectedSubject(subjects[0]);
    }
  }, [subjects])


  return (

    <div>
      <div className="subject-filter">
        <span>Subjects
          :</span>
        <div className="programs
                  ">
          {subjects?.map((subject) => (
            <span
              key={subject.title}
              className={`subject-tag ${selectedSubject === subject ? "active" : ""
                }`}
              onClick={() => handleSubjectClick(subject)}
            >
              {subject.title}
            </span>
          ))}
        </div>
      </div>



      <div className="topicContainer">
        <ul>
          {topics?.map((topic, index) => (
            <Link
              to={subscriptionStatus ? `/questionpapers/${examId}/questions?topicId=${topic?.id}` : `/questionpapers/${examId}/subscriptions`}
            >
              <li key={index}>
                <div className="band"></div>
                <div className="topic-name">
                  <p>{topic.topic_name}</p>
                </div>
                {!subscriptionStatus && <img src="/images/locked.svg" alt="" />}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
}