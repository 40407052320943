import React, { useEffect, useState } from "react";
import "./JobsPage.css";
import JobCard from "../JobCard/JobCard";
import * as jobsApiService from "../../../APIservice/JobsApiService";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../ByteConnect/SearchBar/SearchBar";
import { Link } from "react-router-dom";
import AppliedJobs from "../AppliedJobs/AppliedJobs";
import AppliedJobsCard from "../AppliedJobs/AppliedJobsCard/AppliedJobsCard";
import { appliedJobs } from "../../../APIservice/AppliedJobs";
import StatusDetailsCard from "../AppliedJobs/StatusDetailCard/StatusDetailCard"; // Import the StatusDetailsCard component

export default function Jobs() {
  const [isOpen, setIsOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobFilters, setJobFilters] = useState([{ values: [] }]);
  const [searchJob, setSearchJob] = useState("");
  const [status, setStatus] = useState("");
  const [showStatusDetails, setShowStatusDetails] = useState(false); // State to control whether to show the StatusDetailsCard or not

  // const [jobeType, setJobeType] = useState([]);
  // const [experienceLevel, setExperienceLevel] = useState([]);

  const handleSaveJobs = () => {
    setStatus(status === "" ? "Saved" : "");
  };

  const setSearchJobHandler = (e) => {
    setSearchJob(e.target.value);
    console.log(searchJob);
  };

  const applyFilter = (event) => {
    console.log(event.target.value);
  };

  useEffect(() => {
    jobsApiService.getAllJobOpennings(status).then((jobss) => {
      setJobs(jobss);
      console.log(jobss);
      console.log(jobs);
    });
    jobsApiService.getAllFilters().then((filters) => setJobFilters(filters));
  }, [status]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".job-page-filter")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const openStatusDetails = () => {
    setShowStatusDetails(true);
  };

  return (
    <div className="jobs-container">
      <div className="jobs-container-best-jobs-await-container">
        <div className="jobs-container-best-jobs-await-text-container">
          <h1 className="jobs-container-best-jobs-await-text-heading">
            Your Best Job Awaits!
          </h1>
          <p className="jobs-container-best-jobs-await-text-paragraph">
            Unlock curated job recommendations matched to your unique skills and
            passions, seamlessly blending your resume insights with personalized
            opportunities.
          </p>
          {/* <div className="jobs-container-best-jobs-await-search-bar">
            <div className="job-search">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                type="text"
                value={searchJob}
                onChange={setSearchJobHandler}
                placeholder="Search Jobs"
              />
            </div>
          </div> */}
        </div>

        <div className="jobs-container-best-jobs-await-card-container">
          {jobs.slice(0, 3).map((job) => (
            <JobCard job={job} />
          ))}
        </div>
        <div className="jobs-container-best-jobs-await-next-section-arrow">
          <Link to="/alljobs">
            <img src={'/images/arrow.png'} alt="arrow" />
          </Link>
        </div>
      </div>
      <div className="applied-jobs-container">
        <h1 className="applied-jobs-heading">Applied Jobs</h1>
        {/* <div className=" job-page-filter applied-jobs-filter">
            <img src={filter} alt="filter" />
            <div onClick={() => setIsOpen(!isOpen)}>Filter</div>
            {isOpen && <Filter />}
          </div> */}
        <div className="applied-jobs-card-container">
          <AppliedJobs />
          <div className="jobs-container-applied-jobs-next-section-arrow">
            <Link to="/all-applied-jobs">
              <img src={'/images/arrow.png'} alt="arrow" />
            </Link>
            <span>View All</span>
          </div>
        </div>
      </div>
      {showStatusDetails && (
        <StatusDetailsCard onClose={() => setShowStatusDetails(false)} />
      )}
    </div>
  );
}
