import React, { useState } from "react";
import "./Quiz.scss";
import { quizQuestions } from "../../../APIservice/Assessments";
import Score from "../Score/Score";
import QuestionNavigator from "../QuestionNavigator/QuestionNavigator";

function Quiz() {
  const [index, setIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [attemptedQuestions, setAttemptedQuestions] = useState(
    Array(quizQuestions.length).fill(false)
  );
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);

  const handleOption = (option) => {
    setSelectedOption(option);
  };

  const handleNext = () => {
    if (selectedOption === quizQuestions[index].answer) {
      setScore(score + 1);
    }
    setAttemptedQuestions((prev) => {
      const newAttempted = [...prev];
      newAttempted[index] = true;
      return newAttempted;
    });
    if (index < quizQuestions.length - 1) {
      setIndex(index + 1);
      setSelectedOption(null);
    } else {
      setShowSubmitConfirmation(true);
    }
  };

  const handleSubmit = () => {
    setShowSubmitConfirmation(false);
    setIndex(quizQuestions.length);
  };

  const handleCancel = () => {
    setShowSubmitConfirmation(false);
  };

  if (index === quizQuestions.length) {
    return <Score score={score} quizQuestions={quizQuestions} />;
  } else {
    return (
      <div className="quiz-container">
        <div className="bac-assessment-header">
          <div className="image-title">
            <img src="/images/bytegpt.png" alt="" />
            <h3>Byte Assessment Center</h3>
          </div>
        </div>
        <QuestionNavigator
          quizQuestions={quizQuestions}
          currentIndex={index}
          setIndex={setIndex}
          attemptedQuestions={attemptedQuestions}
        />
        <div className="question-length-container">
          <div className="quiz-questions-container">
            <h3>{quizQuestions[index].question}</h3>
            <ul>
              <li
                onClick={() => handleOption(quizQuestions[index].option1)}
                className={
                  selectedOption === quizQuestions[index].option1
                    ? "selected"
                    : ""
                }
              >
                <div className="option">
                  <span>A</span>
                  {quizQuestions[index].option1}
                </div>
              </li>
              <li
                onClick={() => handleOption(quizQuestions[index].option2)}
                className={
                  selectedOption === quizQuestions[index].option2
                    ? "selected"
                    : ""
                }
              >
                <div className="option">
                  <span>B</span>
                  {quizQuestions[index].option2}
                </div>
              </li>
              <li
                onClick={() => handleOption(quizQuestions[index].option3)}
                className={
                  selectedOption === quizQuestions[index].option3
                    ? "selected"
                    : ""
                }
              >
                <div className="option">
                  <span>C</span>
                  {quizQuestions[index].option3}
                </div>
              </li>
              <li
                onClick={() => handleOption(quizQuestions[index].option4)}
                className={
                  selectedOption === quizQuestions[index].option4
                    ? "selected"
                    : ""
                }
              >
                <div className="option">
                  <span>D</span>
                  {quizQuestions[index].option4}
                </div>
              </li>
            </ul>
          </div>
          <span className="quiz-length">
            {index + 1}/{quizQuestions.length}
          </span>
        </div>
        {selectedOption !== null && (
          <div className="next-button" onClick={handleNext}>
            <span>Next</span>
          </div>
        )}
        {showSubmitConfirmation && (
          <div className="submit-confirmation">
            <p>Are you sure you want to submit the quiz?</p>
            <div style={{display:"flex",gap:"1rem"}}>
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Quiz;
