export const bookLibrary = [
  {
    id: 1,
    category: "React",
    books: [
      {
        id: 1,
        book_title: "Out of the Box",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 2,
        book_title: "React",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/current-book.png",
      },
      {
        id: 3,
        book_title: "React and JavaScript",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 4,
        book_title: "Get hooked with React",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
    ],
  },
  {
    id: 2,
    category: "Python",
    books: [
      {
        id: 1,
        book_title: "Intution",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 2,
        book_title: "Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 3,
        book_title: "Python and Flask",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 4,
        book_title: "Native Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
    ],
  },
  {
    id: 3,
    category: "AI/ML",
    books: [
      {
        id: 1,
        book_title: "Generative AI",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 2,
        book_title: "Train you model",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 3,
        book_title: "Llama",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 4,
        book_title: "ML with DevOps",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
    ],
  },
  {
    id: 4,
    category: "History",
    books: [
      {
        id: 1,
        book_title: "Intution",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 2,
        book_title: "Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 3,
        book_title: "Python and Flask",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 4,
        book_title: "Native Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
    ],
  },
  {
    id: 5,
    category: "Recommended",
    books: [
      {
        id: 1,
        book_title: "Intution",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 2,
        book_title: "Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 3,
        book_title: "Python and Flask",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
      {
        id: 4,
        book_title: "Native Python",
        book_description:
          "Design and maintain data systems using programming languages like Python, Java, or SQL",
        book_image: "/images/out-of-box.png",
      },
    ],
  },
];
