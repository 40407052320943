import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./ExamCard.scss"
import { getExamSubscriptionStatus } from "../../../APIservice/QP/Exams"

export function ExamCard({ exam }) {
    const [subscriptionStatus, setSubscriptionStatus] = useState(false)

    useEffect(() => {
        getExamSubscriptionStatus(exam.id).then((subs) => setSubscriptionStatus(subs.subscription_status))
    }, [])

    return (
        <Link
            to={`/questionpapers/${exam.id}`}
            key={exam.id}
            className="exam-card"
        >
            <div className="image-name">
                <div className="exam-image">
                    <img src={exam.image_url} alt={exam.title} />
                </div>
                <span>{exam.title}</span>
            </div>

            {
                subscriptionStatus ?
                    <Link to={`/questionpapers/${exam.id}`} className="continue-button">
                        <div>Continue</div>
                    </Link> :
                    <Link to={`/questionpapers/${exam.id}/subscriptions`} className="subscribe-button">
                        <div>Subscribe Now</div>
                    </Link>
            }



        </Link>
    )

}