import React, { useEffect, useState } from "react";
import "./JobDetailsPage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as jobsApiService from "../../../APIservice/JobsApiService";
import { useScroll } from "react-spring";
import ConfirmPopup from "../../../GenericComponents/ConfirmPopUp/ConfirmPopup";
import * as JobApiService from "../../../APIservice/JobsApiService";
import JobCard from "../JobCard/JobCard";
import { CheckBox } from "@mui/icons-material";

export const salaryDetails = [
  {
    industryImage: "/images/softwarecompany.svg",
    industryType: "Software",
    industry: "Industry",
    employImage: "/images/noun-employment-6757363 1.svg",
    employType: "Full Time",
    employment: "Employment Type",
    contactImage: "/images/noun-email-6760572%201.svg",
    contactMail: "designteam@amazon.in",
    contact: "Contact(Email)",
  },
];

const JobDetails = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [jobFilters, setJobFilters] = useState([{ values: [] }]);
  const [status, setStatus] = useState("");
  const [jobDetails, setjobDetails] = useState({
    jobType: [],
    requirements: [],
    responsibilities: [],
    aboutCompany: [],
    experience: [],
    educationLevel: [],
    location: [],
  });
  const params = useParams();

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState(
    "Are you sure you want to apply for this job?"
  );

  const [isUnlocked, setIsUnlocked] = useState(false); // State to track whether the job is unlocked

  const handleBack = () => {
    navigate(-1);
  };
  console.log(params.id);

  const handleUnlock = () => {
    setIsUnlocked(true); // Set the state to indicate that the job is unlocked
  };

  // Handle confirmation of job application
  const handleConfirmApply = () => {
    setIsConfirmPopupOpen(false);
    if (jobDetails.isLocked) {
      navigate("/jobs/" + params.code + "/assesment");
    } else {
      setIsConfirmPopupOpen(true);
      setPopupMessage("Thank you, Successfully applied for the job");

      navigate("/jobs");
    }
  };

  // Handle cancellation of job application
  const handleCancelApply = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleSaveJob = (jobID) => {
    console.log("save the job " + jobID);
    setjobDetails({
      ...jobDetails,
      status: jobDetails.status === "" ? "Saved" : "",
    });

    let jobDto = {
      code: jobDetails.code,
      companyCode: jobDetails.companyCode,
      uniqueUserId: localStorage.getItem("uniqueUserId"),
      status: jobDetails.status === "Saved" ? "Unsave" : "Save",
    };
    console.log(jobDto.status);
    JobApiService.saveOrApplyJob(jobDto);
  };

  useEffect(() => {
    // Filter jobs and set them when the component mounts
    jobsApiService
      .getJobById(params.code)
      .then((jobDetail) => setjobDetails(jobDetail));
  }, [params.id]);

  useEffect(() => {
    jobsApiService.getAllJobOpennings(status).then((jobss) => {
      setJobs(jobss);
      console.log(jobss);
      console.log(jobs);
    });
    jobsApiService.getAllFilters().then((filters) => setJobFilters(filters));
  }, [status]);

  return (
    <div className="job-details-container">
      {/* back to job */}
      <div className="go-back" onClick={handleBack}>
        <i class="fa-solid fa-angle-left"></i>
        <h4>Back To Jobs</h4>
      </div>
      <div>
        {/* header */}
        <div className="job-detail">
          <div className="job-img">
            <img src={jobDetails.companyLogoUrl} alt="" />
          </div>
          <div className="job-details-byte-code-image-container">
            <img
              className="job-details-byte-code-image"
              src="/images/Logo.svg"
              alt=""
            />
          </div>
        </div>

        <div className="job-title-and-descripiton">
          <div className="job-title-and-btn">
            <div className="title">
              <p className="job-details-page-company-name">
                {jobDetails.companyName}
              </p>
              <h2 className="job-details-page-role">{jobDetails.title}</h2>
              {isUnlocked ? null : (
                <div className="job-location">
                  <div className="job-details-posted-on-date">
                    {jobDetails.location.length > 0 && (
                      <div>{jobDetails.location[0]}</div>
                    )}
                  </div>
                  <div className="job-location-between-dot"></div>
                  <div className="job-details-posted-on-date">22 Days Ago</div>
                </div>
              )}
            </div>

            <div className="job-action-btn">
              {isUnlocked ? null : (
                <>
                  <div className="jobs-unlock-button" onClick={handleUnlock}>
                    <img src="/images/uil_lock.svg" alt="lock" />
                    <p> Unlock</p>
                  </div>
                  <div className="job-details-posted-on-date">
                    **complete this assessment to apply
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* <hr /> */}

        {/* Here our Mark down comes */}
        {isUnlocked ? (
          <div className="content-below-unlock-button">
            <div className="job-location">
              <div className="job-details-posted-on-date">
                {jobDetails.location.length > 0 && (
                  <div>{jobDetails.location[0]}</div>
                )}
              </div>
              <div className="job-location-between-dot"></div>
              <div className="job-details-posted-on-date">22 Days Ago</div>
            </div>
          </div>
        ) : null}
        <div className="">
          <div className="job-details-page-assessment-page">
            {isUnlocked ? (
              <>
                <br />
                <div className="job-details-page-assessment-page-details">
                  Before proceeding with your application for this position,
                  please complete the eligibility assessment to determine if you
                  meet the necessary criteria for this role.
                </div>
                <br />

                <h2 className="job-details-page-assessment-page-instruction-heading">
                  Important Instructions:
                </h2>
                <br />
                <div className="job-details-page-assessment-page-instruction-details">
                  <p className="job-details-page-assessment-page-instruction-points job-details-page-assessment-page-details">
                    {" "}
                    1. Read the Instructions Thoroughly: Begin by carefully
                    reading all provided instructions and guidelines. Understand
                    what is expected of you during the assessment.
                  </p>

                  <div className="job-details-page-assessment-page-instruction-points job-details-page-assessment-page-details">
                    2. Check Technical Requirements: Ensure your computer,
                    internet connection, and any required software or browser
                    extensions are up to date and functioning properly.
                  </div>
                </div>
                <br />
                <label  className="job-details-page-assessment-page-details" >
                  <input type="checkbox"/>
                  By clicking 'Take Assessment,' you agree to BYTECODE's Terms
                  and Policies.
                </label>
                <br />
                <br />
                <button className="job-detaails-page-take-assesment-button">Take Assessment</button>
              </>
            ) : (
              ""
            )}
          </div>

          {isUnlocked ? null : (
            <div className="jobs-complete-details">
              <div className="jobs-descript">
                <h2>About the Job</h2>
                <p>{jobDetails.jobDescription}</p>
              </div>
              <div className="responsibilities">
                <h2>Key Responsibilities</h2>
                <ul>
                  {jobDetails.responsibilities.map((responsibility) => (
                    <li>{responsibility}</li>
                  ))}
                </ul>
              </div>
              <div className="qualifications">
                <h2>Preffered Qualifications</h2>
                <ul>
                  {jobDetails.requirements.map((qualification) => (
                    <li> {qualification} </li>
                  ))}
                </ul>
              </div>
              <div className="job-salary-container">
                <div className="job-salary-amount-container">
                  <div className="job-salary-image-amount-box">
                    <img src="/images/mdi_currency-inr.svg" />
                    <h1>4,50,000</h1>
                  </div>
                  <div className="average-salary-text">Avg Salary</div>
                </div>
                <div className="job-salary-industry-employment-contact-container">
                  <div className="job-salary-industry-container salary-industry-employment-contact-boxes">
                    <img src={salaryDetails[0].industryImage} />
                    <div className="job-details-sub-container">
                      <h1>{salaryDetails[0].industryType}</h1>
                      <p>{salaryDetails[0].industry}</p>
                    </div>
                  </div>
                  <div className="job-salary-employment-container salary-industry-employment-contact-boxes">
                    <img  style={{background:"rgba(242, 236, 230, 1)", padding:"1rem", borderRadius:"50%"}}  src={salaryDetails[0].employImage} />
                    <div className="job-details-sub-container">
                      <h1>{salaryDetails[0].employType}</h1>
                      <p>{salaryDetails[0].employment}</p>
                    </div>
                  </div>
                  <div className="job-salary-contact-container salary-industry-employment-contact-boxes">
                    <img   style={{background:"rgba(242, 236, 230, 1)", padding:"1rem", borderRadius:"50%"}}  src={salaryDetails[0].contactImage} />
                    <div className="job-details-sub-container">
                      <h1>{salaryDetails[0].contactMail}</h1>
                      <p>{salaryDetails[0].contact}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-overview">
                <h2>About Company</h2>
                {jobDetails.aboutCompany.map((text) => (
                  <div className="job-details-about-company-container">
                    <div className="job-details-about-company-name-logo-employees">
                      <img src={jobDetails.companyLogoUrl} alt="" />
                      <div className="job-details-about-company-name-employees-container">
                        <h1> {jobDetails.companyName}</h1>
                        <p>110000 Employees</p>
                      </div>
                    </div>
                    <div className="job-details-about-company-details-para">
                      <p>{text}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="jobs-details-page-recommended-jobs">
                <h1>Recommended For You</h1>
                <div className="jobs-details-page-recommended-jobs-cards">
                  {" "}
                  {jobs.slice(0, 5).map((job) => (
                    <JobCard job={job} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
