import React, { useEffect, useState } from 'react'
import * as CoursesApi from "../../../../../APIservice/CoursesApi";
import { useParams } from 'react-router-dom';
import { getChaptersAssignment } from "../../../../../APIservice/Assignments";

export default function NewCourseLearnings() {

  const [course, setCourse] = useState({ skills: [], learnings: [] });
  const [assignments, setAssignments] = useState([]);
  const params = useParams();
  useEffect(() => {
    CoursesApi.getCourseById(params.courseCode).then((cs) => {
      setCourse(cs[0]);
    });
    getChaptersAssignment(params.courseCode).then((assignments) => {
      setAssignments(assignments);
    });
  }, []);


  const numChapters = assignments.chapters ? assignments.chapters.length : 0;
  return (
    <div className="program-duration-fees-skills-container">
      <div className="program-duration-fees">
        <div className="course-program-ai program-details">
          <img src="/images/AInew.svg " />
          <p>AI Generated Notes</p>
        </div>
        <div className="assignments-count program-details">
          <img src="/images/Checklist.svg" />
          <p>{numChapters}+ Assignments</p>
        </div>
        <div className="batch-start program-details">
          <img src="/images/CAL.svg" />
          <p>Batch Starts on April 15th</p>
        </div>
        <div className="duration program-details">
          <img src="/images/time.svg" />
          <p>{course.duration}</p>
        </div>
      </div>
      <div className="skills-gain">
        <h3>Skill's you gain</h3>
        <div
          className="skill-shortcuts"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: ".5rem",
            margin: " 1rem 0",
          }}
        >
          {course.skills.map((skill) => (
            <div className="skills-tags">{skill}</div>
          ))}
        </div>
      </div>
    </div>
  )
}

