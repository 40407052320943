import React, { useState } from 'react'
import './Bac.scss'
import { History, Send } from '@mui/icons-material'
import Assessments from './Assessments/Assessments';
import TermsandCondition from './T&C/TermsandCondition';
function Bac() {
  
  const[showTandC,setShowTandC]=useState(false);
  const handleTandCVisiblity=()=>{
    setShowTandC(!showTandC);
  }
return (
  <>
    {showTandC ? (
      <TermsandCondition />
    ) : (
      <div className="bac-container">
        <div className="bac-assessment-header">
          <div className="image-title">
            <img src="/images/bytegpt.png" alt="" />
            <h3>Byte Assessment Center</h3>
          </div>
          <History sx={{ marginRight: "1rem", cursor: "pointer" }} />
        </div>
        <div className="bac-content-container">
          <div className="welcome-section">
            <h3>Welcome to Byte-Assessment</h3>
            <p>
              Welcome to our AI-Powered Self-Assessment Platform! Get ready to
              explore personalized learning at your fingertips. Let's dive in
              and elevate your academic journey together!
            </p>
            <div className="input-prompt">
              <input
                type="text"
                placeholder="Enter Your prompt to generate the assessment"
              />
              <svg width={0} height={0}>
                <linearGradient
                  id="exampleColors"
                  x1={0}
                  y1={0}
                  x2={0}
                  y2={1}
                  gradientTransform="rotate(45)"
                >
                  <stop offset="0%" stopColor="#00A991" />
                  <stop offset="10%" stopColor="#FF7B00" />
                </linearGradient>
              </svg>
              <Send sx={{ fill: "url(#exampleColors)" }} className="enter" />
            </div>
          </div>
          <hr />

          <Assessments handleTandCVisiblity={handleTandCVisiblity} />
        </div>
      </div>
    )}
  </>
);
  }


export default Bac