import React from "react";
import "./ResumeCourseCard.css";
import { Link } from "react-router-dom";
import { careerTrackCourse } from "../../../../../../APIservice/CareerTrackCourses";

// ResumeCourseCard.js

const ResumeCourseCard = ({ courseCode, currentPointIndex }) => {
  const firstTopic = careerTrackCourse.topics[0];
  const { topics } = careerTrackCourse;
  const currentModuleIndex = Math.floor(
    currentPointIndex / firstTopic.modules[0].pointsCovered.length
  );
  const currentPoint =
    firstTopic.modules[0].pointsCovered[
      currentPointIndex % firstTopic.modules[0].pointsCovered.length
    ];

  return (
    <div className="resume-course-card-container">
      <div className="chapter-card-text-container">
        <div className="progress-track-box">In Progress</div>
        {/* <h2>{topics[0].subtopicHeading}</h2> */}
        <h3>{firstTopic.modules[currentModuleIndex].subtopicHeading}</h3>
        <p>Currently studying: {currentPoint}</p>
      </div>
      <div className="chapter-card-resume-button">Resume Track</div>
    </div>
  );
};

export default ResumeCourseCard;
