import React, { useState, useEffect } from "react";
import "./SingleQuestion.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowForward, CommentBank, KeyboardArrowDown, KeyboardArrowUp, Lock, TipsAndUpdates } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UnlockedQuestion } from "./Unlocked/UnlockedQuestion";
import { getAllCommentsOfQuestion, addCommentsOfQuestion, getQuestionDetails } from "../../../APIservice/QP/QuestionPaper";
import { useSelector, useDispatch } from "react-redux";
import { addQuestions, updateCurrentQuestion } from "../../../Redux/slices/QPSlice";

function SingleQuestion() {
  const { examId, questionId = 1 } = useParams();

  const [question, setQuestion] = useState({
    question_text: "",
    hint: '',
    solution: "",
    difficulty_level: null,
    explanation_videos: [],
    related_tags: [],
    related_topics: []

  });

  const [disableButton, setDisableButton] = useState(false)


  let { questions, currentQuestion,
    currentQuestionIndex } = useSelector((state) => state.qp);



  const dispatch = useDispatch();

  const navigate = useNavigate();


  const handleNextQuestion = () => {

    // if (currentQuestionIndex < questions.length && currentQuestionIndex >= 0 && currentQuestionIndex != questions.length - 1) {
    //   currentQuestionIndex += 1
    //   dispatch(updateCurrentQuestion({ currentQuestion: questions[currentQuestionIndex], currentQuestionIndex: currentQuestionIndex }));
    //   navigate(`/questionpapers/${examId}/questions/${questions[currentQuestionIndex].id}`);

    // }
    console.log(questions)
    console.log(currentQuestionIndex)
    console.log(currentQuestion)
  };

  const handlePreviousQuestion = () => {

    // if (currentQuestionIndex > 0) {
    //   dispatch(updateCurrentQuestion({ currentQuestion: questions[currentQuestionIndex], currentQuestionIndex: --currentQuestionIndex }));
    //   navigate(`/questionpapers/${examId}/questions/${currentQuestion.id}`);
    // }
  }



  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [commentVisible, setCommentVisible] = useState(false);


  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleAddComment = () => {
    // Add new comment to the comments array
    if (commentText.length < 5) {
      alert("Add proper comment.")
      return
    }

    const newComment = {
      likes: 0,
      comment_text: commentText,
    };
    setDisableButton(true)
    addCommentsOfQuestion(question.id, newComment).then(() => {
      setCommentText("");
      getAllCommentsOfQuestion(questionId).then((comments) => setComments(comments.results))
    })



  };


  const navigateBack = () => {
    navigate(-1)
  }


  useEffect(() => {

    getAllCommentsOfQuestion(questionId).then((comments) => setComments(comments.results))
    getQuestionDetails(examId, questionId).then((question) => setQuestion(question))

  }, []);




  return (
    <div className="single-question-container">
      <div className="backtoprevious" onClick={navigateBack}>
        <ArrowBackIcon sx={{ fontSize: "1rem" }} /> Go back
      </div>
      {/* <div className="back-next">
        <button onClick={() => handlePreviousQuestion()}>
          <ArrowBackIcon sx={{ fontSize: "1rem" }} /> Previous
        </button>
        |{" "}
        <p>
          Question List{" "}
          <span>
            ({currentQuestionIndex + 1} of {questions?.length} )
          </span>
        </p>{" "}
        |
        <button onClick={() => handleNextQuestion()}>
          Next <ArrowForward sx={{ fontSize: "1rem" }} />
        </button>

      </div> */}

      <div className="question-hint-container">
        {question && <UnlockedQuestion currentQuestion={question}></UnlockedQuestion>}
        <div className="hint-explanation-section">

          <div className="hints-title" onClick={() => setCommentVisible(!commentVisible)}>
            <CommentBank /> Comments
            {commentVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
          {commentVisible &&
            <div className="commentSection">
              <div className="comment-container">
                <div className="commentArea">
                  <div className="picture">
                    <img src="/images/profile_image.png" alt="profile" />
                  </div>
                  <input
                    type="text"
                    value={commentText}
                    placeholder="Add your comment on this explanation"
                    onChange={handleCommentChange}
                  />
                  <button disabled={disableButton} onClick={handleAddComment}>Comment</button>
                </div>

                {/* Display comments */}
                <div className="comments">
                  {comments?.map((comment, index) => (
                    <div key={index} className="comment">
                      <div className="profile-name">
                        <div className="picture">
                          <img src={comment.user.profile_image_url == null ? "/images/profile_image.png" : comment.user.profile_image_url} alt="profile" />
                        </div>
                        <div className="name-comment">
                          <div className="name-timestamp">
                            <p className="name">{comment.user.name}</p>
                            <div className="timestamp">
                              {comment.created_at}
                            </div>
                          </div>
                          <div className="comment-text">{comment.comment_text}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SingleQuestion;
