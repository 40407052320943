import React from "react";
import CustomFormField from '../../../../Molecule/CustomFormField/CustomFormField';

let challenge = `Sure, here's a data structure and algorithm (DSA) practice problem:

**Problem: Maximum Subarray Sum**

**Problem Statement:**
You are given an array of integers, both positive and negative. Find the contiguous subarray with the largest sum.

**Example:**
python
Input: [-2, 1, -3, 4, -1, 2, 1, -5, 4]
Output: 6
Explanation: The contiguous subarray [4, -1, 2, 1] has the largest sum of 6.


**Constraints:**
- The array can have both positive and negative integers.
- Your algorithm should run in O(n) time complexity, where n is the length of the array.

**Notes:**
- You need to return the maximum sum, not the subarray itself.

Try to solve this problem and let me know if you'd like an explanation or if you have any questions about it!`

export default function DisplayChallege(props){
    
      let message = {
            id:"#12",
            type:"PracticeChallenge",
            input: challenge,
            output:""
      }
      return(
            <div style={{width:"75vw", margin:"2rem"}}>
                  <CustomFormField question={message} />                

            </div>
      )
}