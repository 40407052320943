import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import AllCareerTrackCradsPage from "./AllCareerTrackCradsPage/AllCareerTrackCradsPage";


export default function EnrolledCareerTracksHistory(){
    return (
        <div>
            <AllCareerTrackCradsPage/>
            EnrolledCareerTracksHistory
        </div>
    )
}