// Calendar.js

import React, { useState, useContext, useEffect } from "react";
import "./Calendar.css";
import { getMonth } from "./util/util";
import CalendarHeader from "./CalendarHeader/CalendarHeader";
import Sidebar from "./Sidebar/Sidebar";
import Month from "./Month/Month";
import GlobalContext from "../../context/GlobalContext";
import EventModal from "./EventModal/EventModal";
import CalendarEventDetails from "./CalenderEventDetails/CalenderEventDetails";

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const handleCloseDetails = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    setIsBlurred(selectedEvent !== null);
  }, [selectedEvent]);

  return (
    <div className={`custom-calendar ${isBlurred ? "blurred" : ""}`}>
      {showEventModal && <EventModal />}
      {selectedEvent && (
        <div className="calendar-event-details-container">
          <div className="calendar-event-details-background" onClick={handleCloseDetails}></div>
          <CalendarEventDetails event={selectedEvent} />
        </div>
      )}
      <div className="custom-calendar-content">
        <div className="custom-sidebar">
          <Sidebar onClick={handleCloseDetails} />
        </div>
        <div className="custom-calendar-content-header-month-container">
          {/* <div className="custom-calendar-header">
            <CalendarHeader />
          </div> */}
          <div className="custom-month">
            <Month month={currentMonth} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
