import React, { useState } from "react";
import "./BooksHistory.css";
import { PlayArrowOutlined } from "@mui/icons-material";
import { bookLibrary } from "../../../APIservice/LibraryBooks";
import { useNavigate } from "react-router-dom";

const BooksHistory = () => {
  const categories = ["History"];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const readBookClick = (bookId) => {
    navigate(`/library/books/${bookId}`);
  };

  return (
    <div className="allBooks-container" style={{display:"flex", padding:"2rem", flexDirection:"column"}}>
      <h1>Reading History</h1>
      {bookLibrary.map(
        (booksCategory) =>
          (selectedCategory === "history" ||
            booksCategory.category === selectedCategory) && (
            <div
              key={booksCategory.id}
              className="library-book-topic-container"
            >
              <div className="library-book-card-container">
                {booksCategory.books.map((librarybook) => (
                  <div key={librarybook.id} className="book-card-history">
                    <div className="book-bg">
                      <img src="/images/bytegpt-bg.png" alt="" />
                    </div>
                    <div className="book-card-image">
                      <img src={librarybook.book_image} alt="" />
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div className="book-card-info">
                        <p className="book-title">{librarybook.book_title}</p>
                        <p className="description">
                          {librarybook.book_description}
                        </p>
                      </div>
                      <div className="read-download-book">
                        <span
                          className="read-book"
                          onClick={() => readBookClick(librarybook.id)}
                        >
                          Conitnue Reading
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default BooksHistory;
