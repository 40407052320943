import React, { useEffect, useState } from "react";
import "./AvailableItems.css";
import {items,categories} from "../../../APIservice/ShopItem"
import { Link } from "react-router-dom";

function AvailableItems({ searchQuery }) {
  const initialCategory =
    categories && categories.length > 0 ? categories[0] : "All";
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [shoppingStack, setShoppingStack] = useState(items);

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) => {
      // If the clicked category is the currently selected category, toggle it off by setting it to "All"
      // Otherwise, set it to the clicked category
      return prevCategory === category ? "All" : category;
    });
  };

  useEffect(() => {
    let filteredItems = items;

    if (selectedCategory !== "All") {
      filteredItems = filteredItems.filter(
        (item) => item.category === selectedCategory
      );
    }

    if (searchQuery) {
      filteredItems = filteredItems.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setShoppingStack(filteredItems);
  }, [selectedCategory, searchQuery]);

  return (
    <div className="available-item-section">
      <div>
        <ul className="chips-container">
          {categories &&
            categories.map((category) => (
              <li
                key={category}
                onClick={() => handleCategoryClick(category)}
                className={selectedCategory === category ? "active" : ""}
              >
                {category}
              </li>
            ))}
        </ul>
      </div>

      <div className="item-container">
        {shoppingStack.map((item) => (
          <Link to={`/shop/${item.id}`} key={item.id}>
            <div className="item-card" key={item.id}>
              <div className="cover-image">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="name-price" >
              <p>{item.name}</p>
                <p>₹ {item.discount}</p>
                
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AvailableItems;