import React, { useState } from "react";
import "./Videos.css";
import { libraryVideos, categories } from "../../../APIservice/LibraryVideos";
import {
  ChatBubbleOutline,
  PlayArrowOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import VideosHistory from "../VideosHistory/VideosHistory";

function Videos() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const playVideoClick = (video_id) => {
    navigate(`/library/videos/${video_id}`);
  };

  const historyVideos = libraryVideos
    .find((videosCategory) => videosCategory.category === "History")
    .videos.slice(0, 5);

  return (
    <div className="library-video-container">
      <div className="filter-video-tags">
        <ul className="chips-container">
          {categories &&
            categories.map((category) =>
              category !== "History" ? (
                <li
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  className={selectedCategory === category ? "active" : ""}
                >
                  {category}
                </li>
              ) : null
            )}
        </ul>
      </div>
      <div className="history-video-container">
        <h1>Latest Viewed</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="video-card-container">
            {historyVideos.map((video_info) => (
              <div
                key={video_info.id}
                className="video-card"
                onClick={() => playVideoClick(video_info.id)}
              >
                <div className="playicon">
                  <PlayArrowOutlined sx={{ color: "#fff", fontSize: "4rem" }} />
                </div>
                <div className="video-card-image">
                  <img src={video_info.thumbnail_link} alt="" />
                </div>
                <div className="video-card-title">
                  <p>{video_info.title}</p>
                </div>
                <div className="like-comment">
                  <span>
                    <ThumbUpAltOutlined sx={{ fontSize: "0.8rem" }} />{" "}
                    {video_info.likes} Likes
                  </span>
                  <span>
                    <ChatBubbleOutline sx={{ fontSize: "0.8rem" }} />{" "}
                    {video_info.comment} Comments
                  </span>
                </div>
              </div>
            ))}
          </div>
          <Link to="/library/videos/history">
            <div className="books-view-all-button">
              <img src={"/images/arrow.png"} alt="arrow" />
              <p>View All</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="allVideos-container">
        {libraryVideos
          .filter(
            (videoCategory) =>
              selectedCategory === "All" ||
              videoCategory.category === selectedCategory
          )
          .map((videoCategory) => (
            <div
              key={videoCategory.id}
              className="library-video-topic-container"
            >
              <h2>{videoCategory.category}</h2>
              <div className="video-card-container">
                {videoCategory.videos.map((video_info) => (
                  <div
                    key={video_info.id}
                    className="video-card"
                    onClick={() => playVideoClick(video_info.id)}
                  >
                    <div className="playicon">
                      <PlayArrowOutlined
                        sx={{ color: "#fff", fontSize: "4rem" }}
                      />
                    </div>
                    <div className="video-card-image">
                      <img src={video_info.thumbnail_link} alt="" />
                    </div>
                    <div className="video-card-title">
                      <p>{video_info.title}</p>
                    </div>
                    <div className="like-comment">
                      <span>
                        <ThumbUpAltOutlined sx={{ fontSize: "0.8rem" }} />{" "}
                        {video_info.likes} Likes
                      </span>
                      <span>
                        <ChatBubbleOutline sx={{ fontSize: "0.8rem" }} />{" "}
                        {video_info.comment} Comments
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Videos;
