import React, { useState } from "react";
import "./CareerTrackCourses.css";
import { careerTrackCourse } from "../../../../APIservice/CareerTrackCourses";
import CareerTrackMentors from "../../CareerTrackMentors/CareerTrackMentors";
import SampleCertificate from "../../SampleCertificate/SampleCertificate";
import UserRating from "../../../Subjects/UserRating/UserRating";
import CommentSection from "../../../../GenericComponents/CommentSection/CommentSection";
import CourseTopicDetails from "../../../Subjects/CourseTopicDetails/CourseTopicDetails";
import ResumeCourseCard from './SubjectCoursePage/ResumeCourseCard/ResumeCourseCard';

const CareerTrackCourses = ({ courseCode }) => {
  // Destructure data from careerTrackCourse
  const { topics } = careerTrackCourse;
  const [currentPointIndex, setCurrentPointIndex] = useState(0); 
 
  return (
    <div className="career-track-courses-container">
      <div className="career-track-courses-container-header">
        <h1>Career Track</h1>
        <h2>{topics[0].topicTitle}</h2>
        <p>{topics[0].topicDescription}</p>
      </div>
      <div className="career-track-resume-course-card">
      <ResumeCourseCard courseCode={courseCode} currentPointIndex={currentPointIndex} />
        </div>

      <div className="career-track-courses-container-body-container">
      <CourseTopicDetails setCurrentPointIndex={setCurrentPointIndex} /> 
        <div className="career-track-course-mentor-card">
          <CareerTrackMentors />
        </div>
        <div className="career-track-courses-certificate-section">
          <SampleCertificate />
        </div>
        <div className="career-track-courses-rating-section">
          <h1 className="chapter-page-user-rating-section-heading">
            Echoes of Experience
          </h1>
          <UserRating />
        </div>
        <div className="career-track-courses-comment-section">
          <CommentSection />
        </div>
      </div>
    </div>
  );
};

export default CareerTrackCourses;
