import React, { useState } from "react";
import { libraryVideos } from "../../../APIservice/LibraryVideos";
import {
  ChatBubbleOutline,
  PlayArrowOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./VideosHistory.css";

const VideosHistory = () => {
  const categories = ["History"];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const navigate = useNavigate();

  const playVideoClick = (video_id) => {
    navigate(`/library/videos/${video_id}`);
  };

  return (
    <div className="history-video-container">
      <div className="allVideos-container">
        {libraryVideos
          .filter((videoCategory) => videoCategory.category === "History")
          .map((videoCategory) => (
            <div
              key={videoCategory.id}
              className="library-video-topic-container"
            >
              <h2>Watch {videoCategory.category}</h2>
              <div className="video-card-container-history">
                {videoCategory.videos.map((video_info) => (
                  <div
                    key={video_info.id}
                    className="video-card-history"
                    onClick={() => playVideoClick(video_info.id)}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap:"1rem" }}>
                      <div style={{width:"61%"}}>
                        <div className="playicon-history">
                          <PlayArrowOutlined
                            sx={{ color: "#fff", fontSize: "4rem" }}
                          />
                        </div>
                        <div className="video-card-image-history">
                          <img src={video_info.thumbnail_link} alt="" />
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection:"column",width:"100%" }}>
                        <div className="video-card-title">
                          <p>{video_info.title}</p>
                          <h6>{video_info.description.substring(0,100)}</h6>
                        </div>
                        <div className="like-comment-history">
                          <span>
                            <ThumbUpAltOutlined sx={{ fontSize: "0.8rem" }} />{" "}
                            {video_info.likes} Likes
                          </span>
                          <span>
                            <ChatBubbleOutline sx={{ fontSize: "0.8rem" }} />{" "}
                            {video_info.comment} Comments
                          </span>
                          <button>Remove from History</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VideosHistory;
