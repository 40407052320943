import React, { useState } from "react";
import "./MeetingDetailsLeft.css";
import { Link } from "react-router-dom";
import KnowYou from "../../KnowYou/KnowYou";

const MeetingDetailsLeft = ({
  expert,
  selectedMeeting,
  selectedDate,
  selectedTime,
  selectedTimeZone,
  showKnowYou,
}) => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const handleNextTestimonial = () => {
    console.log("Next testimonial index:", testimonialIndex + 1);
    setTestimonialIndex((prevIndex) =>
      prevIndex < selectedMeeting.testimonials.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrevTestimonial = () => {
    console.log("Previous testimonial index:", testimonialIndex - 1);
    setTestimonialIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedMeeting.testimonials.length - 1
    );
  };

  return (
    <div className="meeting-details-left-container">
      <div className="meeting-details-left-header-container">
        <div className="meetings-details-left-header-main">
          <div className="meetings-details-left-header-main-text-container">
            <Link to={`/byteconnect/expertsPage/${expert.id}`}>
              <div className="meeting-details-left-name">&#60; Back</div>
            </Link>
            <div className="meetings-details-left-header-main-mentorship-type">
              {selectedMeeting.buttonCardName}
            </div>
          </div>
          <div className="meetings-details-left-header-image" alt="expert" />
        </div>
      </div>
      {/* Conditionally render either the KnowYou component or the existing details section */}
      {showKnowYou ? (
        <KnowYou
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          selectedTimeZone={selectedTimeZone}
          expert={expert}
          selectedMeeting={selectedMeeting}
        />
      ) : (
        <div className="meeting-details-left-details-container">
          <div className="meetings-details-left-below-header-container">
            <div className="meeting-details-left-timing-text-image-container">
              <img
                src={expert.calenderImage}
                alt="time"
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
              <div className="meeting-details-left-timing-text-container">
                <p className="meeting-details-left-time-text">
                  {selectedMeeting.time}
                </p>
                <p className="meeting-details-left-type-text">
                  {selectedMeeting.meetingType}
                </p>
              </div>
            </div>
            <div className="meeting-details-left-rate-container">
              <img
                src={selectedMeeting.chargeImage}
                alt="charge"
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
              <p className="meeting-details-left-rate-text">
                {selectedMeeting.charge}
              </p>
            </div>
          </div>
          <div className="meeting-details-left-chat-points-section">
            <ul className="meeting-details-left-chat-points-list-section">
              {selectedMeeting.chatPoints.map((point, index) => (
                <li className="meetings-details-left-chat-line" key={index}>
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div className="meeting-details-left-testimonials-container">
            <div className="meeting-details-left-testimonials-heading-buttons-container">
              <h3 className="meeting-details-left-testimonials-heading-text">
                Testimonials
              </h3>
              <div className="meeting-details-left-testimonial-navigation">
                <img
                  onClick={handlePrevTestimonial}
                  src={selectedMeeting.rightArrow}
                  alt="leftarrow"
                />
                <img
                  onClick={handleNextTestimonial}
                  src={selectedMeeting.leftArrow}
                  alt="rightarrow"
                />
              </div>
            </div>
            <div className="meeting-details-left-all-testimonials-container">
              {selectedMeeting.testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="meeting-details-left-single-testimonial-container"
                >
                  <div className="meeting-details-left-single-testimonial-heading-container">
                    <img src={testimonial.authorImage} alt="emoji" />
                    <div>
                      <p className="meeting-details-left-single-testimonial-author-name">
                        {testimonial.author}
                      </p>
                      <h2>
                        {testimonial.authorProfile}
                      </h2>
                    </div>
                  </div>
                  <p className="meeting-details-left-single-testimonial-about-paragraph-container">
                    {testimonial.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingDetailsLeft;
