import React from "react";
import "./MyDashboard.css";
import "../Subjects/ProgramLearning/QuickNotes/QuickNotes.css";
import { ChatBar, SelectButton } from "../../GenericComponents/Buttons/Buttons";
import { useState } from "react";
import CustomFormField from "../../Molecule/CustomFormField/CustomFormField";
import { tempTopicDetail } from "../Subjects/ProgramLearning/QuickNotes/QuickNotes";
import { Dash_Quick_Link, QUICK_LINKS } from "../../Constants/Constants";
import { useEffect } from "react";

import { useNavigate } from "react-router";

export default function NewEnrollDashboard()  {
  const [inputText, setinputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatStart, setChatStart] = useState(false);

  const handleSendMessage = (id, e) => {
    setChatStart(true);
    console.log(id);
    if (inputText.trim() !== "" || e.key === "Enter") {
      console.log(inputText);
      setinputText("");
      setMessages ([
        ...messages,
        {
          input: inputText,
          response:
            "Of course! How can I assist you with your test today? Please provide more details or questions you'd like to ask, and I'll do my best to help. ",
          type: "Chat",
        },
      ]);
    }
  };

  const navigate = useNavigate();

  const navigateToPtogram = () => {
    navigate("/programs");
  };

  const setQuickInput = (input)=>{
    console.log(input)
    setChatStart(true);

    setMessages ([
        ...messages,
        {
          input: input,
          response:
            "Of course! How can I assist you with your test today? Please provide more details or questions you'd like to ask, and I'll do my best to help. ",
          type: "Chat",
        },
      ]);
  }

  return (
    
        <div className="my-dashboard">
          {chatStart ? (
            <div
              style={{
                width: "90%",
                height: "70vh",
                marginTop: "",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignContent:"flex-end"
              }}
            >
              <div className="subject-notes-list">
                {messages.map((message) => (
                  <CustomFormField question={message} />
                ))}
              </div>
            </div>
          ) : (
            <div className="dashboard-intro">
              <h2>WELCOME TO BYTECODER!</h2>
              <p>
                Welcome to Bytecode – Where Coding Dreams Come to Life! 🚀 Ask
                AI anything, solve problems effortlessly, and embark on your
                coding journey with our expert guidance. Get ready to unlock
                your full coding potential and let Bytecode be your compass on
                this exciting adventure!
              </p>
              <div className="start-learning" onClick={()=> navigateToPtogram()}>Start Learning</div>
            </div>
          )}
          
           <div className="recommendations">
                {Dash_Quick_Link.map((link) => 
                  
                    <div className="recommendation" onClick={()=>setQuickInput(link)}>
                      <p>{link}</p>
                      <img src="/images/send.svg" alt="" />
                    </div>
                  )}
              </div>

          <div className="recommendation-and-input">
            <ChatBar
              inputText={inputText}
              setinputText={setinputText}
              handleSendMessage={handleSendMessage}
          />
          </div>
        </div>


  );
};

