import React, { useState, useEffect } from "react";
import "./PaperSubject.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { TopicwiseDetails } from "./TopicswiseDetails/TopicwiseDetails";
import { SubjectwiseDetails } from "./SubjectwiseDetails/SubjectwiseDetails";
import { getExamsById } from "../../../APIservice/QP/Exams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


function PaperSubject() {
  const navigate = useNavigate();

  const [isTopics, setIsTopics] = useState(false);
  const { examId } = useParams();


  const [exam, setExam] = useState({})

  const handleBackButtonClick = () => {
    navigate(`/questionpapers`)
  }


  useEffect(() => {
    getExamsById(examId).then((exam) => setExam(exam))
  }, [])

  const buttonText = isTopics ? "Topics" : "Subjects";

  const switchButtonClass = isTopics ? "switch-button active" : "switch-button";



  return (
    <div className="papersubject-container">

      <h3> {exam.exam_name} </h3>
      <div className="year-switch">
        <div className="backtoprevious" onClick={handleBackButtonClick}>
          <ArrowBackIcon sx={{ fontSize: "1rem" }} /> Go back
        </div>
        <div className={switchButtonClass} onClick={() => setIsTopics(!isTopics)}>
          <div className="circle"></div>
          <span>{buttonText}</span>
        </div>
      </div>
      {isTopics ? <TopicwiseDetails /> : <SubjectwiseDetails />}
    </div>
  );
}

export default PaperSubject;
