import React, { useState } from "react";
import { useNavigate } from "react-router";
import ProgressBar from "../../../../../GenericComponents/ProgressBar/ProgressBar";
import { useEffect } from "react";
import * as courseApi from "../../../../../APIservice/CoursesApi";

const CourseProgress = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    courseApi.getAllEnrolledCourses("", "", 4).then((result) => {
      console.log(result);
      return setCourses(result);
    });
  }, []);


  const handleProgressCard = (code) => {
    navigate("/programs/" + code + "/learn/chapters");
  };
  return (
    <div>
      
      <div className="enrolled-progress">
        {/* <h1>Enrolled Progress</h1> */}
        <div className="progress-card-container">
          {courses.map((course, idx) => {
            return (
              <div
                key={course.id}
                className="progress-card"
                onClick={() => handleProgressCard(course.code)}
              >
                <div className="progress-course-img">
                  <img src={course.imageUrl} alt="" />
                </div>

                <div className="progress-course-details">
                  <h4>{course.title}</h4>
                </div>
                <div className="prog-course-name-percent">
                  <img src="/images/playbutton.svg" />
                  <p>{course.currentChapterTitle}</p>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <ProgressBar filled={course.progress} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseProgress;
