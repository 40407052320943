import {React, useState} from 'react'
import './SearchBar.css'

const SearchBar = () => {
    const [searchJob, setSearchJob] = useState("");
    const setSearchJobHandler = (e) => {
        setSearchJob(e.target.value);
        console.log(searchJob)
      };
  return (
    <div className="search-bar">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            value={searchJob}
            onChange={setSearchJobHandler}
            placeholder="Search for People"
          />
        </div>
  )
}

export default SearchBar