import React from "react"
import { Link, useParams } from "react-router-dom"
import "./SubjectCard.scss"
export function SubjectPaperCard({ subjectPaper , subscriptionStatus}) {
    const { examId } = useParams();
    return (
        <div>
            <Link to={subscriptionStatus ? `/questionpapers/${examId}/questions?paperId=${subjectPaper.id}` : `/questionpapers/${examId}/subscriptions`} className="link">
                <div
                    className="subject-card"
                    // key={index}
                >
                    {!subscriptionStatus && <img className="lock-image" src="/images/locked.svg" alt="" />}

                    <div className="subject-image">
                        <img src={"https://img.freepik.com/free-vector/gradient-english-school-illustration_23-2149477711.jpg?size=338&ext=jpg&ga=GA1.1.2116175301.1718582400&semt=ais_user"} alt={subjectPaper.subject.name} />
                    </div>
                    <div>
                        <h3>{subjectPaper.subject.title}</h3>
                        <p>
                            {subjectPaper.subject.title}-{subjectPaper.year}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    )
}