import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import AllCareerTrackRecommended from "./AllCareerTrackRecommended/AllCareerTrackRecommended";


export default function UnrolledCareerTracks(){
    return (
        <div>
            <AllCareerTrackRecommended/>
        </div>
    )
}