import * as Congs from "../Configurations"


export const uploadFile = async (formData) => {


    const requestOptions = {
        method: "POST",
        redirect: "follow",
        body: formData
    };

    return fetch(Congs.BASE_URL + "/api/v2/uploadfile/", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}