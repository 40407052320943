import React from 'react'
import './CartPopup.css'
import { ShoppingCart ,Close} from '@mui/icons-material'
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function CartPopup({ handleHidePopup }) {
  const navigate=useNavigate();
    const cartItems=useSelector((state)=>state.cart.items);
    // const dispatch=useDispatch();
    const totalPrice = cartItems.reduce(
      (total, item) => total + (item.discount) * item.quantity,
      0
    );

    const handleCheckout=()=>{
      navigate("/addDetails");
    }
  return (
    <div className="cart-popup-container">
      {cartItems.length === 0 ? (
        <div>
          <span className="close" onClick={handleHidePopup}>
            <Close />
          </span>
          <ShoppingCart sx={{ fontSize: "2rem" }} />
          <p>Your cart is empty Start Shopping now for exclusive discounts</p>
          <div className="button">Continue Shopping</div>
        </div>
      ) : (
        <div className='cart-items'>
          <span className="close" onClick={handleHidePopup}>
            <Close sx={{ fontSize: "1rem" }} />
          </span>
          <ul className="items-container">
            {cartItems.map((item) => (
              <li key={item.id}>
                <div className="item-detail">
                  <img src={item.image} alt={item.name} />
                  <div className="name-quantity">
                    <span>{item.name}</span>
                    <span>Quantity: {item.quantity}</span>
                  </div>
                </div>
                <div className="price">₹ {item.discount}</div>
              </li>
            ))}
          </ul>
          <hr />
          <div className="total">
            <span>Total</span>
            <span>₹ {totalPrice}</span>
          </div>
          <div onClick={handleCheckout} className="button">Proceed to Checkout</div>
        </div>
      )}
    </div>
  );
}

export default CartPopup