import React from "react";
import "./Progress.css";
import CircularProgressBar from "../../../../GenericComponents/CircularProgressBar/CircularProgressBar";
import ChapterWiseBar from "../../../../GenericComponents/ChapterWiseBar/ChapterWiseBar";
import { ANALYSIS } from "../../../../APIservice/ChapterBarApi";

const Progress = () => {
  return (
    <div className="student-progress-container">
      <div className="course-progress-heading">
        <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          Course Progress - Python Programming
        </p>
      </div>

      <div className="all-progress-card-container">
        <div className="circular-progress-and-stats">
          <div className="circular-progress-container">
            <div className="circular-progress">
              <CircularProgressBar progress={80} />
            </div>
            <div className="progress-name-and-date">
              <h5 style={{ margin: 0, fontWeight: "bold" }}>
                Python Programming
              </h5>
              <p style={{ color: "grey" }}>Started on 01-Jan</p>
            </div>
          </div>

          <div className="courses-assignments-stats">
            <div className="assign-heading">
              <p style={{ fontWeight: "bold" }}>Assignments</p>
            </div>
            <div className="assignment-stats">
              <div>
                <p className="total-heading">Total Assignments</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  40
                </p>
              </div>

              <div className="verticle-line"></div>
              <div>
                <p>Completed</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  20
                </p>
              </div>
              <div className="verticle-line"></div>
              <div>
                <p>Pending</p>
                <p className="progress-number" style={{ color: "#DF3737" }}>
                  15
                </p>
              </div>
              <div className="verticle-line"></div>
              <div>
                <p>Not Visited</p>
                <p className="progress-number" style={{ color: "#F1C93B" }}>
                  5
                </p>
              </div>
              <div className="prog-clip">
                <img src="/images/prog-clip.svg" alt="" />
              </div>
            </div>
            <div className="user-note">
              <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                User's Note:
              </p>
              <p style={{ fontSize: ".7rem" }}>
                Please be aware that completing all assignments in every chapter
                is the sole criteria for marking the course as complete.
              </p>
            </div>
          </div>
        </div>

        {/* Chapter stats */}
        <div className="chapter-and-challenges">
          <div className="chapter-done-and-left">
            <div className="chapter-head">
              <h4 style={{ fontWeight: "bold" }}>Chapters</h4>
            </div>

            <div className="total-and-completed">
              <div className="total-chapters">
                <p className="total-heading">Total Chapters</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  40
                </p>
              </div>

              <div className="verticle-line"></div>

              <div className="completed-chapters">
                <p>Completed</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  20
                </p>
              </div>
            </div>
          </div>
          <div className="challenges-container">
            <div className="challenges-head">
              <h4 style={{ fontWeight: "bold" }}>Challenges</h4>
            </div>

            <div className="total-and-completed">
              <div className="total-chapters">
                <p>Attempted</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  40
                </p>
              </div>

              <div className="verticle-line"></div>
              <div className="completed-chapters">
                <p>No Attempted</p>
                <p className="progress-number" style={{ color: "#3FA959" }}>
                  20
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
            // height:"30rem",
            padding: "2rem",
            boxShadow: "0 0px 5px 5px rgb(56 56 56 / 24%)",
          }}
        >
          <h5 style={{ margin: 0, marginBottom: "1rem" }}>
            Chapter wise Performance
          </h5>
          <ChapterWiseBar/>
        </div>
        <div className="analysis-container">
          <div className="student-analysis">
            <h5 style={{ fontWeight: "bold" }}>Analysis</h5>
            <div className="analysis-list">
              <ul>
                {ANALYSIS.map((data) => {
                  return <li>{data}</li>;
                })}
              </ul>
            </div>
          </div>

          <div className="ai-analysis">
            <h5 style={{ fontWeight: "bold" }}>AI Analysis</h5>
            <div className="chat-analysis">
              <div className="greenlight"></div>
              <div className="bot-image">
                <img src="/images/bot.svg" alt="" />
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
