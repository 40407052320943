import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import * as CoursesApi from "../../APIservice/CoursesApi";
import "./Subjects.css";
import EnrolledPrograms from "./Programs/EnrolledPrograms/EnrolledPrograms";
import AllSubjectRecommendedPage from "./Programs/UnrolledPrograms/AllSubjectRecommendedPage/AllSubjectRecommendedPage";

function Subjects() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isCareerTrackActive, setIsCareerTrackActive] = useState(true); // State to track if Career Track is active
  const userInfo = useContext({});
  const userName = userInfo ? userInfo.name : "Mayank";



  useEffect(() => {
    CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) => setCourses(cs));
  }, []);

  const colors = ["#90a2e8", "#8dfcd0", "#e890e8", "#f7bd86"];

  // Function to toggle between Career Track and Courses
  const toggleCareerTrack = () => {
    setIsCareerTrackActive((prev) => !prev);
  };

 
    return (
      <div className="programs-container">
        {(
         courses.length !== 0 ? <EnrolledPrograms/>  : <AllSubjectRecommendedPage />
        )}
     
       
      </div>
    );
  
}

export default Subjects;
