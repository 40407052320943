import React, { useState } from "react";
import { PlayArrowOutlined } from "@mui/icons-material";
import { bookLibrary } from "../../../APIservice/LibraryBooks";
import { useNavigate } from "react-router-dom";
import BooksHistory from "../BooksHistory/BooksHistory";
import Library from "../Library";

const AllRecommendedBooks = () => {
  const categories = [
    "All",
    "React",
    "Kafka",
    "Advance Java",
    "Python",
    "AI/ML",
    "System Design",
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [switchButton, setSwitchButton] = useState(false);
  const switchButtonClass = switchButton
    ? "switch-button active"
    : "switch-button";

    const buttonText = switchButton ? "Videos" : "Books";
    

const handleSwitchButtonClick = () => {
  setSwitchButton(!switchButton);
};

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const readBookClick = (bookId) => {
    navigate(`/library/books/${bookId}`);
  };

  const getFilteredBooks = () => {
    return bookLibrary.filter(
      (booksCategory) =>
        booksCategory.category !== "Recommended" &&
        booksCategory.category !== "History"
    );
  };

  return (
    <div
      className="all-recommended-books"
      style={{ display: "flex", flexDirection: "column", padding: "2rem" }}
    >
       <div className="search-switch-container" style={{width:"100%"}}>
          <div className="input">
            <input
              type="text"
              placeholder="Search for videos"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className={switchButtonClass} onClick={handleSwitchButtonClick}>
            <div className="circle"></div>
            <span>{buttonText}</span>
          </div>
        </div>
      <div className="library-books-container">
        <div className="filter-books-tags">
          <ul className="chips-container">
            {categories &&
              categories.map((category) => (
                <li
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  className={selectedCategory === category ? "active" : ""}
                >
                  {category}
                </li>
              ))}
          </ul>
        </div>
        <div className="allBooks-container">
          {getFilteredBooks().map(
            (booksCategory) =>
              (selectedCategory === "All" ||
                booksCategory.category === selectedCategory) && (
                <div
                  key={booksCategory.id}
                  className="library-book-topic-container"
                >
                  <h2>{booksCategory.category}</h2>
                  <div className="library-book-card-container">
                    {booksCategory.books.map((librarybook) => (
                      <div key={librarybook.id} className="book-card">
                        <div className="book-bg">
                          <img src="/images/bytegpt-bg.png" alt="" />
                        </div>
                        <div className="book-card-image">
                          <img src={librarybook.book_image} alt="" />
                        </div>
                        <div className="book-card-info">
                          <p className="book-title">{librarybook.book_title}</p>
                          <p className="description">
                            {librarybook.book_description}
                          </p>
                        </div>
                        <div className="read-download-book">
                          <span
                            className="read-book"
                            onClick={() => readBookClick(librarybook.id)}
                          >
                            Read
                          </span>
                          <span className="download-book">Download</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default AllRecommendedBooks;
