import React, { useEffect, useState } from "react";
import "./App.css";


import CandidateForm from "./Pages/profile/CandidateProfilePage/CandidateProfilePage.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CoursePlayer from "./Pages/Subjects/ProgramLearning/Sessions/CoursePlayer.jsx";
import {
  DashboardSideNavBar,
  DashboardHeader,
} from "./GenericComponents/DashboardSideNavbar/DashboardSideNavBar.jsx";
import CourseProgramDetailsPage from "./Pages/Subjects/Programs/UnrolledPrograms/CourseProgramDetailsPage/CourseProgramDetailsPage.jsx";
import MyDashboard from "./Pages/MyDashboard/MyDashboard.jsx";
import NewEnrollDashboard from "./Pages/MyDashboard/NewEnrollDashboard.jsx";
import JobAssesments from "./Pages/Jobs/JobAssesments/JobAssesments.jsx";
import BlogDetailsPage from "./Pages/Blogs/BlogReadPage/BlogReadPage.jsx";
import Jobs from "./Pages/Jobs/JobsPage/JobsPage.jsx";
import JobDetails from "./Pages/Jobs/JobDetailsPage/JobDetailsPage.jsx";
import BlogReadingComponent from "./Pages/Blogs/BlogsPage/BlogsPage.jsx";
import Recordings from "./Pages/Subjects/ProgramLearning/Sessions/Recordings/Recordings.jsx";
import ClassPlayer from "./Pages/Subjects/ProgramLearning/Sessions/Recordings/ChessPlayer/ClassPlayer.jsx";

import Subjects from "./Pages/Subjects/Subjects.jsx";
import Assignments from "./Pages/Subjects/ProgramLearning/Assignments/Assignments.jsx";
import AssignmentTest from "./Pages/Subjects/ProgramLearning/Assignments/AssignmentTest/AssignmentTest.jsx";
import Practice from "./Pages/Subjects/ProgramLearning/Practice/Practice.jsx";
import DisplayChallege from "./Pages/Subjects/ProgramLearning/Practice/PracticeChallengeDisplay.jsx";
import LiveClasses from "./Pages/Subjects/ProgramLearning/Sessions/Sessions.jsx";
import Bookmark from "./Pages/Subjects/ProgramLearning/Bookmarks/Bookmark.jsx";
import Chapters from "./Pages/Subjects/ProgramLearning/Chapters/Chapters.jsx";
import QuickNotes from "./Pages/Subjects/ProgramLearning/QuickNotes/QuickNotes.jsx";

import SubscriptionsComponent from "./Pages/profile/ProfileCourses/SubscriptionsComponent.jsx";

import Progress from "./Pages/Subjects/ProgramLearning/Progress/Progress.jsx";
import UnderMaintainance from "./Pages/LoginPages/MaintainancePages/UnderMaintainance/UnderMaintainance.jsx";
import { ThankYou } from "./GenericComponents/ThankYou/ThankYou.jsx";
import Questioner from "./Pages/profile/Questioner/Questioner.jsx";
import QuestionPaper from "./Pages/QuestionPaper/QuestionPaper.jsx";
import PaperSubject from "./Pages/QuestionPaper/PaperSubject/PaperSubject.jsx";
import Questions from "./Pages/QuestionPaper/Questions/Questions.jsx";
import SingleQuestion from "./Pages/QuestionPaper/SingleQuestions/SingleQuestion.jsx";
import Shopping from "./Pages/Shop/Shopping.jsx";
import ItemDetail from "./Pages/Shop/ItemDetail/ItemDetail.jsx";
import Cart from "./Pages/Shop/Cart/Cart.jsx";
import AddContact from "./Pages/Shop/AddContact/AddContact.jsx";
import ShopSuccess from "./Pages/Shop/ShopSuccess/ShopSuccess.jsx";
import SubscriptionPlan from "./Pages/QuestionPaper/AllExams/SubscriptionPlans/SubscriptionPlan.jsx";

import ByteConnect from "./Pages/ByteConnect/ByteConnect.jsx";
import ExpertsPage from "./Pages/ByteConnect/ExpertsPage/ExpertsPage.jsx";
import AboutExpert from "./Pages/ByteConnect/AboutExpert/AboutExpert.jsx";
import ExpertMeetingDetails from "./Pages/ByteConnect/ExpertMeetingDetails/ExpertMeetingDetails.jsx";
import KnowYou from "./Pages/ByteConnect/KnowYou/KnowYou.jsx";
import BookingConfirmed from "./Pages/ByteConnect/BookingConfirmed/BookingConfirmed.jsx";
import AllJobsPage from "./Pages/Jobs/AllJobsPage/AllJobsPage.jsx";
import Library from "./Pages/Library/Library.jsx";
import BookSection from "./Pages/Library/BooksSection/BookSection.jsx";
import PlayVideSection from "./Pages/Library/PlayVideoSection/PlayVideSection.jsx";
import Login from "./Pages/LoginPages/Login/Login.jsx";
import Register from "./Pages/LoginPages/Register/Register.jsx";
import Calendar from "./Pages/Calendar/Calendar.jsx";
import AllExams from "./Pages/QuestionPaper/AllExams/AllExams.jsx";

import Bac from "./Pages/BAC/Bac.jsx";
import TermsandCondition from "./Pages/BAC/T&C/TermsandCondition.jsx";
import BookedSessionPage from "./Pages/ByteConnect/BookedSessionPage/BookedSessionPage.jsx";
import SessionHistory from "./Pages/ByteConnect/SessionHistory/SessionHistory.jsx";
import AllSubjectRecommendedPage from "./Pages/Subjects/Programs/UnrolledPrograms/AllSubjectRecommendedPage/AllSubjectRecommendedPage.jsx";
import AllAppliedJobs from "./Pages/Jobs/AllAppliedJobs/AllAppliedJobs.jsx";
import UnrolledCareerTracks from './Pages/CareerPath/UnRolledCareerTracks/UnrolledCareerTracks.jsx';
import EnrolledCareerTracks from "./Pages/CareerPath/EnrolledCareerTracks/EnrolledCareerTracks.jsx";
import CareerTrackDetails from "./Pages/CareerPath/CareerTracks/CareerTrackDetails/CareerTrackDetails.jsx";
import EnrolledCareerTracksHistory from "./Pages/CareerPath/EnrolledCareerTracks/CareerTrackHistory/EnrolledCareerTracksHistory.jsx";
import AllRecommendedBooks from "./Pages/Library/AllRecommendedBooks/AllRecommendedBooks.jsx";
import BooksHistory from "./Pages/Library/BooksHistory/BooksHistory.jsx";
import VideosHistory from "./Pages/Library/VideosHistory/VideosHistory.jsx";
import SubscriptionDetails from "./Pages/QuestionPaper/RegisteredExams/Subscriptions/SubscriptionDetails.jsx";
import MobileMaintainance from "./Pages/LoginPages/MaintainancePages/MobileMaintainance/MobileMaintainance.jsx";
import SubscriptionSuccess from "./Pages/QuestionPaper/AllExams/SubscriptionPlans/SubscriptionSuccess/SubscriptionSuccess.jsx";
import CareerTrackCourses from './Pages/CareerPath/EnrolledCareerTracks/CareerTrackCourseDetail/CareerTrackCourses.jsx'
import CareerPath from "./Pages/CareerPath/CareerPath.jsx";
function App() {

  const [loggedin, setLoggedin] = useState(false)


  useEffect(() => {
    console.log(localStorage.getItem("loggedin"))
    setLoggedin(localStorage.getItem("loggedin") === "true" ? true : false)
  }, [])

  return (
    <div>


      <div className="dashboard-main-container">
        <Router>
          <DashboardSideNavBar />
          <div className="dashboard-content">
            <DashboardHeader />
            <div className="main-content">
              <Routes>
                {/* Dashboard Components */}
                <Route path="/" element={<MyDashboard />} />
                <Route path="/dashboard" element={<MyDashboard />} />
                <Route path="/enroll-dashboard" element={<NewEnrollDashboard />} />

                {/* career tracks right */}
                <Route path="/career-track" element={<CareerPath />} />
                <Route path="/new-career-tracks" element={<UnrolledCareerTracks />} />
                <Route path="/career-tracks" element={<EnrolledCareerTracks />} />
                <Route path="/career-tracks/:courseCode" element={<CareerTrackDetails />} />
                <Route path="/career-tracks/:courseCode/courses/:courseCode" element={<CareerTrackCourses />} />
                <Route path="/career-tracks-history" element={<EnrolledCareerTracksHistory />} />



                {/* course Components */}
                <Route path="/programs" element={<Subjects />} />
                <Route path="/new-courses" element={<AllSubjectRecommendedPage />} />
                <Route path="/programs/:courseCode" exact element={<CourseProgramDetailsPage />} />
                <Route path="/programs/:courseCode/learn/chapters" exact element={<Chapters />} />
                <Route path="/programs/:courseCode/learn/chapters/course" exact element={<CareerTrackCourses />} />



                {/* live classes screen */}
                <Route
                  path="/programs/:courseCode/learn/sessions"
                  element={<LiveClasses />}
                />
                {/* all recording lectures */}
                <Route
                  path="/programs
                  /:courseCode/learn/sessions/all-recordings"
                  element={<Recordings />}
                />
                <Route
                  path="/programs
                  /:courseCode/learn/sessions/:sessionId"
                  element={<ClassPlayer />}
                />
                {/* quick-notes  */}
                <Route
                  path="dashboard/bookmarks-classes"
                  element={<Bookmark />}
                />
                <Route
                  path="/programs
                  /:courseCode/learn/quick-notes"
                  element={<QuickNotes />}
                />
                <Route
                  path="/programs
                  /:courseCode/learn/quick-notes/:id"
                  element={<QuickNotes />}
                />
                {/* Assignments Components */}
                <Route
                  path="/programs
                  /:courseCode/learn/assignments"
                  element={<Assignments />}
                />
                <Route
                  path="/programs
                  /:courseCode/learn/assignments/:id"
                  element={<AssignmentTest />}
                />
                {/* progress Components */}
                <Route
                  path="/programs
                  /:courseCode/learn/progress"
                  element={<Progress />}
                />
                {/* Practice Components */}
                <Route path="/programs/:courseCode/learn/practices" element={<Practice />} />
                <Route path="/programs/:courseCode/learn/practices/:code" element={<DisplayChallege />} />

                {/* not in use */}
                <Route path="/learning/play/:id" element={<CoursePlayer />} />





                {/* Blog Components */}
                <Route path="/blogs" element={<BlogReadingComponent />} />
                <Route path="/blogs/:id" element={<BlogDetailsPage />} />
                {/* Jobs COmponents */}
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/alljobs" element={<AllJobsPage />} />
                <Route path="job-details/:code" element={<JobDetails />} />
                <Route path="/all-applied-jobs" element={<AllAppliedJobs />} />
                <Route path="/jobs/:code/assesment" element={<JobAssesments />} />
                <Route path="/jobs/:code/assesment/:id" element={<AssignmentTest />} />
                {/* Calendar */}
                <Route path="/calender" element={<Calendar />} />


                {/* Question Papers */}
                <Route path="/questionpapers" element={<QuestionPaper />} />
                <Route path="/allpapers" element={<AllExams />} />
                <Route path="/questionpapers/:examId" element={<PaperSubject />} />

                <Route path="/questionpapers/:examId/questions" element={<Questions />} />
                <Route path="/questionpapers/:examId/questions/:questionId" element={<SingleQuestion />} />
                <Route path="/questionpapers/:examId/subscriptions" element={<SubscriptionPlan />} />
                <Route path="/questionpapers/:examId/subscription-history" element={<SubscriptionDetails />} />
                <Route path="/questionpapers/:examId/subscription-success" element={<SubscriptionSuccess />} />


                {/* Shop study material */}
                <Route path="/shop" element={<Shopping />} />
                <Route path="/shop/:id" element={<ItemDetail />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/addDetails" element={<AddContact />} />
                <Route path="/success" element={<ShopSuccess />} />
                {/* user profile courses  */}
                {/* <Route path="/dashboard/blogs" element={<MediumLikeComponent />} /> */}
                <Route path="/profile" element={<CandidateForm />} />
                <Route path="/user-courses" element={<SubscriptionsComponent />} />
                {/* ByteConnect */}
                <Route path="/byteconnect" element={<ByteConnect />} />
                <Route
                  path="/byteconnect/expertsPage"
                  element={<ExpertsPage />}
                />
                <Route
                  path="/byteconnect/bookingDetailsPage"
                  element={<BookedSessionPage />}
                />
                <Route
                  path="/byteconnect/bookingDetailsPage/SessionHistory"
                  element={<SessionHistory />}
                />
                <Route
                  path="/byteconnect/expertsPage/:id"
                  element={<AboutExpert />}
                />
                <Route
                  path="/byteconnect/expertsPage/expert-meeting-details/:id/:meetingIndex"
                  element={<ExpertMeetingDetails />}
                />
                <Route
                  path="/byteconnect/expertsPage/expert-meeting-details/:id/:meetingIndex/knowyou"
                  element={<KnowYou />}
                />
                <Route
                  path="/byteconnect/expertsPage/expert-meeting-details/:id/:meetingIndex/bookingconfirmed"
                  element={<BookingConfirmed />}
                />
                {/* Library */}
                <Route path="/library" element={<Library />} />
                <Route path="/library/books/:id" element={<BookSection />} />
                <Route path="/library/allrecommendedbooks" element={<AllRecommendedBooks />} />
                <Route path="/library/bookshistory/all" element={<BooksHistory />} />
                <Route path="/library/videos/:id" element={<PlayVideSection />} />
                <Route path="/library/videos/history" element={<VideosHistory />} />
                {/* BAC */}
                <Route path="/bac" element={<Bac />} />
                {/* Under Maintainance */}
                <Route
                  path="/undermaintainance"
                  element={<UnderMaintainance />}
                />

                <Route
                  path="/undermaintainance"
                  element={<UnderMaintainance />}
                />

                {/* thanks screen */}
                <Route path="/thankyou" element={<ThankYou />} />
                {/* questioner screen */}
                <Route path="/questioner" element={<Questioner />} />
                {/* login screen */}
                <Route
                  path="/bac/termsandconditions"
                  element={<TermsandCondition />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* mobile maintainance */}
                <Route path="/mobile-maintainance" element={<MobileMaintainance />} />
              </Routes>
            </div>
          </div>
        </Router>
      </div>

    </div>
  );
}

export default App;
