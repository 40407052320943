import PersonIcon from "@mui/icons-material/Person";
import SubjectIcon from "@mui/icons-material/Subject";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import { Dashboard, ListAlt, Storefront,PeopleAlt  } from "@mui/icons-material";

import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { useLocation } from "react-router-dom";
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import AssignmentIndIcon from '@mui/icons-material/TopicOutlined';


export const COLOR_CODES = { primary: "#0F547E", secondary: "#28B2B6" };

export const Heading_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export const TEXT_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export function getButton_css(tab, selectedIcon) {
  return {
    color: tab.tag === selectedIcon ? "white" : "black",
    backgroundColor: tab.tag === selectedIcon ? "green" : "white",
  };
}

export const DASHBOARD_TABS = [
         { tag: "Dashboard", path: "/dashboard", icon: <Dashboard /> },
        //  { tag: "programs", path: "/programs", icon: <SubjectIcon /> },
        //  { tag: "career-track", path: "/career-track", icon: <SubjectIcon /> },
        //  { tag: "Blogs", path: "/blogs", icon: <LibraryBooksIcon /> },
        //  { tag: "Calender", path: "/calender", icon: <CalendarMonthIcon /> },
        //  { tag: "Byte Connect", path: "/byteconnect", icon: <PeopleAlt /> },
        //  { tag: "Jobs", path: "/jobs", icon: <WorkIcon /> },
        //  { tag: "Shop", path: "/shop", icon: <Storefront /> },
        //  {tag:"BAC",path:"/bac",icon:<AssignmentIndIcon/>},
         { tag: "Question Papers", path: "/questionpapers", icon: <ListAlt /> },
        //  { tag: "Library", path: "/library", icon: <LocalLibraryIcon /> },
         { tag: "Profile", path: "/profile", icon: <PersonIcon /> },

       ];

export const SUBJECT_SIDE_NAV = [
  { tag: "Chapters", path:"/programs/CSE100306/learn/chapters", icon:<TopicOutlinedIcon /> },
  { tag: "Sessions", path:"/programs/CSE100306/learn/sessions", icon:<CastForEducationOutlinedIcon /> },
  { tag: "Notes",path:"/programs/CSE100306/learn/quick-notes", icon:<DescriptionOutlinedIcon /> },
  { tag: "Practice" , path:"/programs/CSE100306/learn/practices", icon:<Dashboard />},
  { tag: "Assignment", path:"/programs/CSE100306/learn/assignments", icon:<AssignmentIndOutlinedIcon /> },
  {tag:"Progress", path:"/programs/CSE100306/learn/progress", icon:<AssessmentOutlinedIcon />},
];

export const QUICK_LINKS = ["Regenrate", "Bookmarks", "Examples", "Videos"];

export const Dash_Quick_Link = [
  "How to Start Your DSA Journey at Bytecoder' for a Smooth Beginning.",
  "New to Coding? Let's Begin! Explore 'Bytecode's Coding Basics Roadmap' to Build a Strong Foundation.",
  "New to Coding? Let's Begin! Explore 'Bytecode's Coding Basics Roadmap' to Build a Strong Foundation.",
];

export function getSideNav(location) {
  if (location === "programs") {
    return SUBJECT_SIDE_NAV;
  }
  return DASHBOARD_TABS;
}
