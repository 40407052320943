import React from 'react'
import './Assessment.scss'
import { Rule } from '@mui/icons-material';
import { assessments } from '../../../APIservice/Assessments';
function Assessments({ handleTandCVisiblity }) {
  return (
    <div className="assesment-container">
      <h3>Assessments</h3>
      <div className="assessment-card-container">
        {assessments.map((assesment) => (
          <div key={assesment.id} className="assessment-card">
            <div className="head">
              <span className="expired-date">
                Ends on {assesment.expiry_date}
              </span>
              <span className="type">
                <Rule /> {assesment.type}
              </span>
            </div>
            <div className="body">
              <h3>{assesment.name}</h3>
              <p>Mentor : - {assesment.mentor}</p>
            </div>
            <div className="footer" onClick={handleTandCVisiblity}>
              <span>Take Test</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Assessments