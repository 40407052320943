import React from "react";
import { careerMentor } from "../../../APIservice/CareerMentor";
import './CareerTrackMentors.css'

const CareerTrackMentors = () => {
  return (
    <div className="career-track-mentors">
      <h1 className="career-track-mentors-heading">Mentors</h1>
      <div  className="career-track-mentors-card-container">
      {careerMentor.map((mentor) => (
        <div key={mentor.id} className="career-track-mentors-card">
          <img src={mentor.mentorImage} alt={mentor.mentorName} />
          <div className="career-track-mentors-details">
            <h1>{mentor.mentorName}</h1>
            <h2>{mentor.mentorProfession}</h2>
            <p>{mentor.mentorExperience}</p>
          </div>
        </div>
      ))}
      </div>
   
    </div>
  );
};

export default CareerTrackMentors;
