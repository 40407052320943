import { React, useState } from "react";
import "./BookingConfirmed.css";
import { Checkmark } from "react-checkmark";
import { SendOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

const BookingConfirmed = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEdit = () => {
    window.history.back(); // Navigate back to the previous page
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="booking-confirmed-page">
      <div className="booking-confirmed-container">
        <div className="booking-confirmed-header-details" >
         
            <h3  onClick={handleEdit}>&#60; Back</h3>
    
            <div className="modal-close" onClick={closeModal}>
            ×
          </div>
        </div>
        <div className="booking-confirmed-details-body">
          <div className="tick-container">
            <img src="/images/successful.gif" alt="Order successful" />
          </div>
          <div className="booking-confirmed-details-description-body">
            <h1 style={{ marginBottom: "0px" }}>Booking Confirmed</h1>
            <p> for a 1:1 Meeting</p>
            <div className="booking-confirmed-details-description">
              {" "}
              Monday 12th februray | 11:30 - 11:45 AM{" "}
            </div>
          </div>
        </div>
        <div className="booking-confirmed-page-footer">
          Meeting details are sent to your Email and Mobile number
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmed;
