import React, { useEffect, useState } from 'react'
import './SubscriptionSuccess.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { getExamsById } from '../../../../../APIservice/QP/Exams';


export default function SubscriptionSuccess() {
  const navigate = useNavigate();
  const { examId } = useParams();

  const [examDetails, setExamDetails] = useState({})

  useEffect(() => {
    const redirect = setTimeout(() => {
      navigate("/questionpapers");
    }, 5000);
    return () => clearTimeout(redirect)
  }, [navigate])


  useEffect(() => {
    getExamsById(examId).then((exam) => setExamDetails(exam))

  }, [])


  return (
    <div className="subscription-success-container">
      <p> {examDetails.title} </p>
      <div className="contain">
        <img src="/images/successful.gif" alt="Order successful" />
        <p style={{color:'#02720d'}}>
        Subscription Successful
        </p>
        <p style={{fontSize:'1rem'}}>
        wishing you all the best in your future endeavors
        </p>
        <span className="continue" onClick={()=> navigate("/questionpapers")}> Continue Your Journey </span>

        <div className="delivery-information">
          Subscription details are sent to your Email and Mobile number
        </div>
      </div>
    </div>
  );
}

