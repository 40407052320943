import React from "react";
import BookedSession from "../BookedSession/BookedSession";
import { mentorshipData } from "../../../APIservice/MentorshipCard"; // Import mentorshipData array
import "./BookedSessionPage.css";
import { Link } from "react-router-dom";

const BookedSessionPage = () => {
  return (
    <div className="booked-session-page">
      <div className="booked-session-page-header">
        <h1>Booked Sessions</h1>
        <Link to="/byteconnect/bookingDetailsPage/SessionHistory">
          <div className="booked-session-history">
            <img src="/images/basil_history-outline.svg" /> Session History{" "}
          </div>
        </Link>
      </div>
      <div>
        <BookedSession mentors={mentorshipData} />
      </div>
    </div>
  );
};

export default BookedSessionPage;
