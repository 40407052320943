import React, { useState, useEffect } from "react";
import { ArrowForward, CoPresent, KeyboardArrowDown, KeyboardArrowUp, Lock, TipsAndUpdates } from "@mui/icons-material";
import RichTextEditor from "../../../../GenericComponents/TextEditor/RichTextEditor";


export function UnlockedQuestion(props) {
  const [hintVisible, setHintVisible] = useState(false);
  const [explanationVisible, setExplanationVisible] = useState(false);
  const subjectColorCode = ["#B0E4DD", "#C5B3D5", "#FFC28A", "#DAF3FA"];
  const currentQuestion = props.currentQuestion;

  console.log("currentQuestion: " + currentQuestion.question_text)
  const handleHints = () => {
    setHintVisible(!hintVisible);
  }
  const handleExplanation = () => {
    setExplanationVisible(!explanationVisible);
  }
  return (
    <div>

      {/* question */}
      <div className="question-comment-section">
        <div className="question">
          <div className="tags-container">
            {currentQuestion.related_tags?.map((tag, index) => (
              <span
                style={{
                  background:
                    subjectColorCode[index % subjectColorCode.length],
                }}
              >
                {tag.name}
              </span>
            ))}
          </div>
          <h3>
            <RichTextEditor content={currentQuestion.question_text ? currentQuestion.question_text : ''} preview={false} />
          </h3>

            <br></br>
            <hr/>
          {/* hint */}
          <div className="accordion">
            <div className="hints-title" onClick={handleHints}>
              <TipsAndUpdates /> Hint for the Solution
              {hintVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {hintVisible && (
              // <div className="hint">{currentQuestion.hint}</div>
              <RichTextEditor content={currentQuestion.hint ? currentQuestion.hint : ''} preview={false} />

            )}
          </div>

          {/* explainations */}
          <div className="accordion">
            <div onClick={handleExplanation}  className="hints-title">
              <CoPresent /> Explanation
              {explanationVisible ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </div>
            {explanationVisible && (
              // <div className="explanation">{currentQuestion.solution}</div>
              <RichTextEditor content={currentQuestion.solution ? currentQuestion.solution : ""} preview={false} />

            )}
          </div>

          
        </div>
      </div>

  
    </div>
  )
}