import React from "react";
import { useParams } from "react-router-dom";
import { expertInfo } from "../../../APIservice/ExpertCardInfo";
import "./AboutExpertCard.css";

const AboutExpertCard = () => {
  const { id } = useParams();
  const expert = expertInfo.find((expert) => expert.id === parseInt(id));

  if (!expert) {
    return <div>Expert not found</div>;
  }

  return (
    <div className="about-expert-card-container">
      <div className="about-expert-images-container">
        <img
          src={expert.image3}
          alt="Image 3"
          style={{borderRadius:"20px 20px 0px 0px"}}
          className="about-expert-image"
        />
        <div className="about-expert-byte-verified-container">
          <img src={expert.image4} alt="Image 4" />
          <span className="about-expert-byte-verified-text">
            {expert.verified}
          </span>
        </div>
      </div>
      <div className="about-expert-text-container">
        <div className="about-expert-details-container">
          <h2 className="about-expert-expert-name">{expert.studentName}</h2>
          <p className="about-expert-expert-role">{expert.studentRole}</p>
        </div>

        {/* About */}
        <div className="about-expert-details-summary">
          <p>{expert.about}</p>
        </div>

        {/* Skills */}
        <div className="about-expert-skills-section">
          <h3 className="expert-details-skills-text">Skills:</h3>
          <div className="expert-details-all-skills-container">
            {expert.skills.map((skill, index) => (
              <div
                className="expert-details-skills-single-container"
                key={index}
              >
                {skill}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutExpertCard;
