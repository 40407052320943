import React, { useEffect } from 'react'
import './ShopSuccess.css'
import { useNavigate } from 'react-router-dom';
function ShopSuccess() {
    const navigate=useNavigate();
    useEffect(()=>{
        const redirect=setTimeout(()=>{
            navigate("/shop");
        },35000);
        return ()=>clearTimeout(redirect)
    },[navigate])
    const currentDate = new Date();
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(currentDate.getDate() + 3);
  return (
    <div className="success-container">
      <div className="contain">
        <img src="/images/successful.gif" alt="Order successful" />
        <span className="delivery-date">
          Arriving on{" "}
          {new Date(
            new Date().getTime() + 3 * 24 * 60 * 60 * 1000
          ).toDateString()}{" "}
        </span>
        <div className="delivery-information">
          Order details are sent to your Email and Mobile number
        </div>
      </div>
    </div>
  );
}

export default ShopSuccess