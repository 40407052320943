import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ExpertMeetings.css";

const ExpertMeetings = ({ expertMeetings, id }) => {
  const handleMeetingCardClick = (meetingIndex) => {
    // Navigate to the ExpertMeetingDetails page with the selected meeting's index included in the URL
    window.location.href = `/byteconnect/expertsPage/expert-meeting-details/${id}/${meetingIndex}`;
  };
  // State to store the selected meeting types
  const [selectedMeetingTypes, setSelectedMeetingTypes] = useState(["All"]);

  // Function to toggle selected meeting type
  const toggleSelectedMeetingType = (meetingType) => {
    if (selectedMeetingTypes.includes(meetingType)) {
      // Remove the meeting type if it's already selected
      setSelectedMeetingTypes(
        selectedMeetingTypes.filter((type) => type !== meetingType)
      );
    } else {
      // Add the meeting type if it's not selected
      setSelectedMeetingTypes([...selectedMeetingTypes, meetingType]);
    }
  };

  // Function to check if a meeting type is selected
  const isMeetingTypeSelected = (meetingType) =>
    selectedMeetingTypes.includes(meetingType) ||
    selectedMeetingTypes.includes("All");

  // Filter expert meetings based on selected meeting types
  const filteredMeetings = expertMeetings.filter((meeting) =>
    isMeetingTypeSelected(meeting.buttonName)
  );

  // Group meetings by buttonHeading
  const groupedMeetings = filteredMeetings.reduce((acc, meeting) => {
    if (!acc[meeting.buttonHeading]) {
      acc[meeting.buttonHeading] = [];
    }
    acc[meeting.buttonHeading].push(meeting);
    return acc;
  }, {});

  return (
    <div className="expert-meetings-container">
      {/* Render buttons for filtering */}
      <div className="expert-meetings-header-buttons-container">
        {/* Map through the unique meeting types */}
        {[
          "All",
          ...new Set(expertMeetings.map((meeting) => meeting.buttonName)),
        ].map((meetingType) => (
          <button
            key={meetingType}
            onClick={() => toggleSelectedMeetingType(meetingType)}
            className={
              isMeetingTypeSelected(meetingType)
                ? "active expert-button-style"
                : "expert-button-style"
            }
            style={{
              background: isMeetingTypeSelected(meetingType)
                ? "rgba(68, 9, 120, 1)"
                : "",
              color: isMeetingTypeSelected(meetingType) ? "white" : "",
            }}
          >
            {meetingType}
          </button>
        ))}
      </div>
      {/* Render filtered expert meetings */}
      <div className="expert-meetings-cards-container">
        {Object.entries(groupedMeetings).map(([buttonHeading, meetings]) => (
          <div key={buttonHeading}>
            <h2>{buttonHeading}</h2>
            <div className="expert-meeting-particular-section-cards">
              {meetings.map((meeting, index) => (
                <Link
                  to={`/byteconnect/expertsPage/expert-meeting-details/${id}/${index}`}
                  className="card-link"
                >
                  <div className="expert-meeting-card">
                    {/* Render meeting card details */}
                    <div className="expert-meeting-rating-container">
                      <p className="expert-meeting-rating-popular-text">
                        {meeting.popularButton}
                      </p>
                      <div className="expert-meeting-rating-text-container">
                        <p className="expert-meeting-rating-text">
                          {meeting.rating}
                        </p>
                        <p className="expert-meeting-rating-image">
                          {meeting.ratingImage}
                        </p>
                      </div>
                    </div>

                    <div className="expert-meeting-about-mentorship-container">
                      <div className="expert-meeting-mentorship-type-text">
                        {meeting.buttonCardName}
                      </div>
                      <span>
                        {" "}
                        <p>{meeting.aboutMentorship}</p>
                      </span>
                    </div>
                    <div className="expert-card-timing-and-rate-container">
                      <div className="expert-card-timing-container">
                        <img
                          style={{ height: "1.5rem", width: "1.5rem" }}
                          src={meeting.timeImage}
                          alt="time"
                        />
                        <div className="expert-card-timing-text-container">
                          <p className="expert-card-time-text">
                            {meeting.time}
                          </p>
                          <p className="expert-card-meeting-type-text">
                            {meeting.meetingType}
                          </p>
                        </div>
                      </div>
                      <div className="expert-card-rate-container">
                        <img src={meeting.chargeImage} />
                        <p className="expert-card-rate-text">
                          {meeting.charge}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpertMeetings;
