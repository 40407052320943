import React from "react";
import "./QuestionNavigator.scss";

function QuestionNavigator({ quizQuestions, currentIndex, setIndex, attemptedQuestions }) {
  return (
    <div className="question-navigator">
      {quizQuestions.map((_, idx) => (
        <div
          key={idx}
          className={`question-box ${idx === currentIndex ? "active" : ""} ${attemptedQuestions[idx] ? "attempted" : ""}`}
          onClick={() => setIndex(idx)}
        >
          {idx + 1}
        </div>
      ))}
    </div>
  );
}

export default QuestionNavigator;
