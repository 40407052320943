import { createSlice } from "@reduxjs/toolkit";

const initialState={
    questions:[],
    currentQuestion:{},
    currentQuestionIndex:0,
}

const QPSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addQuestions: (state, action) => {
      // state.questions =[]
      state.questions=action.payload; // Add questions 
    },
  
    updateCurrentQuestion: (state, action) => {
      const { currentQuestion, currentQuestionIndex } = action.payload;
      state.currentQuestion = currentQuestion
      state.currentQuestionIndex = currentQuestionIndex
    },
  },
});

export const {addQuestions,updateCurrentQuestion}=QPSlice.actions;
export default QPSlice.reducer;