import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import * as CoursesApi from "../../../../APIservice/CoursesApi";
import "./EnrolledPrograms.scss";
import CourseProgress from "./CourseProgress/CourseProgress";

import SubjectCourseCard from "../CourseCard/SubjectCourseCard";


export default function EnrolledPrograms(){
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [recommendedcourses, setRecommendedCourses] = useState([]);
    const userInfo = useContext({});
    const userName = userInfo ? userInfo.name : "Mayank";
  
  
    const handleViewAll = () => {
        // Navigate to the page displaying all courses
        navigate("/new-courses");
      };

      const openCourse = (code) => {
        navigate("/programs/" + code);
      };

    useEffect(() => {
      CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) => setCourses(cs));
      CoursesApi.getRecommendedCourses().then((cs) => setRecommendedCourses(cs));
    }, []);
  
    const colors = ["#90a2e8", "#8dfcd0", "#e890e8", "#f7bd86"];
  
    return(
        <div>
        <div className="subject-course-progress">
       <div className="subject-course-progress-text-container">
         <h1>Hello Kiranmayi!!</h1>
         <p>
           Pick up right where you left off and keep progressing seamlessly
           through your course.
         </p>
         <div className="subject-course-progress--view-all-button">
           View All
         </div>
       </div>
       <div>
         {" "}
         <CourseProgress />
       </div>
     </div>

     <div className="discover-courses">
   
        <div className="program-container">
                <div className="program-container-header">
                    <h2>Recommended For You !!</h2>
                    <h3 onClick={handleViewAll}>View All</h3>
                </div>
                <div className="programGrid">

                    {recommendedcourses.slice(0, 3).map((course) => (
                        <SubjectCourseCard
                            key={course.id}
                            course={course}
                            openCourse={() => openCourse(course.code)}
                            isCareerTrack={false}
                        />
                    ))
                        }
                </div>
        </div>
    </div>

   

     </div>
    )

}