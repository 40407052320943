import React,{useState} from 'react'
import './AddContact.css'
import { KeyboardArrowUp, KeyboardArrowDown, LocalShipping } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

function AddContact() {
      const [contactVisible, setContactVisible] = useState(true);
      const handleAccordion = () => {
        setContactVisible(!contactVisible);
      };
      const cartItems = useSelector((state) => state.cart.items);

      const totalPrice = cartItems.reduce(
        (total, item) => total + (item.price - item.discount) * item.quantity,
        0
      );
      const totalQuantity = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );
      const currentDate = new Date();
      const deliveryDate = new Date(currentDate);
      deliveryDate.setDate(currentDate.getDate() + 3);
      const navigate=useNavigate();

      const handlePlaceOrder=()=>{
        navigate("/success")
      }

  return (
    <div className="contact-container">
      <div className="contact-detail-section">
        <div className="accordion">
          <div className="contact-title" onClick={handleAccordion}>
            Contact Details
            {contactVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
          {contactVisible && (
            <form className="form-container">
              <div className="name column-flex">
                <label>Full Name</label>
                <input type="text" placeholder="Name" />
              </div>
              <div className="email column-flex">
                <label>Email</label>
                <input type="email" placeholder="email@email.com" />
              </div>
              <div className="mobile-number column-flex">
                <label>Mobile Number</label>
                <input type="text" placeholder="Mobile Number" />
              </div>
              <div className="address column-flex">
                <label>Address</label>
                <textarea type="text" placeholder="Address" />
              </div>
              <div className="pincode column-flex">
                <label>Pincode</label>
                <input type="text" placeholder="Pincode" />
              </div>
              <div className="delivery-date">
                <LocalShipping />
                Delivered by{" "}
                {new Date(
                  new Date().getTime() + 3 * 24 * 60 * 60 * 1000
                ).toDateString()}
              </div>
            </form>
          )}
          <div className="button">
            <span>Continue</span>
          </div>
        </div>
      </div>
      <div className="pricing">
        <div>
          <h3>Price Details</h3>
        </div>
        <div className="price flex">
          <span>Price ({totalQuantity} items)</span>
          <span>₹ {totalPrice}</span>
        </div>
        <div className="discount flex">
          <span>Discount</span>
          <span>
            ₹{" "}
            {cartItems.reduce(
              (total, item) => total + item.discount * item.quantity,
              0
            )}
          </span>
        </div>
        <hr />
        <div className="total flex">
          <span>Total</span>
          <span>₹ {totalPrice}</span>
        </div>
        <div onClick={handlePlaceOrder} className="checkout-button">Place Order</div>
      </div>
    </div>
  );
}

export default AddContact