import React, { useState } from 'react'
import './Shopping.css'
import AvailableItems from "./Books/AvailableItems"
function Shopping() {
    const [searchQuery,setSearchQuery]=useState('');
  return (
    <div className="shopping-container">
      {/* <img src="/images/shop-design.png" alt="" className='design' /> */}
      <div className="heading">
        <div className="input">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        
      </div>
      <AvailableItems searchQuery={searchQuery} />
    </div>
  );
}

export default Shopping