import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UserProfile.scss";
import * as loginApi from "../../../APIservice/LoginRegistrationApi";
import Loader from "../../../GenericComponents/Loader/Loader";
import { uploadFile } from "../../../APIservice/QP/FileUpload";

function UserProfile() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    profile_image_url: null,
    gender: '',
    exam_preference: ''
  });

  const [editMode, setEditMode] = useState(false);

  const [selectedInput, setSelectedInput] = useState(null);
  const navigate = useNavigate();



  const handleImageUpload = (event) => {
    setEditMode(true)
    let form = new FormData();
    const file = event.target.files[0];
    form.append("file", file, file.name);
    uploadFile(form).then(res => setFormData({ ...formData, profile_image_url: res.file_url }))

  };

  useEffect(() => {
    let user_id = localStorage.getItem("user_id");

    loginApi
      .getUserInfo(user_id)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          return response.json();
        } else {
          // window.open("https://bytecoder.in/login", "_self");
          return response.json();
        }
      })
      .then((data) => {
        console.log("data", data);
        return setFormData(data);
      })
      .catch((e) => {
        console.log("Error " + e);
        window.open("https://bytecoder.in/login", "_self");
      });
  }, []);

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const interestInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    console.log(value)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditClick = (inputRef) => {
    setEditMode(true);
    setSelectedInput(inputRef);
    inputRef.current && inputRef.current.focus();
  };


  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    console.log(formData)
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }

    if (!formData.name) {
      newErrors.name = 'Full name is required';
    } else if (formData.name.length < 3) {
      newErrors.name = 'Full name must be at least 3 characters long';
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }


    if (!formData.gender) {
      newErrors.gender = 'Gender is required';
    }

    if (!formData.exam_preference) {
      newErrors.exam_preference = 'Exam preference is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSaveChanges = () => {

    if (validate()) {

      setEditMode(false);
      setSelectedInput(null);

      let user_id = localStorage.getItem("user_id");

      loginApi.updateUserInfo(user_id, formData).then((res) => {
        setFormData(res)
      });
    }

    console.log("Saved user information:", formData);
  };

  return (
    <div>
      {formData ? (
        <div className="user-profile-container">
          <div className="user-profile-detials-box">
            <p
              style={{
                alignSelf: "start",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              My Profile
            </p>

            <div>
              <div className="user-dash-profile-img">
                <img src={formData.profile_image_url == null ? './images/profile_image.png' : formData.profile_image_url} alt="User Profile" />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />

            </div>

            <div className="user-profile-data">
              <form className="profile-form" action="">
                <div className="user-name">
                  <label htmlFor="">Full Name <span className="asterisk">*</span></label>
                  <div
                    className={`input-div ${selectedInput === nameInputRef ? "focused" : ""
                      }`}
                  >
                    <input
                      type="text"
                      ref={nameInputRef}
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== nameInputRef}

                    />
                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(nameInputRef)}
                    ></i>
                  </div>
                  {errors.name && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.name}</p>}

                </div>
               
                <div className="user-email">
                  <label htmlFor="">Email <span className="asterisk">*</span></label>
                  <div
                    className={`input-div ${selectedInput === emailInputRef ? "focused" : ""
                      }`}
                  >
                    <input
                      type="email"
                      ref={emailInputRef}
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== emailInputRef}

                      
                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(emailInputRef)}
                    ></i>
                  </div>
                  {errors.email && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.email}</p>}

                </div>
                <div className="user-password">
                  <label htmlFor="">Password <span className="asterisk">*</span></label>
                  <div
                    className={`input-div ${selectedInput === passwordInputRef ? "focused" : ""
                      }`}
                  >
                    <input
                      type="password"
                      ref={passwordInputRef}
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== passwordInputRef}

                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(passwordInputRef)}
                    ></i>
                  </div>
                  {errors.password && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.password}</p>}

                </div>

                <div className="user-number">
                  <label htmlFor="">Mobile number <span className="asterisk">*</span></label>
                  <div
                    className={`input-div ${selectedInput === phoneInputRef ? "focused" : ""
                      }`}
                  >
                    <input
                      type="number"
                      ref={phoneInputRef}
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== phoneInputRef}

                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(phoneInputRef)}
                    ></i>
                  </div>
                  {errors.phone && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.phone}</p>}

                </div>
                <div className="user-gender">
                  <label>
                    Gender <span className="asterisk">*</span>
                  </label>
                  <div
                    className={`input-div ${selectedInput === genderInputRef ? "focused" : ""
                      }`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >

                    <select
                      className="user-dropdowns"
                      ref={genderInputRef}
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== genderInputRef}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(genderInputRef)}
                    ></i>
                  </div>
                  {errors.gender && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.gender}</p>}

                </div>


                <div className="user-interest">
                  <label>
                    Exam Preference <span className="asterisk">*</span>
                  </label>                  <div
                    className={`input-div ${selectedInput === interestInputRef ? "focused" : ""
                      }`}
                    style={{ display: 'flex', alignItems: 'center' }}

                  >

                    <select
                      className="user-dropdowns"
                      value={formData.exam_preference}
                      ref={interestInputRef}
                      name="exam_preference"
                      onChange={handleInputChange}
                      disabled={!editMode || selectedInput !== interestInputRef}
                    >
                      <option value="">Select Exam</option>
                      <option value="NEET">NEET</option>
                      <option value="IIT">IIT</option>
                    </select>

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(interestInputRef)}
                    ></i>
                  </div>
                  {errors.exam_preference && <p style={{ color: 'red', fontSize: '.7rem' }}>{errors.exam_preference}</p>}

                </div>
              </form>
              {editMode && (
                <div className="save-profile" onClick={handleSaveChanges}>
                  save changes
                </div>
              )}
            
              <div
                className="logout-profile"
                onClick={() => navigate("/login")}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default UserProfile;



