import React, { useContext } from "react";
import './CreateEventButton.css'

import GlobalContext from "../../../context/GlobalContext";
export default function CreateEventButton() {
  const { setShowEventModal } = useContext(GlobalContext);
  return (
    <button
      onClick={() => setShowEventModal(true)}
      className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl create-event-button"
    >
      <span className="pl-3 pr-7">Add Event</span>
    </button>
  );
}
