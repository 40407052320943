import * as Congs from "../Configurations"







export function getSubjects(examId, year) {


  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };


  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/subjects?year=${year}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getAllPapers(examId, year) {


  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };


  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/papers?year=${year}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getTopics(subjectId) {


  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };


  return fetch(`${Congs.BASE_URL}/api/v2/subjects/${subjectId}/topics`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}





export function getAllActiveExamSubscriptions(userId, fetchOptions) {

  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };
  const url =`${Congs.BASE_URL}/api/v2/users/${userId}/exam-subscriptions?is_active=true&page=${fetchOptions.page}`
  const fetchUrl = Congs.prepareUrl(url, fetchOptions);

  return fetch(fetchUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getSubscriptionsHistoryOfExam(examId, is_active, page = 1, limit = 4) {

  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/subscriptions?page=1&limit=10`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}


export function getALLSubscriptionsHistory(user_id) {

  const requestOptions = {
    method: "GET",
    headers: Congs.getHeaders(),
    redirect: "follow"
  };

  return fetch(`${Congs.BASE_URL}/api/v2/users/${user_id}/subscriptions`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));

}






export function getAllQuestionsOfSubject(examId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };
  // subjectId = 4

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/questions?page=1&limit=100`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}




export function getQuestionDetails(examId, questionId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/exams/${examId}/questions/${questionId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}



export function getAllCommentsOfQuestion(questionId) {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/questions/${questionId}/comments?limit=10&page=1`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}



export function addCommentsOfQuestion(questionId, payload) {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(payload),
    headers: Congs.getHeaders()

  };

  return fetch(`${Congs.BASE_URL}/api/v2/questions/${questionId}/comments`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}









