import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as BlogsService from "../../../APIservice/BlogsApi";
import "./BlogReadPage.css";
import ReactMarkdown from "react-markdown";
import { FaLinkedinIn } from "react-icons/fa";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CommentSection from "../../../GenericComponents/CommentSection/CommentSection";

const BlogDetailsPage = () => {
  const [blogData, setBlogData] = useState({ paragraphs: [], reviews: [] });
  const blogId = useParams();
  const [like, setLike] = useState(false);
  const navigate = useNavigate();

  const [showShareModal, setShowShareModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleCopyLink = () => {
    setCopied(true);
  };

  useEffect(() => {
    BlogsService.getWebsiteBlogs("", "").then((response) => {
      const filteredBlog = response.response.find(
        (blog) => blog.id == blogId.id
      );

      if (filteredBlog) {
        setBlogData(filteredBlog);
      } else {
        console.log("blog not found!!!");
      }
    });
    // console.log("blogData", blogData);
  }, []);

  //   console.log(blogId.id);

  const handleLike = () => {
    setLike((prevLike) => !prevLike);
    setBlogData((prevBlogData) => {
      const newLikes = !like ? prevBlogData.likes + 1 : prevBlogData.likes - 1;
      return { ...prevBlogData, likes: newLikes };
    });
  };

  const hanldeCommentClick = () => {
    navigate("/register");
  };

  return (
    <div className="blog-details-container">
      <div className="blog-title-image-card">
        <div className="blog-title-card">
          <img src="../images/temp/verified.svg" alt="byte-coder-logo" />
          <h1>{blogData.title}</h1>
          <img src="../images/Logo.svg" alt="byte-coder-logo" />
        </div>
        <div className="blog-img">
          <img src="/images/temp/common.png" alt="../images/temp/default.png" />
        </div>
      </div>

      <div className="blog-data-title">
        <h1>{blogData.title}</h1>
      </div>

      <div className="duration-and-action">
        <div className="date-and-read">
          <p>{blogData.publishedDate}<span> |  6 mins read</span></p>
        </div>

        <div className="like-and-share">
          {/* <i
            class={like ? `fa-regular fa-heart` : `fa-solid fa-heart red`}
            onClick={handleLike}
          ></i>
          <i class="fa-solid fa-share" onClick={handleShareClick}></i> */}

        </div>
      </div>

      {/* here we have the logic for the sharing the blog to others */}
      <Dialog open={showShareModal} onClose={handleCloseShareModal}>
        <DialogTitle>Share this link:</DialogTitle>
        <DialogContent>
          <Input
            value={`https://bytecoder.in/blogs/${blogId.id}`}
            readOnly
            fullWidth
          />
          <CopyToClipboard
            text={`https://bytecoder.in/blogs/${blogId.id}`}
            onCopy={handleCopyLink}
          >
            <Button fullWidth>Copy Link</Button>
          </CopyToClipboard>
          {copied && (
            <p className="copied-message">Link copied to clipboard!</p>
          )}
        </DialogContent>
      </Dialog>

      <div className="blog-data-para">
        {blogData.paragraphs.map((paragraph) => (
          <ReactMarkdown>{paragraph}</ReactMarkdown>
        ))}
      </div>

      <div className="horizontal-line"></div>

      <div className="socials-and-comments-data">
        <div className="socials">
          {/* <i class="fa-brands fa-instagram"></i>
          <a href="https://www.linkedin.com/company/96441539/">
            <FaLinkedinIn />
          </a>
          <i class="fa-brands fa-twitter"></i>
          <i class="fa-brands fa-facebook"></i>
          <i class="fa-brands fa-whatsapp"></i> */}
        </div>

        <div className="view-comments-likes">
          <p>
            {blogData.views} views | {blogData.commentsCount} comments |{" "}
            {blogData.likes}{" "}
            <i
              className={
                !like ? `fa-regular fa-heart` : `fa-solid fa-heart red`
              }
              onClick={handleLike}
            ></i>{" "}
          </p>
        </div>
      </div>

      <div className="horizontal-line"></div>

      {/* Recent Post */}
      {/* <BlogCard  /> */}

      {/* comment section */}
      <div className="comment-heading">
        {/* <div className="comments-and-view">
          <p>Comments ({blogData.commentsCount})</p>
          <Link style={{ color: "#3FA959", textDecoration: "underline" }}>
            view all
          </Link>
        </div> */}

        <CommentSection />
        {/* <div className="write-comment">
          <textarea placeholder="write your comment here..."></textarea>
          <button onClick={hanldeCommentClick} className="add-comment">
            Add comment
          </button>
        </div>

        <div className="horizontal-line comment-hr-line"></div>

        {blogData.reviews.map((comment) => {
          return (
            <div key={comment.id} className="comment-section">
              <div className="name-and-time">
                <h6>{comment.userName}</h6>
                <p>{comment.date}</p>
              </div>
              <p className="comment-para-data">{comment.comment}</p>
            </div>
          );
        })} */}
      </div>

      <div className="horizontal-line  comment-hr-line"></div>
    </div>
  );
};

export default BlogDetailsPage;
