// BookedSession.jsx
import React from "react";
import "./BookedSession.css";
import MentorshipCard from "../MentorshipCard/MentorshipCard";

const BookedSession = ({ mentors }) => {
  console.log(mentors);
  return (
    <div className="booked-session-container">
      {mentors.map((mentor) => (
        <MentorshipCard
          key={mentor.id}
          image={mentor.image}
          mentorshipTime={mentor.mentorshipTime}
          mentorshipMode={mentor.mentorshipMode}
          mentorshipStartTime={mentor.mentorshipStartTime}
          image2={mentor.image2}
          mentorshipSession={mentor.mentorshipSession}
          mentorshipSessionPerson={mentor.mentorshipSessionPerson}
          mentor={mentor}
          showJoinNowButton={false}
        />
      ))}
    </div>
  );
};

export default BookedSession;
