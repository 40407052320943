
import './BookSection.css'
import { ArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import pdf from "./dummy.pdf"
function BookSection() {
  
   const navigate=useNavigate();
  const goBack = () => {
   navigate(-1);
  };


   


  return (
    <div className="book-section-container">
      <div className="books-back" onClick={goBack}>
        <ArrowLeft />
        Back to Books
      </div>
      <iframe src={pdf} frameborder="0" width="80%" height="100%"></iframe>
    </div>
  );
}

export default BookSection