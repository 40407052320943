export const assessments = [
         {
           id: 1,
           name: "Linked lists - C",
           mentor: "Siddharth Taneja",
           type: "MCQ's",
           expiry_date: "28 April 2024",
         },
         {
           id: 2,
           name: "Pointers - C",
           mentor: "Abhishek Gaur",
           type: "MCQ's",
           expiry_date: "10 Feb 2024",
         },
         {
           id: 3,
           name: "Pointers - C",
           mentor: "Abhishek Gaur",
           type: "MCQ's",
           expiry_date: "10 Feb 2024",
         },
         {
           id: 4,
           name: "Pointers - C",
           mentor: "Abhishek Gaur",
           type: "MCQ's",
           expiry_date: "10 Feb 2024",
         },
         {
           id: 5,
           name: "Pointers - C",
           mentor: "Abhishek Gaur",
           type: "MCQ's",
           expiry_date: "10 Feb 2024",
         },
         {
           id: 6,
           name: "Pointers - C",
           mentor: "Abhishek Gaur",
           type: "MCQ's",
           expiry_date: "10 Feb 2024",
         },
       ];

export const quizQuestions = [
         {
           id: 1,
           question:
             "Which of the following algorithm implementations is similar to that of an insertion sort?",
           option1: "Binary Heap",
           option2: "Merge Sort",
           option3: "Radix Sort",
           option4: "Quick Sort",
           answer: "Binary Heap",
         },
         {
           id: 2,
           question:
             "Which of the following algorithm implementations is similar to that of an insertion sort?",
           option1: "Binary Heap",
           option2: "Mergeort",
           option3: "Radix Sort",
           option4: "Quick ort",
           answer: "Binary Heap",
         },
         {
           id: 3,
           question:
             "Which of the following algorithm implementations is similar to that of an insertion sort?",
           option1: "B Heap",
           option2: "Mergrt",
           option3: "Radix Sort",
           option4: "QuiSort",
           answer: "Binar Heap",
         },
         {
           id: 4,
           question:
             "Which of the following algorithm implementations is similar to that of an insertion sort?",
           option1: "Binary",
           option2: "Merge Sort",
           option3: " Sort",
           option4: "Quick Sort",
           answer: " Heap",
         },
       ];