import React, { useState } from "react";
import "./Bytegpt.css";
import {  AutoAwesome, Close, FirstPage, KeyboardArrowDown, KeyboardReturnRounded } from "@mui/icons-material";
function Bytegpt() {
  const [showGpt, setShowGpt] = useState(false);

  const toggleGptView=()=>{
    setShowGpt(!showGpt)
  };
  return (
    <div className={`gpt-container`}>
      {showGpt ? (
        <div className="visibleGpt">
          <div className="gpt-heading-container">
            <div className="gpt-heading">
              <img src="/images/bytegpt.png" alt="ByteGpt" />
              <h2>ByteGpt</h2>
            </div>
            <Close sx={{ cursor: "pointer" }} onClick={toggleGptView}/>
          </div>
          <div className="prompt-input-container">
            <div className="prompt-dropdown">
              <div className="chapter-topic-container">
                <div className="topic-dropdown dropdown">
                  <span>Chapter</span> <KeyboardArrowDown />
                </div>
                <div className="chapter-dropdown dropdown">
                  <span>Topic</span> <KeyboardArrowDown />
                </div>
              </div>
              <ul className="faq">
                <li className="faq-item">
                  <p>
                    <AutoAwesome sx={{ fontSize: "0.8rem" }} /> Show me the
                    problems on “Queues”
                  </p>
                  <KeyboardReturnRounded sx={{ fontSize: "0.8rem" }} />
                </li>
                <li className="faq-item">
                  <p>
                    <AutoAwesome sx={{ fontSize: "0.8rem" }} /> Show me the
                    problems on “Queues”
                  </p>
                  <KeyboardReturnRounded sx={{ fontSize: "0.8rem" }} />
                </li>
                <li className="faq-item">
                  <p>
                    <AutoAwesome sx={{ fontSize: "0.8rem" }} /> Show me the
                    problems on “Queues”
                  </p>
                  <KeyboardReturnRounded sx={{ fontSize: "0.8rem" }} />
                </li>
                <li className="faq-item">
                  <p>
                    <AutoAwesome sx={{ fontSize: "0.8rem" }} /> Show me the
                    problems on “Queues”
                  </p>
                  <KeyboardReturnRounded sx={{ fontSize: "0.8rem" }} />
                </li>
              </ul>
            </div>
            <div className="prompt-input">
              <input type="text" placeholder="Ask anything..." />
            </div>
            <div className="bytegpt-bg">
              <img src="/images/bytegpt-bg.png" alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="bytegpt-button" onClick={toggleGptView}>
            <FirstPage sx={{ color: "green" }} />
            <span className="bytegpt-name">ByteGPT</span>
          </div>
      )}
    </div>
  );
}

export default Bytegpt;
