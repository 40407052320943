import React, { useEffect, useState } from 'react'
import './Questions.css'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FileDownload, FilterList, Visibility } from '@mui/icons-material';
// import { questions } from '../../../service/QuestionPaper';
import QuestionFilter from '../QuestionFilter/QuestionFilter';
import { getAllQuestionsOfSubject } from '../../../APIservice/QP/QuestionPaper';
import { getExamsById } from '../../../APIservice/QP/Exams';
import { useSelector, useDispatch } from "react-redux";
import { addQuestions, updateCurrentQuestion } from "../../../Redux/slices/QPSlice";
import { QuestionDevision } from './QuestionDevision';




const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


function Questions() {
  const { examId } = useParams();

  const query = useQuery();
  const subjectId = query.get('paperId')
  const topicId = query.get('topicId')
  const year = query.get('year')

  const [subjectQuestions, setSubjectQuestions] = useState([]);


  const [exam, setExam] = useState({ title: "" })

  const topic = new URLSearchParams(window.location.search).get("topic");
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const navigate = useNavigate();
  const handleFilterButtonClick = () => {
    setShowFilterPopup(!showFilterPopup); // Toggle the visibility of the filter popup
  };
  const handleBackButtonClick = () => {
    navigate(`/questionpapers/${examId}`)
  }





  const dispatch = useDispatch();


  useEffect(() => {
    getExamsById(examId).then((exam) => setExam(exam))
    // getAllQuestionsOfSubject(examId).then((q)=>setSubjectQuestions(q))

    console.log(query.get('year'))
    console.log(query.get('topicId'))
    if (topicId != null && subjectId == null) {
      console.log("get question: for topic")
      getAllQuestionsOfSubject(examId).then((q) => setSubjectQuestions(q.results))


    } else if (subjectId != null && topicId == null) {

      console.log("get question: for subjectid")
      getAllQuestionsOfSubject(examId).then((q) => setSubjectQuestions(q.results))

    } else {
      console.log("route to subjects")
      // navigate(`/questionpapers/${examId}`)

    }


  }, []);



  useEffect(() => {
    dispatch(addQuestions(subjectQuestions.questions));
    console.log(subjectQuestions.questions)


  }, [subjectQuestions]);

  let { questions,
    currentQuestion,
    currentQuestionIndex } = useSelector((state) => state.qp);

  const openSolution = (id, index) => {
    dispatch(updateCurrentQuestion({ currentQuestion: questions[index - 1], currentQuestionIndex: index - 1 }));
    navigate(`/questionpapers/${examId}/questions/${id}`)

  }

  return (
    <div className="questions-section">


      <div className="paper-container">


        <div className="paper-title">

          <div className="backtoprevious" onClick={handleBackButtonClick}>
            <ArrowBackIcon sx={{ fontSize: "1rem" }} /> Go back
          </div>
          <h3>{exam.title}</h3>
          {/* <h3>subjectQuestions.subject + '  ' + subjectQuestions.year</h3> */}
          <div></div>
          {/* <div>
            <button onClick={handleFilterButtonClick}>
              <FilterList /> Filter
            </button>
            {showFilterPopup && (
              <QuestionFilter onClose={() => setShowFilterPopup(false)} />
            )}
          </div> */}
        </div>


        <div className="questions-container">
          <ul>
            {subjectQuestions?.map((question, indexx) => (
              <QuestionDevision question={question} index={indexx} />

            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Questions