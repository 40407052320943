import React, { useState } from "react";
import { careerTrackCourse } from "../../../../../APIservice/CareerTrackCourses";
import { Link } from "react-router-dom";

const ChapterDetails = ({ onClick }) => {
  return (
    <Link to="/career-tracks/CSE100306/courses/courseCode" style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <div className="course-all-chapters-container">
        {careerTrackCourse.topics.map((topic, index) => (
          <div key={index} className="course-all-chapters-individual-container">
            <div key={index} className="chapter-progress-item">
              <div
                className={`chapter-progress-circle ${
                  index + 1 === topic.topicTitle ? "active" : ""
                }`}
              >
                {index + 1}
              </div>
              {index !== careerTrackCourse.topics.length - 1 && (
                <div
                  className={`chapter-progress-line ${
                    index + 1 === topic.topicTitle ? "active" : ""
                  }`}
                ></div>
              )}
            </div>
            <div className="course-all-chapters-details-container">
              <img src={topic.topicImage} alt={topic.topicTitle} />
              <div className="course-all-chapters-details-text-container">
                <h4>{topic.topicTitle}</h4>
                <p>{topic.topicDescription}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Link>
  );
};

export default ChapterDetails;
