import React, { useEffect, useState } from 'react'
import * as CoursesApi from "../../../../../../APIservice/CoursesApi";
import { useParams } from 'react-router-dom';
import { getChaptersAssignment } from '../../../../../../APIservice/Assignments';
import { JoinButton } from '../../../../../../GenericComponents/Buttons/Buttons'


const CareerCourseHero = () => {
    const [course, setCourse] = useState({ skills: [], learnings: [] });
    const [assignments, setAssignments] = useState([]);
    const params = useParams();
    useEffect(() => {
      CoursesApi.getCourseById(params.courseCode).then((cs) => {
        setCourse(cs[0]);
      });
      getChaptersAssignment(params.courseCode).then((assignments) => {
        setAssignments(assignments);
      });
    }, []);
  return (
    <div className='career-course-hero-container'>
         <div className="title-image-card">
        <div className="title-card">
          <h3>Career Track</h3>
          <h1>{course.title}</h1>
          <p>{course.description}</p>

          <div className="program-container-download-enroll-buttons">
            <div className="program-container-download-button">
              Download Brochure
            </div>
            <div className="join-btn">
              <JoinButton
                courseCode={params.courseCode}
                title={course.title}
                description={course.description}
                duration={course.duration}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerCourseHero