import React from "react";
import QuestionerLeftLayer from "../../LoginPages/MaintainancePages/QuestionLeftLayer/QuestionerLeftLayer.jsx";
import QuestionerRightLayer from "../../../Molecule/QuestionRightLayer/QuestionerRightLayer.jsx";
import "./Questioner.css";

const Questioner = () => {
  return (
    <div className="questioner-container">
      <QuestionerLeftLayer />

      <QuestionerRightLayer />
    </div>
  );
};

export default Questioner;
