export const expertInfo = [
    {
        id:1,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        calenderImage:"/images/solar_calendar-outline.jpg",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Pradeep Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        edit:"/images/Group.png",
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                calendarImage2:"/images/solar_calendar-outline (1).svg",
                timeImage:"/images/mdi_clock-outline.png",
                date:"27 May 2024, Monday",
                timeZone:"10:45AM IST",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Hey there, chemistry enthusiast turned data explorer!",
                    "Here's what we can chat about",
                    "➡️ Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "➡️ Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "➡️ Preparing for interviews by highlighting your problem-solving abilities.",
                    "➡️ Getting tips for finding data jobs that fit your skills and interests."
                ],
                leftArrow:"/images/icon-park_right.jpg",
                rightArrow:"/images/icon-park_left.jpg",
                testimonials: [
                    
                    {
                        authorImage:"/images/Rectangle 40358.svg",
                        authorProfile:"CEO - Sonic Solutions",
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts",
                        emoji:"/images/lllook 1.svg",
                    },
                    {
                        authorImage:"/images/Rectangle 40358.svg",
                        authorProfile:"CEO - Sonic Solutions",
                        text: "Theressa is an outstanding mentor! Her insights and guidance have been instrumental in shaping my career path. Highly recommend!",
                        author: "Emily Johnson",
                        emoji:"/images/lllook 1.svg",
                    },
                    {
                        authorImage:"/images/Rectangle 40358.svg",
                        authorProfile:"CEO - Sonic Solutions",
                        text: "I'm so grateful for the mentorship I received from Theressa. She provided me with practical advice and encouragement that helped me land my dream job.",
                        author: "John Smith",
                        emoji:"/images/lllook 1.svg",
                    },
                    {
                        authorImage:"/images/Rectangle 40358.svg",
                        authorProfile:"CEO - Sonic Solutions",
                        text: "My experience with Theressa was fantastic. She's not only knowledgeable but also very supportive. I gained valuable insights that boosted my confidence.",
                        author: "Sophia Martinez",
                        emoji:"/images/lllook 1.svg",
                    },
                    {
                        authorImage:"/images/Rectangle 40358.svg",
                        authorProfile:"CEO - Sonic Solutions",
                        text: "Theressa truly cares about her mentees' success. She goes above and beyond to provide personalized guidance and resources. Highly recommend her services!",
                        author: "Michael Brown",
                        emoji:"/images/lllook 1.svg",
                    }
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]

    },
    {
        id:2,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Piyush Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg"
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeImage:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:3,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Abhishek Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
                
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:4,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Rohan Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:5,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Naman Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:6,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Aman Jaiswal",
        studentRole: "Chemistry Expert",   about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:7,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Nipun Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:8,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Vedant Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:9,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Visal Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    }, {
        id:10,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Shreya Jaiswal",
        studentRole: "Chemistry Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:11,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Goku Jaiswal",
        studentRole: "Strategy Planners",
        about:"A Strategy Planner is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },

    {
        id:12,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Vegeta Jaiswal",
        studentRole: "Career Advicers",
        about:"A Career Advicer is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },

    {
        id:13,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Broly Jaiswal",
        studentRole: "Mathematics Expert",
        about:"A Mathematics Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:14,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Shivam Jaiswal",
        studentRole: "Mathematics Expert",
        about:"A Mathematics Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:15,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Prem Jaiswal",
        studentRole: "Exam Helpers",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },
    {
        id:16,
        image:"/images/Vector 79.svg",
        image2: "/images/studentimg.png",
        image3: "/images/byte-image.png",
        image4:"/images/tick.png",
        verified:"ByteCode Verified",
        studentDesignation: "STUDENT",
        studentName: "Joseph Jaiswal",
        studentRole: "Physics Expert",
        about:"A Chemistry Expert is a distinguished professional with unparalleled expertise in the intricate realm of matter and its properties. Armed with advanced degrees and a wealth of experience, they possess the acumen to meticulously analyze chemical phenomena, pioneer groundbreaking materials, and tackle diverse scientific and industrial conundrums. Their profound understanding of the molecular world empowers them to unravel complexities, devise innovative solutions, and propel advancements across a spectrum of disciplines. Whether unraveling the mysteries of chemical reactions or spearheading transformative research, a Chemistry Expert stands at the forefront of scientific discovery, driving progress and shaping the future of our world.",
        skills:["Organic Chemistry", "Physical Chemistry", "States of matter", "States of matter", "States of matter"],
        expertMeeting:[  
            {
                buttonName:"1:1",
                buttonHeading:"One to One Mentorship",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1  Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Quick Advice Chat",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },     {
                buttonName:"Quick Chat",
                buttonHeading:"Quick Assistance",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"1:1 Mentorship",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Resume Review",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },  {
                buttonName:"Resume Review",
                buttonHeading:"Resume Building",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
            {
                buttonName:"Mock Interview",
                buttonHeading:"Mock Interview",
                popularButton:"Popular",
                rating:"5",
                ratingImage:"🌟",
                buttonCardName:"Mock Interview",
                aboutMentorship:"Personalized career guidance for you",
                timeIamge:"/images/mdi_clock-outline.png",
                time:"15mins",
                meetingType:"Video Meeting",
                charge:"500", 
                chargeImage:"/images/mdi_currency-inr.jpg",
                chatPoints: [
                    "Making a plan for your dream data job, considering your engineering and chemistry background.",
                    "Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
                    "Preparing for interviews by highlighting your problem-solving abilities.",
                    "Getting tips for finding data jobs that fit your skills and interests."
                ],
                testimonials: [
                    {
                        text: "I recently had a 1:1 session with Theressa Webs, and it was incredibly valuable. She helped me develop a clear roadmap for my career in chemistry...",
                        author: "William Potts"
                    },
                    // Add more testimonials as needed
                ],
                selectionCount: 0
            },
        ]
    },

    


];
