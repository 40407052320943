import React, { useState } from 'react'
import './TandC.scss'
import Quiz from '../Quiz/Quiz';
function TermsandCondition() {
  const [showQuiz,setShowquiz]=useState(false);

  const handleQuizVisibility=()=>{
    setShowquiz(!showQuiz);
  }
  return (
    <>
      {showQuiz ? (
        <Quiz />
      ) : (
        <div className="tandc-container">
          <div className="bac-assessment-header">
            <div className="image-title">
              <img src="/images/bytegpt.png" alt="" />
              <h3>Byte Assessment Center</h3>
            </div>
          </div>

          <div className="about-test-container">
            <div className="topic-assessment">
              <h1>Data Structures </h1>
              <p className="assessment">Linked lists Assessment</p>
            </div>
            <p className="message">
              Before proceeding with your application for this position, please
              complete the eligibility assessment to determine if you meet the
              necessary criteria for this role.
            </p>
            <div className="assessment-instructions">
              <h3>Important Instructions:</h3>
              <ol>
                <li>
                  Read the Instructions Thoroughly: Begin by carefully reading
                  all provided instructions and guidelines. Understand what is
                  expected of you during the assessment
                </li>
                <li>
                  Check Technical Requirements: Ensure your computer, internet
                  connection, and any required software or browser extensions
                  are up to date and functioning properly.
                </li>
              </ol>
              <div className="policy">
                <input type="checkbox" />
                <p>
                  By clicking 'Take Assessment,' you agree to BYTECODE's Terms
                  and Policies.
                </p>
              </div>
            </div>
            <div className="start-test" onClick={handleQuizVisibility}>
              Start Assessment
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TermsandCondition