import React, { useState, useRef } from "react";
import "./PlayVideSection.css";
import { ArrowLeft, ModeComment, Share, ThumbUp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { libraryVideos, categories } from "../../../APIservice/LibraryVideos";
import {
  ChatBubbleOutline,
  PlayArrowOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";

function PlayVideSection() {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [currentBg, setCurrentBg] = useState(false);
  const commentSectionRef = useRef(null);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const playVideoClick = (video_id) => {
    navigate(`/library/videos/${video_id}`);
  };
  const goBack = () => {
    navigate(-1);
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };
  const scrollToCommentSection = () => {
    commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleAddComment = () => {
    // Add new comment to the comments array
    const newComment = {
      text: commentText,
      timestamp: Date.now(),
    };
    setComments([...comments, newComment]);
    // Clear comment input after adding comment
    setCommentText("");
  };

  const getTimeOfComment = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const handleLike = () => {
    setCurrentBg(!currentBg);
  };

  return (
    <div className="play-video-container">
      <div className="video-container">
        <div className="video-back" onClick={goBack}>
          <ArrowLeft />
          Back to Videos
        </div>
        <div className="video-player-section">
          <div className="video-player">
            <video src="/videos/ai.mp4" controls className="video"></video>
          </div>
          <div className="like-share-comment">
            <span
              className={currentBg ? `like video-action-background` : `like`}
              onClick={handleLike}
            >
              <ThumbUp />
              Like
            </span>
            <span className="share">
              <Share />
              Share
            </span>
            <span className="comment" onClick={scrollToCommentSection}>
              <ModeComment />
              Comment
            </span>
          </div>
          <div className="about-video-topic">
            <h3>Introduction to Arrays</h3>
            <p className="topic-info">
              Introduction to ARRAYS" is a comprehensive video tutorial aimed at
              beginners to understand the fundamental concept of arrays in
              programming. In this engaging presentation, viewers will learn the
              definition of arrays and how they function as a structured way to
              store and organize data in computer memory. The video covers basic
              array syntax, initialization, accessing elements, and common
              operations performed on arrays. Through clear explanations and
              illustrative examples, viewers will gain a solid understanding of
              how arrays work and their importance in programming. Whether
              you're new to coding or seeking to strengthen your foundational
              knowledge, "Introduction to ARRAYS" is an essential resource to
              kickstart your journey into the world of programming.
            </p>
          </div>
        </div>
        <div
          className="commentSection video-comment-section"
          ref={commentSectionRef}
        >
          <h3>Comments</h3>
          <div className="comment-container">
            <div className="commentArea video-comment-area">
              <div className="picture">
                <img src="/images/profile_image.png" alt="profile" />
              </div>
              <input
                type="text"
                value={commentText}
                placeholder="Add your comment on this explanation"
                onChange={handleCommentChange}
              />
              <button onClick={handleAddComment}>Comment</button>
            </div>
            {/* Display comments */}
            {comments.map((comment, index) => (
              <div key={index} className="comment-list">
                <div className="profile-name">
                  <div className="picture">
                    <img src="/images/profile_image.png" alt="profile" />
                  </div>
                  <div className="name-comment">
                    <div className="name-timestamp">
                      <p className="name">1</p>
                      <p className="timestamp">
                        {getTimeOfComment(comment.timestamp)}
                      </p>
                    </div>
                    <p>{comment.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="upcoming-videos">
        <h1 style={{marginBottom:"0.8rem", fontSize:"1.6rem"}}>Upcoming Videos</h1>
        <div className="upcoming-videos">
          {libraryVideos.map(
            (videoCategory) =>
              (selectedCategory === "All" ||
                videoCategory.category === selectedCategory) && (
                <div key={videoCategory.id} className="library-video-upcoming">
                  <div className="library-video-upcoming">
                    {videoCategory.videos.slice(0, 6).map((video_info) => (
                      <div
                        key={video_info.id}
                        className="video-card-upcoming-section"
                        onClick={() => playVideoClick(video_info.id)}
                      >
                        <div className="playicon-section">
                          <PlayArrowOutlined
                            sx={{ color: "#fff", fontSize: "4rem" }}
                          />
                        </div>
                       
                          <img className="video-card-image-section" src={video_info.thumbnail_link} alt="" />
                        
                        <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>
                          <div className="video-card-title">
                            <p>{video_info.title}</p>
                          </div>
                          
                          <div className="like-comment like-comment-section">
                            <span>
                              <ThumbUpAltOutlined sx={{ fontSize: "0.8rem" }} />{" "}
                              {video_info.likes} Likes
                            </span>

                            <span>
                              <ChatBubbleOutline sx={{ fontSize: "0.8rem" }} />{" "}
                              {video_info.comment} Comments
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayVideSection;
