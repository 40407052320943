import React, {useEffect, useState} from 'react'

import './SubscriptionPlan.css'
// import { plans } from '../../../service/SubscriptionPlan';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAllPlansOfExamination, getExamsById, subscribeExamPlan } from '../../../../APIservice/QP/Exams';

function SubscriptionPlan() {

  const { examId } = useParams();
  const navigate = useNavigate()
  const [examDetails, setExamDetails] = useState({})
  const [plans, setPlans] = useState([])


  const subscribePlan = (planId)=>{
    const userId = localStorage.getItem("user_id")
    const payload = {
      "exam_id": examId,
      "plan_id": planId,
      "coupon_code": "DISCOUNT20"
    }
    subscribeExamPlan(userId, payload).then(res=>{
      if(res.exam_id != null){
        navigate(`/questionpapers/${examId}/subscription-success  `)
      }
    })
  }

  useEffect(() => {

    getAllPlansOfExamination(examId).then(plans => setPlans(plans))
    getExamsById(examId).then((exam) => setExamDetails(exam))

  }, [])






  return (
    <div className="subscription-container">
      <div className="subcription-heading">
        <h1>{examDetails.title}</h1>
        <p>Subscription Plans</p>
      </div>
      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.id} className="card-container">
            {plan.isPopular && <div className="popular-tag">Popular</div>}

            <div className="content">
              <div className="image">
                <img src={plan.plan_image_url} alt={plan.plan_name} />
              </div>
              <h3>{plan.plan_name}</h3>
              <div className="features">
                {plan.plan_features.map((feature) => (
                  <p key={feature} className="feature">
                    <div className="mark">
                      <span>✓</span>
                    </div>
                    <span>{feature}</span>
                  </p>
                ))}
              </div>
            </div>
            <div className="plan-pricing">
            <hr />

              <h2>
                ₹ {plan.price}
                <span>/{plan.validity_in_days} Days</span>
              </h2>
              <div className="plan-btn" onClick={()=> subscribePlan(plan.id)}>Subscribe </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionPlan