import React, { useEffect, useState } from "react";
import "./QuestionPaper.scss";
import { getAllActiveExamSubscriptions } from "../../APIservice/QP/QuestionPaper";
import { getAllExams } from "../../APIservice/QP/Exams";
import { Link, useNavigate } from "react-router-dom";
import { ExamCard } from "./ExamCard/ExamCard";

function QuestionPaper() {
  const [showAll, setShowAll] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [recommendedSubscriptions, setRecommendedSubscriptions] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);

  let options = {
    page: page,
    limit: limit,
  }


  const navigate = useNavigate()
  let user_id = localStorage.getItem("user_id")

  // useEffect(() => {
  //   if (showAll) {
  //     getAllActiveExamSubscriptions(user_id, 1, 20).then(papers => setSubscriptions(papers['results']))

  //   } else {

  //     getAllActiveExamSubscriptions(user_id, 1, 5).then(papers => setSubscriptions(papers['results']))

  //   }
  // }, [showAll])


  useEffect(() => {

    
    if (filters) {
      options = {
        ...options
      }
    }

    options = {
      ...options,
      page: page,
      limit: limit,
      query: query
    }

    getAllActiveExamSubscriptions(user_id, options).then(papers => setSubscriptions(papers['results']))
    getAllExams(options).then(papers => setRecommendedSubscriptions(papers['result']["results"]))


  }, [])

  useEffect(() => {
    options = {
      ...options,
      page: page,
      limit: limit,
      query: query
    }

    // if (getAllActiveExamSubscriptions(user_id, options).length === 0) {
    //   navigate("/allpapers")
    // }


  }, [subscriptions])



  return (
    <div className="questionPaper-home">
      {subscriptions.length != 0 &&
        <section className="exams-subscribed-container">
          <div className="title-viewAll">
            <h2>My Subscriptions</h2>
            {/* <div onClick={() => setShowAll(!showAll)}>
              {showAll ? "Show Less" : "View All"}
            </div> */}
          </div>

          <div className="subscribed-card-container">
            {console.log(subscriptions)}
            {subscriptions?.map((exam, index) => (
              <ExamCard exam={exam.exam_details} />

            ))}
          </div>
        </section>
      }

      {/*--------- Explore Section ----------*/}
      <section className="explore-section" >
        <div className="title-viewAll">
          <h2>What do you like to explore </h2>
          {/* <Link to="/allpapers">
            <div style={{ fontSize: '1rem' }}>View All</div>
          </Link> */}
        </div>


        <div className="subscribed-card-container">
          {recommendedSubscriptions?.map((exam) => (
            <ExamCard exam={exam} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default QuestionPaper;
