import React, { useState } from "react";
import "./BookingDetails.css";
import { mentorshipData } from "../../../../APIservice/MentorshipCard";
import { expertInfo } from "../../../../APIservice/ExpertCardInfo";
import { ClickableButton } from "../../../../GenericComponents/Buttons/Buttons";
import MeetingDetailsRight from "../../ExpertMeetingDetails/MeetingDetailsRight/MeetingDetailsRight";

const BookingDetails = ({ closeModal }) => {
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);

  const openReschedule = () => {
    setIsRescheduleOpen(true);
  };

  const closeReschedule = () => {
    setIsRescheduleOpen(false);
  };

  return (
    <div className="booking-details-container">
      {!isRescheduleOpen ? (
        <div className="booking-details-body">
          <div className="booking-details-header">
            <h1>Booking Details</h1>
            <div className="modal-close material-icons-outlined close-icon" onClick={closeModal}>
             close
            </div>
          </div>
          <div className="booking-details-header-border-line"></div>
          <div className="booking-details-container-body">
            <div className="booking-details-container-body-mentor-details">
              <h1>{expertInfo[0].expertMeeting[0].buttonCardName}</h1>
              <h2>{expertInfo[0].studentName}</h2>
              <div>
                <h3>Description</h3>
                <ul style={{ width: "70%", paddingLeft:"0" }}>
                  {expertInfo[0].expertMeeting[0].chatPoints.map(
                    (point, index) => (
                      <li className="" key={index}>
                        {point}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="booking-details-container-body-meeting-details">
              <header
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                }}
              >
                <h1>Meeting Details</h1>
                <ClickableButton
                  name="Join Now"
                  backgroundColor="rgba(7, 184, 24, 1)"
                  color="white"
                  height="3rem"
                  width="8rem"
                />
              </header>
              <div className="booking-details-container-body-meeting-date-time-duration-details">
                <p>
                  <span>
                    <img src={expertInfo[0].expertMeeting[0].calendarImage2} />
                  </span>
                  {expertInfo[0].expertMeeting[0].date}
                </p>
                <p>
                  <span>
                    <img src={expertInfo[0].expertMeeting[0].timeImage} />
                  </span>
                  {expertInfo[0].expertMeeting[0].timeZone}
                </p>
                <p>
                  <span>
                    <img src={expertInfo[0].expertMeeting[0].timeImage} />
                  </span>
                  {expertInfo[0].expertMeeting[0].time}
                </p>
              </div>
              <div className="booking-details-container-body-meeting-attendees-details">
                <h3>Attendees</h3>
                <p>
                  1.
                  {expertInfo[0].studentName}
                </p>
              </div>
              <div
                className="booking-details-container-reschedule-button"
                onClick={openReschedule}
              >
                Reschedule
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="booking-details-meeting-details-right"> <MeetingDetailsRight closeReschedule={closeReschedule} forRescheduling={true} closeModal={closeModal}  /></div>
       
      )}
    </div>
  );
};

export default BookingDetails;
