import React from "react";
import { useParams } from "react-router-dom";
import { expertInfo } from "../../../APIservice/ExpertCardInfo"; // Import expertInfo instead of expertMeeting
import "./AboutExpert.css";
import AboutExpertCard from "../AboutExpertCard/AboutExpertCard";
import ExpertMeetings from "../ExpertMeetings/ExpertMeetings";

const AboutExpert = () => {
  const { id } = useParams();
  const expert = expertInfo.find((expert) => expert.id === parseInt(id));

  return (
    <div className="about-expert-container">
     <div className="about-expert-card-container">
        <AboutExpertCard expert={expert} />
     </div>
     <div className="about-expert-mentorships-container">
        <ExpertMeetings expertMeetings={expert.expertMeeting} id={id} />
     </div>
    </div>
  );
};

export default AboutExpert;
