import React, { useState, useEffect, useRef } from "react";
import "./MeetingDetailsRight.css";
import { Link } from "react-router-dom";
import SmallCalendar from "../../../Calendar/SmallCalendar/SmallCalendar";
import MeetingDetailsCalendar from "../MeetingDetailsCalendar/MeetingDetailsCalendar";

const MeetingDetailsRight = ({ forRescheduling, closeModal }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState("IST - 5:30"); // Default time zone
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);

  const handleConfirmDetails = () => {
    const queryParams = new URLSearchParams({
      date: selectedDate,
      time: selectedTime,
      timeZone: selectedTimeZone,
    }).toString();
    window.location.href = `/byteconnect/expertsPage/expert-meeting-details/:id/:meetingIndex/knowyou?${queryParams}`;
  };

  const times = [];
  for (let i = 9; i <= 13; i++) {
    // Adjusted time range from 9am to 1:30pm
    for (let j = 0; j < 60; j += 30) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      const minute = j === 0 ? "00" : `${j}`;
      times.push(`${hour}:${minute} ${i < 12 ? "AM" : "PM"}`);
    }
  }

  const timeZoneOptions = [
    { name: "IST", offset: "+05:30", location: "Kolkata, India" },
    { name: "CET", offset: "+01:00", location: "Central European Time" },
    {
      name: "AEST",
      offset: "+10:00",
      location: "Australian Eastern Standard Time",
    },
    { name: "NZST", offset: "+12:00", location: "New Zealand Standard Time" },
    { name: "HST", offset: "-10:00", location: "Hawaiian Standard Time" },
  ];
 // Get current date
 const currentDate = new Date();

 // Get the day index (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
 const currentDayIndex = currentDate.getDay();

 // Calculate the start date by subtracting the current day index from the current date
 const startDate = new Date(currentDate);
 startDate.setDate(startDate.getDate() - currentDayIndex);

 const calendarRef = useRef(null);

 useEffect(() => {
   const handleClickOutside = (event) => {
     if (calendarRef.current && !calendarRef.current.contains(event.target)) {
       setIsCalendarOpen(false);
     }
   };

   document.addEventListener("mousedown", handleClickOutside);
   return () => {
     document.removeEventListener("mousedown", handleClickOutside);
   };
 }, [calendarRef]);

 
  startDate.setDate(startDate.getDate() - currentDayIndex);

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);
    dates.push(date);
  }

  const toggleCalendar = () => {
    setIsCalendarOpen((prevState) => !prevState);
  };

  const handleDateClick = (date, index) => {
    setSelectedDate(date);
    setSelectedDateIndex(index);
  };

  const handleTimeClick = (time, index) => {
    setSelectedTime(time);
    setSelectedTimeIndex(index);
  };

  const renderCalendar = () => {
    if (isCalendarOpen) {
      return (
        <div className="meeting-calendar">
          <MeetingDetailsCalendar
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="meeting-details-right-container">
      <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        {" "}
        <h1 className="slot-booking-heading">
          {forRescheduling ? "Reschedule" : "Slot Booking"}
        </h1>
        {forRescheduling ? (
          <div className="modal-close material-icons-outlined close-icon" onClick={closeModal}>
            close
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="slot-booking-date-container">
        <div className="slot-booking-date-container-header">
          <h2 className="slot-booking-select-date-heading">Select Date</h2>
          <p onClick={toggleCalendar} className="choose-calendar">
            Choose from calendar
          </p>
        </div>

        {renderCalendar()}
        <div className="date-cards">
          {dates.map((date, index) => (
            <div
              key={index}
              className={`date-card ${
                selectedDateIndex === index ? "selected" : ""
              }`}
              onClick={() => handleDateClick(date, index)}
              style={{
                background:
                  selectedDateIndex === index ? "rgba(197, 179, 213, 1)" : "",
                color: selectedDateIndex === index ? "black" : "",
              }}
            >
              <p className="slot-booking-date-card-day">
                {date.toLocaleDateString("en-US", { weekday: "short" })}
              </p>
              <p className="slot-booking-date-card-date">
                {date.toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                })}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="slot-booking-container">
        <h2 className="slot-booking-pick-a-time-heading">Pick a Time</h2>
        <div className="time-cards">
          {times.map((time, index) => (
            <div
              key={index}
              className={`time-card ${
                selectedTimeIndex === index ? "selected" : ""
              }`}
              onClick={() => handleTimeClick(time, index)}
              style={{
                background:
                  selectedTimeIndex === index ? "rgba(197, 179, 213, 1)" : "",
                color: selectedTimeIndex === index ? "black" : "",
              }}
            >
              <p className="slot-booking-time">{time}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="slot-booking-time-zone-body">
        <h2 className="slot-booking-time-zoner-heading">Time Zone</h2>
        <div className="calendar-header">
          <select
            value={selectedTimeZone}
            onChange={(e) => setSelectedTimeZone(e.target.value)}
            className="timezone-dropdown"
          >
            {timeZoneOptions.map((option) => (
              <option key={option.name} value={option.offset}>
                {option.name}
                {option.offset}
                {option.location}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="meetings-detail-right-confirm-details-button">
        <button className="submit-button" onClick={handleConfirmDetails}>
          Confirm Details
        </button>
      </div>
    </div>
  );
};

export default MeetingDetailsRight;
