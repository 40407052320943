export const appliedJobs = [
    {
      "id": 1,
      "image":"/images/image1.svg",
      "companyName": "Navistar International Corporation",
      "role": "Visual and Experience Designer & Developer",
      "status": "Shortlisted"
    },
    {
      "id": 2,
      "image":"/images/baker.svg",
      "companyName": "Baker Hughes Incorporated",
      "role": "Visual and Experience Designer & Developer",
      "status": "Accepted"
    },
    {
      "id": 3,
      "image":"/images/baker.svg",
      "companyName": "Baker Hughes Incorporated",
      "role": "Visual and Experience Designer & Developer",
      "status": "Rejected"
    },
    {
        "id": 4,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Accepted"
      },
      {
        "id": 5,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Shortlisted"
      },
      {
        "id": 6,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Accepted"
      },
      {
        "id": 7,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Shortlisted"
      },
      {
        "id": 8,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Rejected"
      },
      {
        "id": 9,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Accepted"
      },
      {
        "id": 10,
        "image":"/images/baker.svg",
        "companyName": "Baker Hughes Incorporated",
        "role": "Visual and Experience Designer & Developer",
        "status": "Accepted"
      }
]
  