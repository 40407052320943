
export const mentorshipData = [
    {
        id:1,
        image:"/images/mdi_clock-outline.png",
        image2: "/images/Rectangle 40358.png",
        mentorshipTime:"20min",
        mentorshipMode: "Video Meeting",
        mentorshipStartTime: "Starts in 1:59mins",
        mentorshipSession: "1:1 MentorShip Session",
        mentorshipSessionPerson: "Tejas Shah",

    },
    {
        id:2,
        image:"/images/mdi_clock-outline.png",
        image2: "/images/Rectangle 40358.png",
        mentorshipTime:"20min",
        mentorshipMode: "Video Meeting",
        mentorshipStartTime: "Starts in 1:59mins",
        mentorshipSession: "1:1 MentorShip Session",
        mentorshipSessionPerson: "Tejas Shah",
        
    },
    {
        id:3,
        image:"/images/mdi_clock-outline.png",
        image2: "/images/Rectangle 40358.png",
        mentorshipTime:"20min",
        mentorshipMode: "Video Meeting",
        mentorshipStartTime: "Starts in 1:59mins",
        mentorshipSession: "1:1 MentorShip Session",
        mentorshipSessionPerson: "Tejas Shah",
        
    },
    
 
]