import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./CourseProgramDetailsPage.css";

import * as CoursesApi from "../../../../../APIservice/CoursesApi";
import { getChaptersAssignment } from "../../../../../APIservice/Assignments";
import CourseTopicDetails from "../../../CourseTopicDetails/CourseTopicDetails";
import CareerTrackMentors from "../../../../CareerPath/CareerTrackMentors/CareerTrackMentors";
import UserRating from "../../../UserRating/UserRating";
import CommentSection from "../../../../../GenericComponents/CommentSection/CommentSection";
import NewCourseLearnings from "./NewEnrollmentDiv";
import CareerCourseHero from "../../../../CareerPath/EnrolledCareerTracks/CareerTrackHistory/AllCareerTrackCradsPage/CareerCourseHero/CareerCourseHero";



export default function CourseProgramDetailsPage() {
  const params = useParams();
  const [assignments, setAssignments] = useState([]);

  const [course, setCourse] = useState({ skills: [], learnings: [] });

  const imgaeExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

  useEffect(() => {
    CoursesApi.getCourseById(params.courseCode).then((cs) => {
      setCourse(cs[0]);
    });
    getChaptersAssignment(params.courseCode).then((assignments) => {
      setAssignments(assignments);
    });
  }, []);

  // Check if assignments is loaded before accessing chapters
  const numChapters = assignments.chapters ? assignments.chapters.length : 0;

  console.log("params", params.courseCode);

  return (
    <div className="program-container">
      <CareerCourseHero />
      <div className="course-program-details-page-body">
        <NewCourseLearnings />
        <div className="course-topic-details">
          <CourseTopicDetails />
        </div> 
         <div className="learning-container">
          <div className="learning-box">
            <h4>What you'll learn</h4>
            <div className="learning-list">
              <ul className="details-list">
                {course.learnings.map((line) => (
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <img src="/images/charm_tick.svg" />
                    <li>{line}</li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="course-program-details-mentor-container">
          <CareerTrackMentors />
        </div>
        {/* <div className="course-program-details-certification-container">
          <SampleCertificate />
        </div> */}
        <div className="course-program-details-rating-container">
          {" "}
          <UserRating />
        </div>
        <div className="course-program-details-comment-container">
          {" "}
          <CommentSection />
        </div>
      </div>
    </div>
  );
}
