import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


function CareerCard(props) {
  const navigate = useNavigate();



  const course = props.course;


  return (
    <div
      className="course-container"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "1rem",
          margin: "1rem 0",
          // border:"2px solid"
        }}
        // key={course.id}
        // onClick={() => props.openCourse(course)}
      >
        <div
          className="course-name-details"
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "start",

            gap: "1rem",
            // border: "2px solid",
            // justifyContent:"space-between"
          }}
        >
          <h3
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "16px",
              margin: 0,
            }}
          >
            {course.title}
          </h3>
          <p>{course.description}</p>
          <div className="course-certification">
            <img src="/images/icon-park-outline_certificate.svg" />
           <h1>Certification Available</h1> 
          </div>
          <div className="course-projects-details-container">
            <div className="course-projects-container">
              <img src="/images/image 76.svg" />
              <p>14 Courses and 5 Projects</p>
            </div>
            <div className="course-details-container"  onClick={() => props.openCourse()}>
              View Details
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerCard;
