export const sessionHistory = [
    {
      id: 1,
      sessionMentorshipType: "1:1 Mentorship Session",
      sessionMentorshipMentor: "Tejas Shah",
      clockImage: "/images/mdi_clock-outline.png",
      meetingType: "Video Meeting",
      sessionDate: "20-05-2024",
      sessionDuration: "15mins",
      sessionStartTime: "9:30 PM",
      sessionEndTime: "9:45PM",
      sessionSummary: [
        {
          sessionBookedOn: "10-05-2024",
          sessionAttendees: ["Theressa Web", "Kiranmayi"],
          SessionName: "1:1 Session",
          sessionCallDetails:
            "Career counselling and suggestions to get into which field",
          sessionDescription: [
            "Hey there, chemistry enthusiast turned data explorer!",
            "Here's what we can chat about",
            "➡️ Making a plan for your dream data job, considering your engineering and chemistry background.",
            "➡️ Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
            "➡️ Preparing for interviews by highlighting your problem-solving abilities.",
            "➡️ Getting tips for finding data jobs that fit your skills and interests.",
          ],
          sessionMentorProfile: "/images/Rectangle 40358.png",
        },
      ],
    },
    {
      id: 2,
      sessionMentorshipType: "1:1 Mentorship Session",
      sessionMentorshipMentor: "Tejas Shah",
      clockImage: "/images/mdi_clock-outline.png",
      meetingType: "Video Meeting",
      sessionDate: "20-05-2024",
      sessionDuration: "15mins",
      sessionStartTime: "9:30 PM",
      sessionEndTime: "9:45PM",
      sessionSummary: [
        {
          sessionBookedOn: "10-05-2024",
          sessionAttendees: ["Theressa Web", "Kiranmayi"],
          SessionName: "1:1 Session",
          sessionCallDetails:
            "Career counselling and suggestions to get into which field",
          sessionDescription: [
            "Hey there, chemistry enthusiast turned data explorer!",
            "Here's what we can chat about",
            "➡️ Making a plan for your dream data job, considering your engineering and chemistry background.",
            "➡️ Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
            "➡️ Preparing for interviews by highlighting your problem-solving abilities.",
            "➡️ Getting tips for finding data jobs that fit your skills and interests.",
          ],
          sessionMentorProfile: "/images/Rectangle 40358.png",
        },
      ],
    },
    {
      id: 3,
      sessionMentorshipType: "1:1 Mentorship Session",
      sessionMentorshipMentor: "Tejas Shah",
      clockImage: "/images/mdi_clock-outline.png",
      meetingType: "Video Meeting",
      sessionDate: "20-05-2024",
      sessionDuration: "15mins",
      sessionStartTime: "9:30 PM",
      sessionEndTime: "9:45PM",
      sessionSummary: [
        {
          sessionBookedOn: "10-05-2024",
          sessionAttendees: ["Theressa Web", "Kiranmayi"],
          SessionName: "1:1 Session",
          sessionCallDetails:
            "Career counselling and suggestions to get into which field",
          sessionDescription: [
            "Hey there, chemistry enthusiast turned data explorer!",
            "Here's what we can chat about",
            "➡️ Making a plan for your dream data job, considering your engineering and chemistry background.",
            "➡️ Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
            "➡️ Preparing for interviews by highlighting your problem-solving abilities.",
            "➡️ Getting tips for finding data jobs that fit your skills and interests.",
          ],
          sessionMentorProfile: "/images/Rectangle 40358.png",
        },
      ],
    },
    {
      id: 4,
      sessionMentorshipType: "1:1 Mentorship Session",
      sessionMentorshipMentor: "Tejas Shah",
      clockImage: "/images/mdi_clock-outline.png",
      meetingType: "Video Meeting",
      sessionDate: "20-05-2024",
      sessionDuration: "15mins",
      sessionStartTime: "9:30 PM",
      sessionEndTime: "9:45PM",
      sessionSummary: [
        {
          sessionBookedOn: "10-05-2024",
          sessionAttendees: ["Theressa Web", "Kiranmayi"],
          SessionName: "1:1 Session",
          sessionCallDetails:
            "Career counselling and suggestions to get into which field",
          sessionDescription: [
            "Hey there, chemistry enthusiast turned data explorer!",
            "Here's what we can chat about",
            "➡️ Making a plan for your dream data job, considering your engineering and chemistry background.",
            "➡️ Reviewing your resume, LinkedIn, and work samples to show off your unique skills.",
            "➡️ Preparing for interviews by highlighting your problem-solving abilities.",
            "➡️ Getting tips for finding data jobs that fit your skills and interests.",
          ],
          sessionMentorProfile: "/images/Rectangle 40358.png",
        },
      ],
    },
  ];
  
