import React, { useState, useEffect } from 'react'
import './AllExams.scss'
import { Search, FilterList } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { getAllExams } from '../../../APIservice/QP/Exams'
import { ExamCard } from '../ExamCard/ExamCard'

function AllExams() {
  const [ exams, setAllExams] = useState([])
  const [filterTag, setFilterTag] = useState("")

  useEffect(() => {

    getAllExams(filterTag, 1, 20).then(papers => setAllExams(papers['results']))

  }, [])

  const selectExam = (exam) => {
    console.log(exam)
    if (filterTag == exam) {
      setFilterTag("")
    } else {
      setFilterTag(exam)
    }
  }

  useEffect(() => {

    getAllExams(filterTag, 1, 20).then(papers => setAllExams(papers['results']))

  }, [filterTag])



  return (
    <div className="questionpaper-container">
      <section className="search-content-section">
        <h1>Hello! How can we help you?</h1>
        {/* <div className="input-filter">
          <div className="input-field">
            <Search
              sx={{
                position: "absolute",
                top: "24%",
                left: "2%",
                opacity: "0.5",
              }}
            />
            <input
              type="text"
              name=""
              placeholder="Search by year,subject or exam"
            />
          </div>
          <div className="filter">
            <FilterList />
            Filter
          </div>
        </div> */}

        <div className="popular-searches">
          {["IIT", "NEET"].map((exam) => (
            <div key={exam} onClick={() => selectExam(exam)} style={exam == filterTag ? { backgroundColor: 'green' } : { backgroundColor: '#c5b3d5' }}>{exam}</div>
          ))}
        </div>
      </section>
      <section className="exam-card-container">
        {exams.map((exam, index) => (
          <ExamCard index={index} exam={exam} />
        ))}
      </section>
    </div>
  );
}

export default AllExams