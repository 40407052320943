
import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { Visibility } from '@mui/icons-material';

import { addQuestions, updateCurrentQuestion } from "../../../Redux/slices/QPSlice";
import { useSelector, useDispatch } from "react-redux";
import RichTextEditor from '../../../GenericComponents/TextEditor/RichTextEditor';

export function QuestionDevision(props) {
  const { examId } = useParams();

  const question = props.question
  const index = props.index

  const subjectColorCode = ["#FFD6B0", "#E3DAEB", "#DAF3FA", "#B0E4DD"];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { questions,
    currentQuestion,
    currentQuestionIndex } = useSelector((state) => state.qp);
  const openSolution = (id) => {
    // dispatch(updateCurrentQuestion({currentQuestion: questions[index], currentQuestionIndex: index }));
    navigate(`/questionpapers/${examId}/questions/${id}`)

  }

  return (
    <li key={question.id} className="question-item">
      <div style={{ width: "70%" }}>
        <div className="tags-container">
          {question.related_tags?.map((tag, index) => (
            <span
              style={{
                background:
                  subjectColorCode[index % subjectColorCode.length],
              }}
            >
              {tag.name}
            </span>
          ))}
        </div>
        <div className="questions-repeat">
          <RichTextEditor content={question.question_text} preview={false}  ></RichTextEditor>
        </div>

      </div>
      <div className="solution">
        <span>{question.related_tags ? question.related_tags.length : 1} times</span>

        <button onClick={() => openSolution(question.id)}>
          <Visibility sx={{ fontSize: "1rem" }} />
          View Solution
        </button>
      </div>
    </li>
  )
}