import { createSlice } from "@reduxjs/toolkit";

const initialState={
    items:[],
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.items.push(action.payload); // Add item to cart
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id); // Remove item from cart
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.items.find((item) => item.id === id);
      if (item) {
        item.quantity = quantity; // Update item quantity
      }
    },
  },
});

export const {addToCart,removeFromCart,updateQuantity}=cartSlice.actions;
export default cartSlice.reducer;