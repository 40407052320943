export const ratingsData = [
  {
    averageRating: 4.5,
    ratings: [
      { rating: 5, count: 10 },
      { rating: 4, count: 20 },
      { rating: 3, count: 20 },
      { rating: 2, count: 10 },
      { rating: 1, count: 5 },
    ],
    userReviews: [
      {
        image: "/images/user-rating-image.svg",
        name: "John Doe",
        rating: 5,
        comment:
          "Courses like this are rare gems! I entered with little knowledge but left feeling confident in my ability to tackle real-world data engineering challenges. Highly recommended!",
      },
      {
        image: "/images/user-rating-image.svg",
        name: "Alice Smith",
        rating: 4,
        comment: "Courses like this are rare gems! I entered with little knowledge but left feeling confident in my ability to tackle real-world data engineering challenges. Highly recommended! ",
      },
      // {
      //   image: "/images/user-rating-image.svg",
      //   name: "Bob Johnson",
      //   rating: 3,
      //   comment: "Courses like this are rare gems! I entered with little knowledge but left feeling confident in my ability to tackle real-world data engineering challenges. Highly recommended! ",
      // },
      // {
      //   image: "/images/user-rating-image.svg",
      //   name: "Emily Brown",
      //   rating: 2,
      //   comment: "Courses like this are rare gems! I entered with little knowledge but left feeling confident in my ability to tackle real-world data engineering challenges. Highly recommended! ",
      // },
      // {
      //   image: "/images/user-rating-image.svg",
      //   name: "Chris Wilson",
      //   rating: 1,
      //   comment: "Courses like this are rare gems! I entered with little knowledge but left feeling confident in my ability to tackle real-world data engineering challenges. Highly recommended! ",
      // },
    ],
  },
];
