import React, { useState } from "react";
import "./LocationSelector.css";
import { Close } from "@mui/icons-material";

const LocationSelector = ({ handleHidePopup }) => {
  const [pincode, setPincode] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState(null);

  const handleCheckDelivery = () => {
    // function to check delivery availability based on the pincode
    const isDeliveryAvailable = checkDeliveryAvailability(pincode);
    setDeliveryStatus(isDeliveryAvailable);
  };

  const checkDeliveryAvailability = (pincode) => {
    //function to check delivery availability
    return pincode === "515526";
  };

  const getDeliveryDate = () => {
    //  function to calculate delivery date
    const today = new Date();
    const deliveryDate = new Date(today);
    deliveryDate.setDate(today.getDate() + 3);
    return deliveryDate.toLocaleDateString();
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="location-header" style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"1px  rgba(197, 179, 213, 1)"}}>
          {" "}
          <h2>Choose your location</h2>
          <span className="close" onClick={handleHidePopup} style={{cursor:"pointer"}}>
            <Close />
          </span>
        </div>
        
        <label htmlFor="pincode">Enter any India pincode:</label>
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <input
          type="text"
          id="pincode"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          placeholder="Enter pincode"
        />
        <button onClick={handleCheckDelivery}>Check</button>
        </div>
        {deliveryStatus !== null && (
          <div className="delivery-status">
            {deliveryStatus ? (
              <p style={{ color: "green" }}>Get it by {getDeliveryDate()}</p>
            ) : (
              <p style={{ color: "red" }}>
                Delivery Not available to your location
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSelector;
