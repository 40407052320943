import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../../../GenericComponents/ProgressBar/ProgressBar";
import * as JobApiService from "../../../APIservice/JobsApiService";
import "./JobCard.css";
const JobCard = (props) => {
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [savedJobs, setSavedJobs] = useState([]);
  const [job, setJob] = useState(props.job);

  const handleApply = (code) => {
    navigate(`/job-details/${code}`);
  };

  const handleSaveJob = (jobID) => {
    console.log("save the job " + jobID);
    setJob({
      ...job,
      status: job.status === "" ? "Saved" : "",
    });

    let jobDto = {
      code: job.code,
      companyCode: job.companyCode,
      uniqueUserId: localStorage.getItem("uniqueUserId"),
      status: job.status === "Saved" ? "Unsave" : "Save",
    };
    console.log(jobDto.status);
    JobApiService.saveOrApplyJob(jobDto);
  };
  const handleSaveJobs = () => {
    setStatus(status === "" ? "Saved" : "");
  };

  return (
    <div className="single-job-container">
      <div className="single-job-about-container">
        <div
          className="job-logo-and-company"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="job-logo">
            <img src={job.companyLogoUrl} alt="" />
          </div>

          <div
            className="saved-jobs"
            style={{ color: status == "Saved" ? "green" : "black" }}
            onClick={handleSaveJobs}
          >
            <i className="fa-regular fa-bookmark"></i>
          </div>
        </div>
        <div>
          <h5 className="jobCmpnyName" style={{ margin: 0 }}>
            {job.companyName}
          </h5>
          <h5 className="jobTitle" style={{ margin: 0 }}>
            {job.title}
          </h5>
        </div>
        <div className="job-tags">
          {job.jobType.map((tag) => {
            return (
              <div className="job-tags-container">
                <h4>{tag}</h4>
                <h4>2+ years</h4>
                <h4>UG</h4>
              </div>
            );
          })}
        </div>
      </div>

      <div className="job-card-btn ">
        <div className="job-card-location-salary-container">
          <h3 className="job-card-salary-text">4.5 LPA</h3>
          <p className="job-card-location-text">Banglore, India</p>
        </div>
        <div className="apply-job-btn" onClick={() => handleApply(job.code)}>
          Details
        </div>
        {/* <div className="save-job-btn" onClick={() => handleSaveJob(job.jobID)}>
          {job.status === "Saved" ? "Saved Job" : "Save Job"}
        </div> */}
      </div>
      {/* <div style={{ display: "flex" }}>
      
        <ProgressBar filled={job.matchRate} />
      </div> */}
    </div>
  );
};

export default JobCard;
