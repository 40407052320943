import React from 'react';
import { PracticeChallenge,MultiChoice, ChatQuestion, Upload, SingleChoice, CodeQuestion } from '../FormsField/FormsFields';



export default function CustomFormField(props) {
  let question = props.question
  console.log("question")
  console.log(question)
  switch (question.type) {
    case 'multi': {
      return (<MultiChoice question={question} />);
    }
    case 'text': {
      return (<CodeQuestion question={question} />);
    }
    case 'upload': {
      return (<Upload question={question} />);
    }
    case 'single': {
      return (<SingleChoice question={question} />);
    }
    case 'code': {
      return (<CodeQuestion question={question} />);
    }
    case 'Chat': {
      return (<ChatQuestion question={question} />);
    } 
    case 'PracticeChallenge': {
      return (<PracticeChallenge question={question} />);
    }

  }
}
