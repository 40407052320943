import React, { useEffect, useState, useRef } from "react";
import './SearchJob.css'


const SearchJob = () => {
  const [searchJob, setSearchJob] = useState("");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectJob = (job) => {
    setSelectedJob(job);
    setSearchJob(job);
    setIsSearchClicked(false);
  };
  const handleSearchClick = () => {
    setIsSearchClicked(true);
  };

  const setSearchJobHandler = (e) => {
    setSearchJob(e.target.value);
    console.log(searchJob);
  };

  return (
    <div>
      {" "}
      <div
        className="jobs-container-best-jobs-await-search-bar"
        ref={searchRef}
      >
        <div className="job-search" onClick={handleSearchClick}>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            value={searchJob}
            onChange={setSearchJobHandler}
            placeholder="Search For Jobs"
          />
        </div>
      </div>
      {/* {isSearchClicked && (
        <div className="job-page-search-results">
          <p className="job-page-search-results-heading">For you!</p>
          <ul className="job-search-result-content">
            <li onClick={() => handleSelectJob("UI/UX Designer")}>
              <i className="fa-solid fa-magnifying-glass"></i> UI/UX Designer
            </li>
            <li onClick={() => handleSelectJob("SQL Developer")}>
              <i className="fa-solid fa-magnifying-glass"></i> SQL Developer
            </li>
            <li
              onClick={() =>
                handleSelectJob("Partner Reinsurance Europe SE, Dublin")
              }
            >
              <i className="fa-solid fa-magnifying-glass"></i> Partner
              Reinsurance Europe SE, Dublin
            </li>
            <li onClick={() => handleSelectJob("Database Administrator")}>
              <i className="fa-solid fa-magnifying-glass"></i>Database
              Administrator
            </li>
            <li
              onClick={() => handleSelectJob("S&P Credit Mkt SVCS Eurpoe Ltd")}
            >
              <i className="fa-solid fa-magnifying-glass"></i>S&P Credit Mkt
              SVCS Eurpoe Ltd
            </li>
            <li onClick={() => handleSelectJob("Data scientist")}>
              <i className="fa-solid fa-magnifying-glass"></i> Data scientist
            </li>
          </ul>
        </div>
      )} */}
    </div>
  );
};

export default SearchJob;
