import React, { useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import parse from 'html-react-parser';

import './RichTextEditor.scss';

Quill.register('modules/imageResize', ImageResize);


const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];

const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ 'color': [] }, { 'background': [] }],

      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],

      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
    
  };
  
  

const RichTextEditor = (props) => {



  return (
    <div className='text-editor'>
    {
        props.preview ? 
      
      <div className='editor'>
        <h3>Editor</h3>
        <ReactQuill value={props.content} onChange={props.setContent}
        modules={modules} 
        formats={formats}
        placeholder={props.placeholder}
        />
      </div>
 
    :

      <div className='Preview'>
        {/* <h3>Preview:</h3> */}
        {parse(props.content)}
      </div>
    }

    </div>
  );
};



export default RichTextEditor;
