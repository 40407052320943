// MediumLikeComponent.js
import React, { useEffect, useState } from "react";
import * as BlogsService from "../../../APIservice/BlogsApi";
import SearchAndPopularTags from "../../../GenericComponents/SearchAndPopularTags/SerachAndPopularTags";
import { BlogCard } from "../BlogsCard/BlogsCard";
import "./BlogsPage.css";
import { useNavigate } from "react-router-dom";
import BlogsSearch from "../BlogsSearch/BlogsSearch";
import BlogsFilter from "../BlogsFilter/BlogsFilter";

export default function BlogComponent() {
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [populerShortcuts, setpopulerShortcuts] = useState([]);
  const [interest, setInterest] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    BlogsService.getWebsiteBlogs("", "").then((response) => {
      setBlogs(response.response);
      console.log(response.response);
    });
    BlogsService.getPopulerShortcuts().then((shortcuts) =>
      setpopulerShortcuts(shortcuts)
    );
  }, []);

  useEffect(() => {
    BlogsService.getWebsiteBlogs("", interest).then((response) =>
      setBlogs(response.response)
    );
  }, [interest]);

  const searchBlogs = (e) => {
    e.preventDefault();
    console.log(searchText);
    BlogsService.getWebsiteBlogs(searchText, interest).then((response) =>
      setBlogs(response.response)
    );
  };

  const handleClick = (id) => {
    navigate(`/blogs/${id}`);
  };

  return (
    <div className="blog-area">
      <div className="blog-area-header">
        <div className="blog-area-header-details-container">
          <h1>Discover the Latest Trends and Insights</h1>
          <p>
            Welcome to our blog! Get ready to explore interesting topics, tips,
            and stories that matter. Stay informed and inspired with us!
          </p>
        </div>
        <div className="blog-area-header-search-filter-container">
          <div>
            <BlogsSearch />
          </div>
          <div>
            <BlogsFilter />
          </div>
        </div>
      </div>
      {console.log(
        "blogs --> ",
        blogs.slice(0, 1).map((blog) => blog.title)
      )}
      <div className="blog-recommend-container">
        {blogs.slice(0, 2).map((blog) => (
          <div
            className="blog-recommend"
            key={blog.id}
            onClick={() => handleClick(blog.id)}
          >
            <div className="dash-blog-img">
              <img src={blog.imageUrl} alt="" />
            </div>

            <div className="dash-details-and-new">
              <div className="dash-blog-container">
                <div className="dash-blog-detail">
                  <h2>{blog.title}</h2>
                  <p style={{ textAlign: "start" }}>{blog.description}</p>
                  <div className="dash-authot-details">
                    <div className="dash-author-img">
                      <img src="" alt="" />
                    </div>
                  </div>

                  <div className="dash-new-show">
                    
                    <h2><span><img src="/images/playy.svg" /></span>Read Now</h2>
                  </div>
                </div>
              </div>
              ;
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div className="blog-cards-container">
          {blogs.map((blog) => (
            <BlogCard blog={blog} />
          ))}
        </div>
      </div>
    </div>
  );
}
