import React, { useContext, useEffect, useState } from "react";
import SmallCalendar from "../../../Calendar/SmallCalendar/SmallCalendar";
import "./MeetingDetailsCalendar.scss"; // Changed to SCSS for nesting
import dayjs from "dayjs";
import GlobalContext from "../../../../context/GlobalContext";
import { getMonth } from "../../../Calendar/util/util";

const MeetingDetailsCalendar = () => {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  const {
    monthIndex,
    setSmallCalendarMonth,
    setDaySelected,
    daySelected,
  } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }

  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  function handleDateClick(day) {
    const format = "YYYY-MM-DD"; // Use a standard format
    const clickedDay = day.format(format);
    let updatedDates = [...selectedDates];

    if (updatedDates.includes(clickedDay)) {
      updatedDates = updatedDates.filter((date) => date !== clickedDay);
    } else {
      updatedDates.push(clickedDay);
      updatedDates.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
      if (updatedDates.length > 4) {
        updatedDates = updatedDates.slice(-4);
      }
    }

    setSelectedDates(updatedDates);
  }

  function getDayClass(day) {
    const format = "YYYY-MM-DD"; // Use a standard format
    const currDay = day.format(format);
    const nowDay = dayjs().format(format);
    if (selectedDates.includes(currDay)) {
      return "selected-day";
    }
    if (nowDay === currDay) {
      return "current-day";
    }
    return "";
  }

  const firstSelectedDate = selectedDates[0]
    ? dayjs(selectedDates[0]).format("DD MMM YYYY")
    : null;
  const lastSelectedDate = selectedDates[selectedDates.length - 1]
    ? dayjs(selectedDates[selectedDates.length - 1]).format("DD MMM YYYY")
    : null;

  return (
    <div className="meeting-details-calendar">
      <div className="meeting-details-calendar-body">
      <div className="meeting-details-calendar-filter">
        <div className="meeting-details-calendar-filter-header">
          {firstSelectedDate && lastSelectedDate && (
            <div className="meeting-details-calendar-filter-header-container">
              <div className="meeting-details-calendar-filter-header-date">
                <img src="/images/solar_calendar-outline.svg" />
                {firstSelectedDate}
              </div>
              <div className="meeting-details-calendar-filter-header-date">
                <img src="/images/solar_calendar-outline.svg" />
                {lastSelectedDate}
              </div>
            </div>
          )}
        </div>
        <div className="meeting-details-calendar-filter-body">
          <h1>Filters</h1>
          <div className="meeting-details-calendar-filter-body-buttons">
            <button>Today</button>
            <button>Yesterday</button>
            <button>This Week</button>
            <button>Last Week</button>
            <button>This Month</button>
          </div>
        </div>
      </div>
      <div>
        <div className="small-calendar">
          <header className="small-calendar-header">
            <p className="small-calendar-title">
              {dayjs(new Date(dayjs().year(), currentMonthIdx)).format(
                "MMMM YYYY"
              )}
            </p>
            <div className="small-calendar-navigation-buttons">
              <button
                onClick={handlePrevMonth}
                className="small-calendar-nav-button"
              >
                <span className="material-icons-outlined small-calendar-chevron-left">
                  chevron_left
                </span>
              </button>
              <button onClick={handleNextMonth}>
                <span className="material-icons-outlined small-calendar-chevron-right">
                  chevron_right
                </span>
              </button>
            </div>
          </header>
          <div className="small-calendar-days-grid">
            {currentMonth[0].map((day, i) => (
              <span key={i} className="small-calendar-day-label">
                {day.format("dd").charAt(0)}
              </span>
            ))}
            {currentMonth.map((row, i) => (
              <React.Fragment key={i}>
                {row.map((day, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      setSmallCalendarMonth(currentMonthIdx);
                      setDaySelected(day);
                      handleDateClick(day);
                    }}
                    className={`small-calendar-day-button ${getDayClass(day)}`}
                  >
                    <span className="small-calendar-day-number">
                      {day.format("D")}
                    </span>
                  </button>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      </div>
    
    </div>
  );
};

export default MeetingDetailsCalendar;
