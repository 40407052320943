// UserRating.js
import React from "react";
import "./UserRating.css";
import { ratingsData } from "../../../APIservice/UserRating";

const UserRating = () => {
  const { ratings, userReviews } = ratingsData[0];

  // Calculate the total count of ratings
  const totalCount = ratings.reduce((acc, curr) => acc + curr.count, 0);

  // Calculate the average rating
  const averageRating = ratings
    .reduce((acc, curr) => {
      return acc + curr.rating * (curr.count / totalCount);
    }, 0)
    .toFixed(1);

  // Function to render star images based on the rating value
  const renderStars = (rating) => {
    const stars = [];

    // Add full star images based on the rating
    for (let i = 0; i < Math.floor(rating); i++) {
      stars.push(<img key={i} src="/images/noto_star.svg" alt="full star" />);
    }

    // Add half star image if rating is not a whole number
    if (rating % 1 !== 0) {
      stars.push(
        <img key="half" src="/images/half-star.svg" alt="half star" />
      );
    }

    // Add empty star images to complete 5 stars
    for (let i = stars.length; i < 5; i++) {
      stars.push(<img key={i} src="/images/half-star.svg" alt="empty star" />);
    }

    return <div className="star-rating">{stars}</div>;
  };

  return (
    <div className="rating-reviews-container">
      <div className="rating-container">
        <div
          className="average-rating"
          style={{ display: "flex", alignItems: "center", gap: "1.4rem" }}
        >
          <h4>{averageRating}*</h4>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {renderStars(averageRating)}
            <p>From {totalCount} Reviews</p>
          </div>
        </div>
        <div className="ratings">
          {ratings.map((item, index) => (
            <div key={index} className="rating-item">
              <h1> {item.rating}.0</h1>
              <div className="rating-bar">
                <div
                  className="rating-bar-fill"
                  style={{ width: `${(item.count / totalCount) * 100}%` }}
                ></div>
              </div>
              <h1 className="rating-count">{item.count}</h1>
            </div>
          ))}
        </div>
      </div>

      <div className="user-reviews-container">
        <h1>Hear from our learners</h1>
        <div className="user-reviews-all-reviews-container">
        {userReviews.map((review, index) => (
          <div key={index} className="review-item">
              <img src={review.image} />
              <div className="review-comment">{review.comment}</div>
            <div className="user-name">~{review.name}</div>
          </div>
        ))}
        </div>
     
      </div>
    </div>
  );
};

export default UserRating;
