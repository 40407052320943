import React, { useEffect, useState } from 'react'

import './SubscriptionDetails.css'
// import { plans } from '../../../service/SubscriptionPlan';
import { Link, useParams } from 'react-router-dom';
import { getSubscriptionsHistoryOfExam } from '../../../../APIservice/QP/QuestionPaper';
import { getExamsById } from '../../../../APIservice/QP/Exams';

function SubscriptionDetails() {

  const { examId } = useParams();

  const [examDetails, setExamDetails] = useState({})
  const [subscriptions, setSubscriptions] = useState([])


  useEffect(() => {

    getSubscriptionsHistoryOfExam(examId, false).then(object => {
      setSubscriptions(object.results)
      getSubscriptionsHistoryOfExam(examId, true).then(object => setSubscriptions([...subscriptions, ...object.results]))
    })
    getExamsById(examId).then((exam) => setExamDetails(exam))

  }, [])



  return (
    <div className="subscription-container">
      <div className="subcription-heading">
        <h1>{examDetails.exam_name}</h1>
        <p>Subscription Plans</p>
      </div>
      <div className="plans-container">
        {subscriptions.map((subscription) => (
          <div key={subscription.plan.id} className="card-container">
            {subscription.plan.isPopular && <div className="popular-tag">Popular</div>}

            <div className="content">
              <div className="image">
                <img src={subscription.plan.plan_image_url} alt={subscription.plan.plan_name} />
              </div>
              <h3>{subscription.plan.plan_name}</h3>
              <div className="features">
                {subscription.plan.plan_features.map((feature) => (
                  <p key={feature} className="feature">
                    <div className="mark">
                      <span>✓</span>
                    </div>
                    <span>{feature}</span>
                  </p>
                ))}
              </div>
            </div>
            <hr />
            <div className="plan-pricing">
              <h2>
                ₹ {subscription.plan.price}
                <span>/{subscription.plan.validity_in_days} months</span>
              </h2>
            </div>
            {
              !subscription.is_active ?
                <div className="expired-plan-btn" >expired on {subscription.end_date}</div>

                :
                <div className="plan-btn">expires on {subscription.end_date}</div>

            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionDetails