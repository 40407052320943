import React, { useState, useEffect } from "react";
import "./CommentSections.css";

const CommentSection = () => {
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };
  useEffect(() => {
    const storedComments = JSON.parse(localStorage.getItem("comments"));
    if (storedComments) {
      setComments(storedComments);
    }
  }, []);
  const handleAddComment = () => {
    // Add new comment to the comments array
    const newComment = {
      text: commentText,
      timestamp: Date.now(),
    };
    setComments([...comments, newComment]);
    // Clear comment input after adding comment
    setCommentText("");
    const updatedComments = [...comments, newComment];
    setComments(updatedComments);
    // Save comments to localStorage
    localStorage.setItem("comments", JSON.stringify(updatedComments));
    // Clear comment input after adding comment
    setCommentText("");
  };

  const getTimeOfComment = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };
  return (
    <div className="comment-Section">
      {/* <h3>Comments</h3> */}
      <div className="commentSection-comment-container">
        <div className="commentSection-commentArea">
          <div className="commentSection-picture">
            <img src="/images/user-rating-image.svg" alt="profile" />
          </div>
          <input
            type="text"
            value={commentText}
            placeholder="Add your comment on this explanation"
            onChange={handleCommentChange}
          />
          <button onClick={handleAddComment}>Comment</button>
        </div>
        {/* Display comments */}
        {comments.map((comment, index) => (
          <div key={index} className="commentSection-comment-list">
            <div className="commentSection-profile-name">
              <div className="commentSection-picture">
                <img src="/images/user-rating-image.svg" alt="profile" />
              </div>
              <div className="commentSection-name-comment">
                <div>
                  <h4>{comment.text}</h4>
                  <p>Henry Cavil</p>    
                </div>   
              </div>
              
            </div>
            <div className="commentSectionname-timestamp">
                  <p className="commentSectiontimestamp">
                    {getTimeOfComment(comment.timestamp)}
                  </p>
                </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
