
import './Score.scss'
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos, Download, Star, StarBorder } from "@mui/icons-material";
import { useState } from 'react';
function Score({ score, quizQuestions }) {
  const navigate = useNavigate();

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString();

  const handleBack = () => {
    navigate("/dashboard");
  };

  const [rating, setRating] = useState(0);

  const handleStarClick = (starNumber) => {
    setRating(starNumber);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        rating >= i ? (
          <Star
            key={i}
            className="star-active"
            onClick={() => handleStarClick(i)}
            sx={{ fontSize: "2rem" }}
          />
        ) : (
          <StarBorder
            key={i}
            className="star-inactive"
            onClick={() => handleStarClick(i)}
            sx={{ fontSize: "2rem" }}
          />
        )
      );
    }
    return stars;
  };


  return (
    <div className="score-container">
      <div className="bac-assessment-header">
        <div className="image-title">
          <img src="/images/bytegpt.png" alt="" />
          <h3>Byte Assessment Center</h3>
        </div>
      </div>
      <div className="score-content">
        <div className="back" onClick={handleBack}>
          <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
          Back to DashBoard
        </div>

        <div className="assessment-name-report">
          <div className="name-date">
            <div className="name">
              <h1>Data Structures</h1>
              <span>Linked Lists Assessment</span>
            </div>
            <div className="date">
              <span>{formattedDate}</span>
            </div>
          </div>
          <div className="report">
            <Download /> Download Report
          </div>
        </div>

        <table className="assessment-table">
          <thead>
            <tr>
              <th>Section</th>
              <th>Obtained Marks</th>
              <th>Total Marks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Section 1</td>
              <td>80</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Section 2</td>
              <td>80</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Section 3</td>
              <td>80</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Section 4</td>
              <td>80</td>
              <td>100</td>
            </tr>
          </tbody>
        </table>

        <div className="score">
          <p>You scored:</p>
          <span>
            {score}/{quizQuestions.length}
          </span>
        </div>

        <div className="rate">
          <span>Rate our Platform:</span>
          <div className="stars">{renderStars()}</div>
        </div>
      </div>
    </div>
  );
}

export default Score