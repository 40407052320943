import React from "react";
import "./SampleCertificate.css";

const SampleCertificate = () => {
  return (
    <div className="sample-certficate-container">
      <div className="sample-certificate-text-container">
        <div className="sample-certificate-logo-image">
          <img src="/images/logo-byte.svg" />
        </div>
        <h1 className="sample-certificate-topic">
          Program on Data Structures and Algorithms
        </h1>
        <p className="sample-certificate-topic-description">
          The Advanced Certification in Data Structures and Algorithms
          emphasizes Algorithmic Analysis, Data Structures Implementation,
          Problem Solving Techniques, Algorithm Design Paradigms, Performance
          Optimization, and a portfolio showcasing projects on GitHub.
        </p>
        <div className="sample-certificate-generate-button">Get Sample Certificate</div>
      </div>
      <div className="sample-certificate-image-container">
        <img src="/images/sample-certificate.svg" />
      </div>
    </div>
  );
};

export default SampleCertificate;
