import React,{useState, useEffect} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Sessions.css";
import { RecordingApiData } from "../../../../APIservice/RecordingApi";
import MentorCard from "../../../../Molecule/MentorCard/MentorCard";
import RecordedCard from "../../../../Molecule/RecordedCard/RecordedCard";
import * as enrolledApi from "../../../../APIservice/EnrolledApi";

const LiveClasses = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [courseSessions, setCourseSessions] = useState({ chapters: [{sessions:[]}] });
  const [courseMentor, setcourseMentor] = useState({  });

  useEffect(() => {
    enrolledApi.getCourseSessions(params.courseCode).then((content) => {
      console.log(content)
      setCourseSessions(content)
    });
    
  }, []);

  const handleAllRecording = () => {
    navigate("/programs/:courseCode/learn/sessions/all-recordings");
  };

  return (
    <div className="live-classes-container">
      <div className="live-class-name-and-heading">
        <h4 style={{ fontWeight: "100" }}>
          programs
                   &gt;{" "}
          <span className="live-class-name" style={{ fontWeight: "bold" }}>
            {courseSessions.title}
          </span>{" "}
        </h4>
      </div>
      <div>
        <div className="live-classes-and-mentor-container">
          <div className="ongoing-and-recordings">
            {courseSessions.chapters?.map((chapter)=>
            <div className="recording-classes">
              <div className="recording-and-view-all">
                <p style={{ fontWeight: "bold" }}>{chapter.chapterTitle}</p>

                <div className="view-all-btn" onClick={handleAllRecording}>
                  <p>View all</p>
                </div>
              </div>

              {/* recorded classes */}
              {chapter.sessions.slice(0, 1).map((session) => {
                return (
                  <RecordedCard
                    recording={session}
                    code={params.courseCode}
                  />
                );
              })}
            </div>
          )}
        </div>

          {/* Mentor Card */}
          <MentorCard />
        </div>
      </div>
    </div>
  );
};

export default LiveClasses;
