import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../../context/GlobalContext";
import Day from "../Day/Day";
import "./Month.css"; // Import CSS file for Month component styles
import CreateEventButton from "../CreateEventButton/CreateEventButton";

export default function Month({ month }) {
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }

  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }

  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }
  // Function to generate an array of time slots from 7am to 5pm
  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      timeSlots.push(`${hour < 10 ? "0" : ""}${hour}:00`);
    }
    return timeSlots;
  };

  // Function to render the time slots beside each day
  const renderTimeSlots = () => {
    const timeSlots = generateTimeSlots();
    return timeSlots.map((timeSlot, index) => (
      <div key={index} className="time-slot">
        {timeSlot}
      </div>
    ));
  };

  // State variables for view mode and starting date
  const [viewMode, setViewMode] = useState("week");
  const [startDate, setStartDate] = useState(new Date());

  // Function to handle click on the "Day" button
  const handleDayView = () => {
    setViewMode("day");
    setStartDate(new Date()); // Set start date to current date
  };

  // Function to handle click on the "Week" button
  const handleWeekView = () => {
    setViewMode("week");
  };

  return (
    <div>
      <div className="month-container">
        <header className="calendar-header-container">
          <div className="calendar-header-navigate-button-container">
            <button onClick={handlePrevMonth} className="navigation-button">
              <span className="material-icons-outlined chevron-icon">
                chevron_left
              </span>
            </button>
            <button onClick={handleReset} className="reset-button">
              Today
            </button>

            <button onClick={handleNextMonth} className="navigation-button">
              <span className="material-icons-outlined chevron-icon">
                chevron_right
              </span>
            </button>
          </div>
          <div>
            <div className="calendar-search">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <CreateEventButton />
        </header>


        <div style={{ display: "flex", gap: "1rem" }}>
          {/* Render time slots only if in day view mode */}
            <div className="month-containertime-slots">{renderTimeSlots()}</div>
          <div className="month-containerdays-container">
              <div className="month-containerdays-row ">
                {[...Array(7)].map((_, i) => {
                  const currentDate = new Date(startDate);
                  currentDate.setDate(startDate.getDate() + i);
                  return (
                    <div key={i} className="month-containerday-container">
                      <div className="month-containerday-box">
                        <Day day={currentDate} rowIdx={0} />
                      </div>
                      {generateTimeSlots().map((timeSlot, index) => (
                          <div key={index} className="month-containerday-other-box"></div>
                        ))}
                     
                    </div>
                  );
                })}
              </div>
          </div>
            <div className="month-containertime-slots">{renderTimeSlots()}</div>
        </div>
      </div>
    </div>
  );
}
