import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { getMonth } from "../util/util";
import "./SmallCalendar.css"; // Import CSS file for SmallCalendar component styles

export default function SmallCalendar() {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  const {
    monthIndex,
    setSmallCalendarMonth,
    setDaySelected,
    daySelected,
  } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }

  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected && daySelected.format(format);
    if (nowDay === currDay) {
      return "current-day";
    } else if (currDay === slcDay) {
      return "selected-day";
    } else {
      return "";
    }
  }

  return (
    <div className="small-calendar ">
      <header className="small-calendarcalendar-header">
        <p className="small-calendarcalendar-title">
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format(
            "MMMM YYYY"
          )}
        </p>
        <div className="small-calendar-navigation-buttons">
          <button onClick={handlePrevMonth} className="small-calendarnav-button">
            <span className="material-icons-outlined small-calendarchevron-left">
              chevron_left
            </span>
          </button>
          <button onClick={handleNextMonth}>
            <span className="material-icons-outlined small-calendarchevron-right">
              chevron_right
            </span>
          </button>
        </div>
      </header>
      <div className="small-calendardays-grid">
        {currentMonth[0].map((day, i) => (
          <span key={i} className="small-calendarday-label">
            {day.format("dd").charAt(0)}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setSmallCalendarMonth(currentMonthIdx);
                  setDaySelected(day);
                }}
                className={`small-calendarday-button ${getDayClass(day)}`}
              >
                <span className="small-calendarday-number">{day.format("D")}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
