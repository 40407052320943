import React,{useState} from 'react'
import './Library.css'
import Bytegpt from './Bytegpt/Bytegpt';
import Books from './Books/Books';
import Videos from './Videos/Videos';


function Library() {
    const [searchQuery, setSearchQuery] = useState("");
    const [switchButton, setSwitchButton] = useState(false);
    const switchButtonClass = switchButton
      ? "switch-button active"
      : "switch-button";

      const buttonText = switchButton ? "Books" : "Videos";
      
  
  const handleSwitchButtonClick = () => {
    setSwitchButton(!switchButton);
  };
  return (
    <div className="library-container">
      <div className="left-library-container">
        <div className="search-switch-container">
          <div className="input">
            <input
              type="text"
              placeholder="Search for videos"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className={switchButtonClass} onClick={handleSwitchButtonClick}>
            <div className="circle"></div>
            <span>{buttonText}</span>
          </div>
        </div>
        <div className="book-video-container">
          {switchButton ? (<Books/>):(<Videos/>)}
        </div>
      </div>
      {/* <Bytegpt /> */}
    </div>
  );
}

export default Library