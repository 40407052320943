import React from "react";
import { Link } from "react-router-dom";
import "./ExpertCard.css";

function ExpertCard({ experts, className }) {
  return (
    <div className={className}>
      {experts.map((expert) => (
        <Link key={expert.id} to={`/byteconnect/expertsPage/${expert.id}`}>
          <div className="student-card-container">
            <div className="student-card">
              <div className="student-card-images">
                <div className="student-card-image1">
                  <img src={expert.image} alt="Student" />
                </div>
                <div className="student-card-image2">
                  <img
                    className="student-card-image2"
                    src={expert.image2}
                    alt="student2"
                  />
                </div>
              </div>
              <div className="student-card-text-container">
                <h1 className="student-card-text-designation">
                  {expert.studentDesignation}
                </h1>
                <div className="student-card-text-name-role">
                  <h1 className="student-card-text-name">
                    {expert.studentName}
                  </h1>
                  <h1 className="student-card-text-role">
                    {expert.studentRole}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default ExpertCard;
