import React from "react";
import './Layout.css';

const Layouts = () => {
  return (
    <div className="login-left-container">
      <div className="bytecoder-login-logo">
        <img src="/images/Logo.svg" alt="" />
      </div>

      <div className="login-intro">
        <h3>Unlocking Possibilities, One Step at a Time.</h3>
        <p>
          Unleashing Innovation Through Learning. Join us in the world of competition
          and creativity.
        </p>
      </div>

      <div className="login-svg-img">
        <img src="/images/login-page-image.svg" alt="" />
      </div>
    </div>
  );
};

export default Layouts;
