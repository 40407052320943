export const items = [
         {
           id: 10,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Books",
           rating: 3,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 1,
           name: "Code Simplicity",
           discount: 399,
           price: 1499,
           image: "/images/book2.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 2,
           name: "Engineering Physics",
           discount: 399,
           price: 1499,
           image: "/images/book3.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 3,
           name: "Future Hi Tech",
           discount: 399,
           price: 1499,
           image: "/images/book4.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Notebooks",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 4,
           name: "Write On Tech",
           discount: 399,
           price: 1499,
           image: "/images/book5.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 5,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Sample Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 6,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 7,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 8,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
         {
           id: 9,
           name: "Paper Pack",
           discount: 399,
           price: 1499,
           image: "/images/book1.png",
           cover_images: [
             "/images/book-cover1.png",
             "/images/book-cover2.png",
             "/images/book-cover3.png",
             "/images/book-cover4.png",
           ],
           category: "Papers",
           rating: 5,
           description:
             "Unlock the power of data-driven decision-making with our practical guide, 'Building Products with Data'. This comprehensive resource equips you with the knowledge and tools to leverage data effectively in product development. From understanding user behavior to optimizing features, you'll learn how to harness data to create products that resonate with your audience and drive success. Packed with actionable insights and real-world examples, this book is essential reading for product managers, developers, and anyone looking to harness the full potential of data in their product strategy.",
           about:
             'Dr. Amanda Rodriguez, a distinguished data scientist and product strategist with a Ph.D. in Computer Science from Stanford University, presents "Building Products with Data," a comprehensive guide for leveraging data in product development. Drawing from over a decade of experience in the tech industry, Dr. Rodriguez offers practical insights and strategies to empower readers to harness the power of data-driven decision-making. From user analytics to feature prioritization, this book provides a step-by-step framework for building successful products that resonate with users and drive business growth. With real-world examples and case studies, Dr. Rodriguez demystifies the process of incorporating data into every stage of the product lifecycle, making this essential reading for product managers, developers, and entrepreneurs looking to thrive in today\'s data-driven landscape.',
           author: "Dr. Amanda Rodriguez",
           publisher: "HarperCollins India",
           publication_date: "February 26, 2024",
           language: "English",
           format: "Paperback",
           pages: "256 pages",
           isbn_10: "935699997X",
           isbn_13: "978-9356999978",
           item_weight: "500g",
           dimensions: "19.8 x 12.9 x 1.5 cm",
           best_sellers_rank: [
             "#26 in Books (See Top 100 in Books)",
             "#2 in Children's Family, Personal & Social Issues (Books)",
             "#3 in Self-Help for Happiness",
             "#3 in Healthy Living & Wellness",
           ],
           quantity: 1,
           banner_image: "/images/banner.png",
         },
       ];

export const categories = ["All", "Papers", "Notebooks", "Books", "Sample Papers"];