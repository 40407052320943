import React, { useEffect, useState } from "react";
// import { DASHBOARD_TABS } from "../Constants/Constants";
import { Collapse, Paper } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./DashboardSideNavBar.css";
import { getSideNav } from "../../Constants/Constants";
import * as loginApi from "../../APIservice/LoginRegistrationApi";
import { useRef } from "react";


export function DashboardHeader() {
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    profile_image_url: null,
    gender: '',
    exam_preference: ''
  });

  useEffect(() => {
    let user_id = localStorage.getItem("user_id");

    loginApi.getUserInfo(user_id).then((response) => {
      console.log(response)
      if(response.status === 200){
          return  response.json().then(data => setUserInfo(data))
      }else{
        navigate("/login");
        return  response.json()
      }
    })
    .catch((e) => 
    {
      console.log("Error " + e)
      navigate("/login");

    });

  }, []);

  const location = useLocation();
  const pathLocation = [
    "/login",
    "/register",
    "/undermaintainance",
    "/mobile-maintainance",
    "/thankyou",
    "/questioner",
    "/bac/termsandconditions",
  ];
  const getTabName = (pathname) => {
    // Define a mapping of pathnames to tab names
    if(pathname.includes("dashboard")){
      return "Dashboard"
    }
    if(pathname.includes("questionpapers")){
      return "Question Paper"
    }
    if(pathname.includes("profile")){
      return "Profile"
    }
    const tabNames = {
      "/dashboard": "Dashboard",
      "/programs": "Programs",
      "/jobs": "Jobs",
      "/profile": "Profile",
      "/questionpapers": "Question Paper",
      "/blogs": "Blogs",
      "/byteconnect": "Byte Connect",
      "/shop": "Shop",
      "/alljobs": "Jobs",
      "/library": "Library",
      "/calender": "Calendar",
      "/bac": "BAC"

    };
    return tabNames[pathname] || "Dashboard"; 
  };


  const loginAndRegister = pathLocation.includes(location.pathname);

  const handleProfile = () => {
    console.log("clicked");
    setShowProfile(!showProfile);
  };

  const handleUserLink = (path) => {
    navigate(path);
  };

  const handleToggleProfile = () => {
    navigate("/profile");
  };

  const logoutUser = ()=>{
    navigate("/login");
    // loginApi.logoutUser().then((response) => {
    //   console.log(response)
    //   if(response.status === 200){
    //       response.json().then((json) => {
    //         // window.open(json.logout_url, '_self')
    //       })
    //   } else{
    //     console.log("logout is not working")
    //   }
    // })

  }

  const profileRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Click outside the profile card
        setShowProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!loginAndRegister || !userInfo)
    return (
      <div className="dash-header-container">
        <div className="dash-header">
          {/* <div className="header-circle"></div> */}

          <div className="user-name">
            {/* <div className="ring"></div> */}
            <h3>{getTabName(location.pathname)}</h3>

          </div>
          <div className="user-img-and-name" onClick={handleProfile}>
            <div className="user-profile-image">
              <img src={userInfo.profile_image_url== null ? "/images/profile_image.png" : userInfo.profile_image_url} alt="" />
            </div>

            <span style={{ textTransform: "capitalize" }}>
              {userInfo?.name ? userInfo.name.split(" ")[0] : ""}
            </span>

            {/* <h3>Karan</h3> */}
            <Collapse in={showProfile}>
              <Paper
                ref={profileRef}
                elevation={3}
                className="user-profile-box"
                style={{ borderRadius: "10px" }}
              >
                <div className="profile-navs">
                  <p>{userInfo.email}</p>

                  <hr style={{ width: "90%" }} />
                  <div
                    className="user-navs"
                    onClick={() => handleUserLink("/")}
                  >
                    <i className="fa-solid fa-sliders fa-rotate-90"></i>
                    <h4>Home</h4>
                  </div>
                  <div className="user-navs" onClick={handleToggleProfile}>
                    <i className="fa-solid fa-file"></i>
                    <h4>Profile</h4>
                  </div>
                  <div className="user-navs"
                    onClick={() => logoutUser()}>
                    <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                    <h4>Log out</h4>
                  </div>
                </div>
              </Paper>
            </Collapse>
          </div>
        </div>
      </div>
    );
}

export function DashboardSideNavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [nav_tabs, set_tabs] = useState(getSideNav(""));

  const dashLocations = [
    "/jobs/assesments",
    "/dashboard/profile",
    "/dashboard/all-recordings",
    "/class-player",
    "/login",
    "/register",
    "/undermaintainance",
    "/mobile-maintainance",
    "/user-courses",
    "/thankyou",
    "/questioner",
    "/bytecoder",
    "/calendar"
  ].includes(location.pathname);
  const [activeLink, setActiveLink] = useState("/dashboard");

  useEffect(() => {
    setActiveLink(location.pathname);
    set_tabs(getSideNav(""))
  }, [location.pathname]);

  // console.log(location, "hewre is the locations");

  if (!dashLocations)
    return (
      <div className="dash-nav-container">
        <div className="logo-menu">
          <div className="byte-logo" onClick={() => navigate("/")}>
            <img src="/images/Logo.svg" alt="" />
          </div>

          <div className="all-dash-nav-link">
            {nav_tabs.map((nav) => (
              <div className="dash-navs" key={nav.tag}>
                <Link
                  className={
                    activeLink.toLowerCase().includes(nav.path.toLowerCase())
                      ? "active-link"
                      : "inactive-link"
                  }
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".5em",
                    textDecoration: "none",
                  }}
                  to={nav.path}
                >
                  {nav.icon}
                  {nav.tag}
                </Link>
                {activeLink.toLowerCase().includes(nav.path.toLowerCase()) && (
                  <div className="bar"></div>
                )}
              </div>
            ))}
          </div>
        </div>

      </div>
    );
}


// kiscore and preapproval ->  