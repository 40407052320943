export const careerTrackCourse = {
  careerTrack: "Data Engineer",
  description:
    "Become the architect of data systems. As a Data Engineer, you'll design, build, and maintain robust data infrastructure. Harness the power of programming languages like Python, Java, or SQL to ensure seamless data flow and drive insights.",
  topics: [
    {
      topicNumber: 1,
      topicImage: "/images/lang.svg",
      topicTitle: "Introduction to Data Engineering",
      topicDescription:
        "Gain a foundational understanding of data engineering, including its role within organizations and its importance in today's data-driven world. Explore key concepts and terminology essential for success in the field.",
      modules: [
        {
          completionPercentage: "10",
          subtopicNumber: 1,
          subtopicHeading: "Overview of Data Engineering",
          subtopicDescription:
            "Gain a foundational understanding of data engineering, including its role within organizations and its importance in today's data-driven world. Explore key concepts and terminology essential for success in the field.",
          pointsCovered: [
            "Understanding Data Engineering",
            "Evolution of Data Engineering",
            "Data Engineering Workflow",
          ],
        },
        {
          completionPercentage: "30",
          subtopicNumber: 2,
          subtopicHeading: "Role and Responsibilities of a Data Engineer",
          subtopicDescription:
            "Gain a foundational understanding of data engineering, including its role within organizations and its importance in today's data-driven world. Explore key concepts and terminology essential for success in the field.",
          pointsCovered: [
            "Understanding Data Engineering",
            "Evolution of Data Engineering",
            "Data Engineering Workflow",
          ],
        },
        {
          completionPercentage: "20",
          subtopicNumber: 3,
          subtopicHeading: "Importance of Data Engineering in the Industry",
          subtopicDescription:
            "Gain a foundational understanding of data engineering, including its role within organizations and its importance in today's data-driven world. Explore key concepts and terminology essential for success in the field.",
          pointsCovered: [
            "Understanding Data Engineering",
            "Evolution of Data Engineering",
            "Data Engineering Workflow",
          ],
        },
        {
          completionPercentage: "50",
          subtopicNumber: 3,
          subtopicHeading: "Key Concepts and Terminologies",
          subtopicDescription:
            "Gain a foundational understanding of data engineering, including its role within organizations and its importance in today's data-driven world. Explore key concepts and terminology essential for success in the field.",
          pointsCovered: [
            "Understanding Data Engineering",
            "Evolution of Data Engineering",
            "Data Engineering Workflow",
          ],
        },
      ],
    },
    {
      topicNumber: 2,
      topicImage: "/images/lang.svg",
      topicTitle: "Data Storage and Management",
      topicDescription:
        "Dive into the world of data storage and management, learning about relational and NoSQL databases, data warehousing, and data lakes. Discover how different storage solutions play a crucial role in organizing and accessing large volumes of data.",
    },
    {
      topicNumber: 3,
      topicImage: "/images/lang.svg",
      topicTitle: "Data Processing and Transformation",
      topicDescription:
        "Explore the process of extracting, transforming, and loading data (ETL), essential for preparing data for analysis. Learn about data pipeline architecture and the differences between batch processing and stream processing, as well as popular tools like Apache Hadoop and Spark.",
    },
    {
      topicNumber: 4,
      topicImage: "/images/lang.svg",
      topicTitle: "Big Data Technologies",
      topicDescription:
        "Delve into big data technologies, including the Hadoop ecosystem and Apache Spark. Understand how these tools enable processing and analyzing massive datasets efficiently, and explore real-time data streaming with Apache Kafka and Apache Flink.",
    },
    {
      topicNumber: 5,
      topicImage: "/images/lang.svg",
      topicTitle: "Data Modeling and Optimization",
      topicDescription:
        "Master the art of data modeling and optimization, learning techniques for designing efficient database schemas and optimizing performance. Explore data partitioning, indexing strategies, and best practices for ensuring data quality and reliability.",
    },
    {
      topicNumber: 6,
      topicImage: "/images/lang.svg",
      topicTitle: "Cloud Computing and Data Engineering",
      topicDescription:
        "Discover how cloud computing has transformed data engineering, with an overview of major cloud platforms and services. Learn how to leverage cloud storage, data warehousing, and compute resources for building scalable and cost-effective data solutions.",
    },
    {
      topicNumber: 7,
      topicImage: "/images/lang.svg",
      topicTitle: "Data Quality and Governance",
      topicDescription:
        "Learn about the importance of data quality and governance in ensuring trustworthy and reliable data. Explore techniques for assessing and monitoring data quality, as well as best practices for implementing data governance frameworks and ensuring compliance with regulations.",
    },
    {
      topicNumber: 8,
      topicImage: "/images/lang.svg",
      topicTitle: "Capstone Project",
      topicDescription:
        "Apply your knowledge and skills to a real-world data engineering project, working collaboratively with peers to design and implement a data solution from start to finish. Showcase your proficiency in data engineering concepts and techniques through a comprehensive capstone project.",
    },
  ],
};
