import { Filter } from '@mui/icons-material'
import React from 'react'
import './BlogsFilter.css'
const BlogsFilter = () => {
  return (
    <div className='blogs-filter'>
      <img src="/images/filter-lines.png" />
      Filter
    </div>
  )
}

export default BlogsFilter