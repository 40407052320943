import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slices/cartSlice"
import qpReducer from "./slices/QPSlice"

const store=configureStore({
  reducer:{
    cart:cartReducer,
    qp:qpReducer
  },
});

export default store;