import React, { useEffect, useState } from "react";
import AppliedJobsCard from "../AppliedJobs/AppliedJobsCard/AppliedJobsCard";
import { appliedJobs } from "../../../APIservice/AppliedJobs";
import "./AllAppliedJobs.css";
import SearchAndPopularTags from "../../../GenericComponents/SearchAndPopularTags/SerachAndPopularTags";
import Filter from "../../../GenericComponents/Filter/Filter";
import * as CoursesApi from "../../../APIservice/CoursesApi";
import SearchJob from '../SearchJob/SearchJob'

const AllAppliedJobs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [courses, setCourses] = useState([]);
  const [populerShortcuts, setpopulerShortcuts] = useState([]);
  const [interest, setInterest] = useState("");

  useEffect(() => {
    CoursesApi.getAllCourses().then((cs) => setCourses(cs));
    CoursesApi.getPopulerShortcuts().then((shortcuts) =>
      setpopulerShortcuts(shortcuts)
    );
  }, []);

  useEffect(() => {
    CoursesApi.getAllCourses("", interest).then((cs) => setCourses(cs));
  }, [interest]);

  const searchCourse = (e) => {
    e.preventDefault();
    console.log("search " + searchText + " interest " + interest);
    CoursesApi.getAllCourses(searchText, interest).then((cs) => setCourses(cs));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".job-page-filter")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="all-applied-jobs-container">
      <div className="all-applied-jobs-header">
        <h1>Applied Jobs</h1>
        <div style={{display:"flex", gap:"1rem"}}>
          {/* <SearchAndPopularTags
            props={{
              interest,
              populerShortcuts,
              setInterest,
              searchText,
              setSearchText,
              searchEvent: searchCourse,
            }}
          /> */}
          <SearchJob />
          <div className=" job-page-filter applied-jobs-filter">
            <img src={'/images/filter-lines.png'} alt="filter" />
            <div onClick={() => setIsOpen(!isOpen)}>Filter</div>
            {isOpen && <Filter />}
          </div>
        </div>
      </div>
      <div className="all-applied-jobs-card-container">
        {appliedJobs.map((job) => (
          <AppliedJobsCard
            key={job.id}
            image={job.image}
            company={job.companyName}
            role={job.role}
            status={job.status}
            job={job}
          />
        ))}
      </div>
    </div>
  );
};

export default AllAppliedJobs;
